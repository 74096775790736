.none-desktop {
  display: none !important;
}
.about-us {
  flex-wrap: wrap;
  height: calc(100vh - 102px);
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1366px) and (-webkit-device-pixel-ratio: 1) {
    .about-us-content {
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
  }
  .about-us-content {
    // padding-top: 68px;
    padding-left: 130px;
    padding-right: 78px;
    padding-bottom: 142px;
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .about-us-content {
      -moz-transform: scale(0.8);
      zoom: 80%;
    }
  }
  .about-us-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #222222;
    padding-bottom: 32px;
  }
  .title-tablet {
    display: none;
  }
  .about-us-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #222222;
    padding-right: 15px;
  }
  .about-us-img {
    padding-right: 0 !important;
    // padding-left: 80px;
    background: #1eb2ff;
    height: 100vh;
    // width: 100vh;
    margin-left: 45px;
    align-items: center;
    display: flex;
  }
  .image-about-us-lab6 {
    position: relative;
    right: 90px;
    padding-bottom: 190px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
  }
  .image-about-us-lab8 {
    padding-top: 116px;
    padding-bottom: 109px;
    position: relative;
    right: 102px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
  }
  .image-about-us-lab6-nav {
    width: 100%;
    height: auto;
  }
  .image-about-us-lab8-nav {
    width: 100%;
    height: auto;
  }
  .about-us-box {
    padding-top: 32px;
  }
  .box-content {
    justify-content: center;
    height: 72px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    margin-bottom: 16px;
  }
  .col-box-left {
    padding-left: 0px;
    padding-right: 8px;
  }
  .col-box-right {
    padding-left: 8px;
    padding-right: 0px;
  }
  .box-content-img {
    height: 32px;
    width: 32px;
  }
  .box-content-text {
    padding-left: 8px;
    padding-top: 5px;
  }
  .about-us-backroud-frame {
    position: absolute;
    right: 0px;
    top: 0px;
    img {
      width: 222px;
      height: 370px;
    }
  }
  .about-us-backgroud {
    position: fixed;
    left: 0px;
    display: none;

    .backgroud-image {
      height: calc(100vh + 40px);
    }
    .backgroud-image-mobile {
      display: none !important;
    }
  }
  .col-right-page {
    padding-right: 0px;
  }
}
@media screen and (max-width: 1677px) {
  .about-us {
    .about-us-content {
      padding-left: 8%;
    }
  }
}
@media screen and (max-width: 1650px) {
  .about-us {
    .about-us-content {
      // padding-left: 60px;
      padding-left: 7%;
    }
  }
}
@media screen and (max-width: 1490px) {
  .about-us {
    .about-us-content {
      // padding-left: 60px;
      padding-left: 8%;
    }
  }
}
@media screen and (max-width: 1440px) {
  .about-us {
    .about-us-content {
      padding-left: 8%;
    }
  }
}
@media screen and (max-width: 1366px) {
  .about-us {
    .about-us-content {
      padding-left: 102px !important;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  #about {
    display: block !important;
  }
  .mobile-page {
    margin: auto;
    display: block !important;
    width: fit-content;
  }

  .about-us .about-us-backgroud {
    display: block !important;
    position: absolute !important;
    left: 0em;
    top: -6em;
    max-height: calc(100vh + 40px);
    .backgroud-image-mobile {
      width: 100%;
      height: 964px;
      display: block !important;
    }
    .backgroud-image {
      display: none;
    }
  }

  .about-us-backgroundImage {
    display: none;
  }

  .about-us {
    display: block !important;
    justify-content: unset;
    align-items: unset;
    height: auto;
    .title-desktop {
      display: none !important;
    }
    .title-tablet {
      display: block !important;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 100% !important;
      padding-left: 0;
      padding-right: 0;
    }
    .about-us-content {
      padding-left: 98px;
      padding-right: 90px;
      width: 100%;
      padding-bottom: unset;

      .about-us-title {
        text-align: center;
        padding-top: 80px;
        width: 100%;
        font-weight: 700;
      }
      .about-us-span {
        width: 100%;
        height: auto;
        left: calc(50% - 720px / 2);
        font-family: "IBM Plex Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 55px;
      }
      .about-us-box {
        width: 100%;
        font-size: 20px;
      }
    }
    .about-us-img {
      //   background: url("../../assets/images/AboutUs/background-about.webp");
      background-size: contain;
      margin-top: 10em;
      background-position: left;
      margin-left: 0px;
      width: 100%;
      height: auto;
      justify-content: center;
      .image-about-us-lab6 {
        position: relative;
        /* right: 90px; */
        top: -8em;
        left: -6em;
        padding-left: 10em;
        padding-bottom: 116px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        .image-about-us-lab6-nav {
          width: 100%;
          height: auto;
        }
      }

      .image-about-us-lab8 {
        padding-top: 100px;
        padding-bottom: 28px;
        position: relative;
        right: 55px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        top: -5em;
        left: -4em;

        .image-about-us-lab8-nav {
          width: 100%;
          height: auto;
        }
      }
    }
    .clients-map .clients-map-backgroud {
      display: none;
    }
  }
  .about-us-backroud-frame {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #about {
    display: block !important;
  }
  .mobile-page {
    margin: auto;
    display: block !important;
    width: fit-content;
  }

  .about-us .about-us-backgroud {
    display: block !important;
    position: absolute !important;
    left: 0em;
    top: -4em;
    max-height: calc(100vh + 40px);
    .backgroud-image-mobile {
      width: 100%;
      height: 964px;
      display: block !important;
    }
    .backgroud-image {
      display: none;
    }
  }

  .about-us-backgroundImage {
    display: none;
  }

  .about-us {
    display: block !important;
    justify-content: unset;
    align-items: unset;
    height: auto;
    .title-desktop {
      display: none !important;
    }
    .title-tablet {
      display: block !important;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 100% !important;
      padding-left: 0;
      padding-right: 0;
    }
    .about-us-content {
      padding-left: 24px !important;
      padding-right: 24px;
      width: 100%;
      padding-bottom: unset;
      .about-us-title {
        text-align: center;
        padding-top: 80px;
        width: 100%;
        font-weight: 700;
      }
      .about-us-span {
        width: 100%;
        height: auto;
        left: calc(50% - 720px / 2);
        font-family: "IBM Plex Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 55px;
      }
      .about-us-box {
        width: 100%;
        font-size: 20px;
      }
    }
    .about-us-img {
      //   background: url("../../assets/images/AboutUs/background-about.webp");
      background-size: contain;
      margin-top: 10em;
      background-position: left;
      margin-left: 0px;
      width: 100%;
      height: auto;
      justify-content: center;
      .image-about-us-lab6 {
        position: relative;
        /* right: 90px; */
        top: -8em;
        left: 0em;
        padding-bottom: 116px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        .image-about-us-lab6-nav {
          width: 100%;
          height: auto;
        }
      }

      .image-about-us-lab8 {
        padding-top: 116px;
        padding-bottom: 28px;
        position: relative;
        right: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        top: -6em;

        .image-about-us-lab8-nav {
          width: 100%;
          height: auto;
        }
      }
    }
    .clients-map .clients-map-backgroud {
      display: none;
    }
  }
  .about-us-backroud-frame {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .about-us {
    .about-us-backgroud {
      display: block !important;
      position: absolute !important;
      left: 0em;
      top: 10em;
      max-height: calc(100vh + 40px);
      .backgroud-image-mobile {
        width: 100%;
        height: 964px;
        display: block !important;
      }
      .backgroud-image {
        display: none;
      }
    }
  }
  #about {
    display: block !important;
  }
  .mobile-page {
    margin: auto;
    display: block !important;
    width: fit-content;
  }

  .about-us-backgroundImage {
    display: none;
  }
  .about-us-backroud-frame {
    display: none;
  }
  .about-us {
    display: block !important;
    justify-content: unset;
    align-items: unset;
    height: auto;
    .about-us-content {
      width: 100% !important;
      padding-left: 32px;
      padding-right: 25px;
      .about-us-title {
        text-align: center;
        padding-top: 30px;
        width: 100%;
        font-size: 38px;
      }
      .about-us-span {
        width: 100%;
        height: auto;
        font-family: "IBM Plex Sans";
        font-style: normal;
        font-weight: 400;
        // font-size: 18px;
        padding-left: 16px;
        padding-right: 16px;
      }
      .about-us-box {
        width: 100%;
        display: block !important;
        padding-left: 16px;
        padding-right: 10px;
        .box-content {
          width: 100%;
          font-size: 16px;
          line-height: 19.2px;
        }
        .col-box-right {
          padding-left: 0px;
          padding-right: 8px;
        }
      }
    }

    .about-us-img {
      //   background: url("../../assets/images/AboutUs/background-about.webp");
      background-size: contain;
      margin-top: 13em;
      background-position: left;
      margin-left: 0px;
      width: 100%;
      height: 26em;

      .image-about-us-lab6 {
        padding-top: 20px;
        position: relative;
        top: -7em;
        left: 0;
        padding-bottom: 0px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        .image-about-us-lab6-nav {
          width: 100%;
          height: auto;
        }
      }

      .image-about-us-lab8 {
        padding-top: 60px;
        padding-bottom: 0px;
        position: relative;
        right: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        top: -5em;
        .image-about-us-lab8-nav {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .about-us .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 100%;
  }
  .about-us .col-lg-6 {
    flex: 0 0 50%;
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: unset;
  }
}

@media screen and (max-width: 670px) {
  .about-us {
    .about-us-img {
      //   background: url("../../assets/images/AboutUs/background-about.webp");
      background-size: contain;
      margin-top: 13em;
      background-position: left;
      margin-left: 0px;
      width: 100%;
      height: 22em;
      .image-about-us-lab6 {
        padding-top: 20px;
        position: relative;
        top: -9em;
        left: 0;
        padding-bottom: 0px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        .image-about-us-lab6-nav {
          width: 100%;
          height: auto;
        }
      }

      .image-about-us-lab8 {
        padding-top: 60px;
        padding-bottom: 0px;
        position: relative;
        right: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        top: -7em;
        .image-about-us-lab8-nav {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .about-us {
    .about-us-img {
      //   background: url("../../assets/images/AboutUs/background-about.webp");
      background-size: contain;
      margin-top: 13em;
      background-position: left;
      margin-left: 0px;
      width: 100%;
      height: 16em;
      .image-about-us-lab6 {
        padding-top: 20px;
        position: relative;
        top: -9em;
        left: 0;
        padding-bottom: 0px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        .image-about-us-lab6-nav {
          width: 100%;
          height: auto;
        }
      }

      .image-about-us-lab8 {
        padding-top: 60px;
        padding-bottom: 0px;
        position: relative;
        right: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        top: -7em;
        .image-about-us-lab8-nav {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .about-us {
    .about-us-img {
      //   background: url("../../assets/images/AboutUs/background-about.webp");
      background-size: contain;
      margin-top: 11em;
      background-position: left;
      margin-left: 0px;
      width: 100%;
      height: 14em;
      .image-about-us-lab6 {
        padding-top: 20px;
        position: relative;
        top: -6em;
        left: 0;
        padding-bottom: 0px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        .image-about-us-lab6-nav {
          width: 100%;
          height: auto;
        }
      }

      .image-about-us-lab8 {
        padding-top: 60px;
        padding-bottom: 0px;
        position: relative;
        right: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        top: -5em;
        .image-about-us-lab8-nav {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .about-us {
    .about-us-img {
      //   background: url("../../assets/images/AboutUs/background-about.webp");
      background-size: contain;
      margin-top: 8em;
      background-position: left;
      margin-left: 0px;
      width: 100%;
      height: 12em;

      .image-about-us-lab6 {
        padding-top: 20px;
        position: relative;
        top: -5em;
        left: 0;
        padding-bottom: 0px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        .image-about-us-lab6-nav {
          width: 100%;
          height: auto;
        }
      }

      .image-about-us-lab8 {
        padding-top: 60px;
        padding-bottom: 0px;
        position: relative;
        right: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        top: -5em;
        .image-about-us-lab8-nav {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .about-us {
    .about-us-backgroud {
      display: block !important;
      position: absolute !important;
      top: 8em;
      max-height: calc(100vh + 40px);
    }
    .about-us-img {
      //   background: url("../../assets/images/AboutUs/background-about.webp");
      background-size: contain;
      margin-top: 7em;
      background-position: left;
      margin-left: 0px;
      width: 100%;
      height: 12em;

      .image-about-us-lab6 {
        padding-top: 20px;
        position: relative;
        top: -4em;
        left: 0;
        padding-bottom: 0px;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        .image-about-us-lab6-nav {
          width: 100%;
          height: auto;
        }
      }

      .image-about-us-lab8 {
        padding-top: 60px;
        padding-bottom: 0px;
        position: relative;
        right: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        top: -4em;
        .image-about-us-lab8-nav {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
