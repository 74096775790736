.experienced-in-large {
  flex-wrap: wrap;
  height: calc(100vh - 102px);
  justify-content: center;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 0.8) {
    .experienced-in-large-content {
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
    .image-members-1-top {
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }
  @media (-webkit-device-pixel-ratio: 0.9) {
    .experienced-in-large-content {
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
    .image-members-1-top {
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .experienced-in-large-content {
      zoom: 80%;
      -moz-transform: scale(0.8);
    }
    .image-members-1-top {
      zoom: 80%;
      -moz-transform: scale(0.8);
    }
  }
  .experienced-in-large-content {
    // padding-left: 60px;
    padding-left: 16%;
    padding-right: 10px;
    margin-top: -150px;
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .experienced-in-large-content {
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
    .image-members-1-top {
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .experienced-in-large-content {
      top: 4%;
      -moz-transform: scale(0.9);
      zoom: 90%;
      left: 65px !important;
    }
    .image-members-1-top {
      -moz-transform: scale(0.9);
      zoom: 90%;
      right: 175px !important;
      top: -75px !important;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .experienced-in-large-content {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
      left: 65px;
    }
    .image-members-1-top {
      -moz-transform: scale(0.8);
      zoom: 80%;
      padding-top: 140px !important;
      right: 220px !important;
    }
  }
  .members-two-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #222222;
    padding-top: 20px;
  }
  .about-us-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #222222;
    padding-right: 15px;
  }
  .members-us-img {
    padding-right: 0 !important;
    background: #1eb2ff;
    height: 100vh;
    // width: 100vh;
    align-items: center;
    display: flex;
    // position: relative;
  }
  .image-members-1-top {
    position: relative;
    right: 180px;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    top: -103px;
    padding-top: 78px;
  }
  .image-about-us-lab8 {
    padding-top: 194px;
    position: relative;
    left: -430px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    padding-bottom: 20px;
  }
  .image-1 {
    padding-right: 5px;
    padding-left: 0px;
  }
  .image-members-1 {
    width: 100%;
    height: 273px;
  }
  .image-2 {
    padding-right: 0px;
    padding-left: 5px;
  }
  .image-members-2 {
    width: 100%;
    height: 273px;
  }
  .members-two-box {
    padding-top: 46px;
    padding-right: 10px;
  }
  .box-content {
    justify-content: center;
    height: 89px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    margin-bottom: 12px;
    text-align: center;
  }
  .col-box-left {
    padding-left: 0px;
    padding-right: 5px;
  }
  .col-box-right {
    padding-left: 5px;
    padding-right: 0px;
  }
  .box-content-img {
    height: 32px;
    width: 32px;
  }
  .box-content-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #222222;
    padding-top: 23px;
  }
  .about-us-backroud-frame {
    position: absolute;
    right: 0px;
    top: 0px;
    img {
      width: 222px;
      height: 370px;
    }
  }
  .e-commerce-retail {
    padding-top: 11px;
    padding-right: 22px;
    padding-left: 20px;
  }
  .e-commerce-retail-VN {
    padding-top: 11px;
    padding-right: 60px;
    padding-left: 60px;
  }
  .experienced-in-large-right {
    padding-left: 175px;
    padding-right: 0px;
  }
  .image-bottom {
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    .image-members-3 {
      width: 626px;
      height: 278px;
    }
  }
  .image-members-4 {
    width: 100%;
    height: 400px;
  }
  .image-members-5 {
    width: 100%;
    height: 400px;
  }
  .experienced-in-large-backgroud {
    position: fixed;
    left: 0px;
    top: 32px;
    display: none;

    .backgroud-image {
      height: 100vh;
    }
  }
}
@media screen and (max-width: 1677px) {
  .experienced-in-large {
    .experienced-in-large-content {
      // padding-left: 60px;
      padding-left: 9.5%;
    }
  }
}
@media screen and (max-width: 1650px) {
  .experienced-in-large {
    .experienced-in-large-content {
      // padding-left: 60px;
      padding-left: 10%;
    }
  }
}
@media screen and (max-width: 1490px) {
  .experienced-in-large {
    .experienced-in-large-content {
      // padding-left: 60px;
      padding-left: 8%;
    }
  }
}
@media screen and (max-width: 1440px) {
  .experienced-in-large {
    .experienced-in-large-content {
      padding-left: 6%;
    }
  }
}
@media screen and (max-width: 1366px) {
  .experienced-in-large {
    .experienced-in-large-content {
      padding-left: 60px;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .experienced-in-large {
    display: block !important;
    height: auto;
    .backgroud-image {
      display: none;
    }

    .experienced-in-large-content {
      padding-left: 98px;
      padding-right: 98px;
      margin: 0;
      .members-two-title {
        padding-top: 80px;
        text-align: center;
      }
      .members-two-box {
        .col-box-left img {
          width: 100%;
          height: auto;
        }
        .col-box-right img {
          width: 100%;
          height: auto;
        }
      }
    }
    .experienced-in-large-right {
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 90px;
      .members-us-img {
        flex-direction: column;
        display: flex;
        align-items: initial;
        height: auto;
        .image-members-1-top {
          width: 100%;
          height: auto;
          position: relative;
          filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
          top: -88px;
          right: 0;
          padding-top: 20px;
          padding-left: 98px;
          padding-right: 98px;
          .image-members-1 {
            padding-left: 0px !important;
          }
          .image-1 img {
            width: 100%;
            height: auto;
          }
          .image-2 img {
            width: 100%;
            height: auto;
          }
          .image-bottom img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 100%;
    }
    .col-xl-7 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .col-xl-5 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
  .experienced-in-large .members-two-box {
    padding-top: 46px;
    padding-right: 0px;
  }
  .experienced-in-large .image-2 {
    padding-right: 0px;
    padding-left: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .experienced-in-large {
    height: auto;
    display: block !important;
    .backgroud-image {
      display: none;
    }

    .experienced-in-large-content {
      padding-left: 24px;
      padding-right: 24px;
      margin: 0;
      .members-two-title {
        padding-top: 80px;
        text-align: center;
        font-weight: 700;
        font-size: 48px;
        line-height: 120%;
      }
      .members-two-box {
        .col-box-left img {
          width: 100%;
          height: auto;
        }
        .col-box-right img {
          width: 100%;
          height: auto;
        }
      }
    }
    .experienced-in-large-right {
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 90px;
      .members-us-img {
        flex-direction: column;
        display: flex;
        align-items: initial;
        height: auto;
        .image-members-1-top {
          width: 100%;
          height: auto;
          position: relative;
          filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
          top: -88px;
          right: 0;
          padding-top: 20px;
          padding-left: 24px;
          padding-right: 24px;
          .image-1 img {
            width: 100%;
            height: auto;
          }
          .image-2 img {
            width: 100%;
            height: auto;
          }
          .image-bottom img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 100%;
    }
    .col-xl-7 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .col-xl-5 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
  .experienced-in-large .members-two-box {
    padding-top: 46px;
    padding-right: 0px;
  }
  .experienced-in-large .image-2 {
    padding-right: 0px;
    padding-left: 13px;
  }
}

@media screen and (max-width: 767px) {
  .experienced-in-large {
    height: auto;
    display: block !important;
    .backgroud-image {
      display: none;
    }

    .experienced-in-large-content {
      padding-left: 16px;
      padding-right: 16px;
      margin: 0;
      .members-two-title {
        margin-top: 1em;
        text-align: center;
        font-family: "IBM Plex Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 120%;
      }
      .members-two-box {
        .col-box-left img {
          width: 100%;
          height: auto;
        }
        .col-box-right img {
          width: 100%;
          height: auto;
        }
      }
    }
    .experienced-in-large-right {
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 90px;
      .members-us-img {
        flex-direction: column;
        display: flex;
        align-items: initial;
        height: auto;
        .image-members-1-top {
          width: 100%;
          height: auto;
          position: relative;
          filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
          top: -88px;
          right: 0;
          padding-top: 20px;
          padding-left: 16px;
          padding-right: 16px;
          .image-1 img {
            width: 100%;
            height: auto;
          }
          .image-2 img {
            width: 100%;
            height: auto;
          }
          .image-bottom img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 100%;
    }
    .col-xl-7 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .col-xl-5 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
  .experienced-in-large .members-two-box {
    padding-top: 46px;
    padding-right: 0px;
  }
  .experienced-in-large .image-2 {
    padding-right: 0px;
    padding-left: 11px;
  }
}

@media screen and (max-width: 550px) {
  .experienced-in-large .experienced-in-large-right .members-us-img {
    height: 28em;
  }
  .experienced-in-large .image-2 {
    padding-right: 0px;
    padding-left: 9px;
  }
}
@media screen and (max-width: 450px) {
  .experienced-in-large .experienced-in-large-right .members-us-img {
    height: 21em;
  }
  .experienced-in-large .image-2 {
    padding-right: 0px;
    padding-left: 7px;
  }
}
@media screen and (max-width: 375px) {
  .experienced-in-large .experienced-in-large-right .members-us-img {
    height: 17em;
  }
}
@media screen and (max-width: 320px) {
  .experienced-in-large .experienced-in-large-right .members-us-img {
    flex-direction: column;
    display: flex;
    align-items: initial;
    height: 245px;
  }
  .experienced-in-large .image-2 {
    padding-right: 0px;
    padding-left: 5px;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1365px) {
  .experienced-in-large {
    .image-members-1-top {
      .image-members-2 {
        width: 99.5% !important;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .experienced-in-large {
    .image-members-1-top {
      .image-members-2 {
        width: 100.5% !important;
      }
    }
  }
}


@media screen and (max-height: 767px) {
  .experienced-in-large {
    .image-members-4 {
      width: 90%;
      height: 350px;
    }
    .image-members-5 {
      width: 90%;
      height: 350px;
    }
    .image-members-3 {
      height: 230px !important;
    }
  }
}
@media screen and (max-height: 730px) {
  .experienced-in-large {
    .image-members-4 {
      // width: 80%;
      height: 300px;
    }
    .image-members-5 {
      // width: 80%;
      height: 300px;
    }
    .image-members-3 {
      height: 180px !important;
    }
  }
}
@media screen and (max-height: 690px) {
  .experienced-in-large {
      .experienced-in-large-content {
        padding-left: 16%;
      }
      .image-members-1 {
        height: 200px;
      }
      .image-members-2 {
        height: 200px;
      }
      .image-members-3 {
        height: 158px !important;
        width: 500px !important;
      }
      .image-members-4 {
        width: 100%;
        height: 250px;
      }
      .image-members-5 {
        width: 100%;
        height: 250px;
      }
      
      .members-two-title {
        font-size: 30px;
      }
   
  }
}
@media screen and (max-height: 580px) {
  .experienced-in-large {
    .experienced-in-large-content {
      padding-left: 22%;
    }
      .image-members-1 {
        height: 150px;
      }
      .image-members-2 {
        height: 150px;
      }
      .image-members-3 {
        height: 130px !important;
        width: 400px !important;
      }
      .image-members-4 {
        width: 100%;
        height: 200px;
      }
      .image-members-5 {
        width: 100%;
        height: 200px;
      }
      
      .members-two-title {
        font-size: 30px;
        padding-top: 60px;
      }
      .members-two-box {
        padding-top: 20px;
      }
      .image-members-1-top {
        top: -83px;
      }
   
  }
}