.tma-innovation {
  flex-wrap: wrap;
  height: calc(100vh - 102px);
  justify-content: center;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 0.9) {
    .about-us-content {
      padding-top: 40px;
      padding-left: 180px !important;
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
    .image-members-1-top {
      padding-top: 40px;
      zoom: 85%;
      padding-right: 180px !important;
      -moz-transform: scale(0.85);
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-device-pixel-ratio: 1) {
    .about-us-content {
      padding-top: 40px;
      padding-left: 180px !important;
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
    .image-members-1-top {
      padding-top: 40px;
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
  }
  .about-us-content {
    // padding-top: 68px;
    padding-left: 16%;
    padding-right: 100px;
    margin-top: -150px;
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .about-us-content {
      padding-top: 40px;
      zoom: 90%;
    }
    .image-members-1-top {
      padding-top: 40px;
      zoom: 90%;
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .about-us-content {
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
    .image-members-1-top {
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .about-us-content {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
    .image-members-1-top {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }
  .members-two-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #222222;
    padding-bottom: 8px;
  }
  .about-us-span {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #222222;
  }
  .members-us-img {
    padding-right: 0 !important;
    background: #1eb2ff;
    height: 100vh;
    // width: 100vh;
    align-items: center;
    display: flex;
    // position: relative;
  }
  .image-members-1-top {
    position: relative;
    right: 160px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    top: -65px;
  }
  .image-members-1 {
    width: 489px;
    height: 263px;
  }
  .image-members-2 {
    width: 440px;
    height: 263px;
  }
  .members-two-box {
    padding-top: 32px;
    padding-right: 20px;
  }
  .box-content {
    justify-content: center;
    height: 69px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    margin-bottom: 12px;
    text-align: center;
  }
  .col-box-left {
    padding-left: 0px;
    padding-right: 6px;
  }
  .col-box-right {
    padding-left: 6px;
    padding-right: 0px;
  }
  .box-content-img {
    height: 32px;
    width: 32px;
  }
  .content-box {
    display: none;
  }
  .box-content-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #222222;
    padding-top: 23px;
  }
  .about-us-backroud-frame {
    position: absolute;
    right: 0px;
    top: 0px;
    img {
      width: 222px;
      height: 370px;
    }
  }
  .right-contain {
    padding-left: 165px;
    padding-right: 0px;
  }
  .box-image {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    width: 176px;
    height: 170px;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-transform: capitalize;
    color: #222222;
    display: flex;
    align-items: center;
    padding-left: 42px;
    padding-right: 27px;
  }
  .image-right-1 {
    height: 170px;
  }
  .item-1 {
    padding-bottom: 16px;
  }
  .item-2 {
    padding-bottom: 16px;
  }
  .tma-innovation-backgroud {
    position: fixed;
    left: 0px;
    top: 32px;
    display: none;
    .backgroud-image {
      height: 100vh;
    }
  }
}
@media screen and (max-width: 1677px) {
  .tma-innovation {
    .about-us-content {
      padding-left: 9.5%;
    }
  }
}
@media screen and (max-width: 1650px) {
  .tma-innovation {
    .about-us-content {
      // padding-left: 60px;
      padding-left: 10%;
    }
  }
}
@media screen and (max-width: 1490px) {
  .tma-innovation {
    .about-us-content {
      // padding-left: 60px;
      padding-left: 8%;
    }
  }
}
@media screen and (max-width: 1440px) {
  .tma-innovation {
    .about-us-content {
      padding-left: 6%;
    }
  }
}
@media screen and (max-width: 1366px) {
  .tma-innovation {
    .about-us-content {
      padding-left: 60px;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .tma-innovation .backgroud-image {
    display: none;
  }
  #tma-innovation {
    position: relative;
    top: 4em;
  }
  .tma-innovation {
    display: block !important;
    height: auto;
    margin-top: 10em;
    .about-us-content {
      padding-bottom: 109px;
      padding-left: 98px;
      padding-right: 98px;
      .members-two-title {
        text-align: center;
      }
      .about-us-span {
        text-align: center;
      }
      .members-two-box {
        padding-top: 60px;
        padding-right: unset;
      }
    }
    .right-contain {
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        justify-content: center;
        width: 100%;
        align-items: center;
        height: auto;
        .content-box {
          display: none;
        }
        .image-members-1-top {
          right: 0;
          top: -5em;
          padding-left: 98px;
          padding-right: 98px;
          width: 100%;
          .item-1 {
            padding-bottom: 40px;
            .box-image {
              width: 35%;
              height: auto;
              display: flex;
              justify-content: center;
              padding-left: 70px;
              text-align: center;
              padding-right: 70px;
            }
            .image-right-1 {
              width: 65%;
              height: auto;
            }
          }
          .item-2 {
            padding-bottom: 40px;
            .box-image {
              width: 35%;
              height: auto;
              display: flex;
              justify-content: center;
              padding-left: 70px;
              text-align: center;
              padding-right: 70px;
            }
            .image-right-1 {
              width: 65%;
              height: auto;
            }
          }
        }
        .item-3 {
          padding-bottom: 40px;
          .box-image {
            width: 35%;
            height: auto;
            display: flex;
            justify-content: center;
            padding-left: 70px;
            text-align: center;
            padding-right: 70px;
          }
          .image-right-1 {
            width: 65%;
            height: auto;
          }
        }
      }
    }
    .col-lg-6 {
      max-width: 100%;
    }
  }
  .tma-innovation .box-image {
    padding-left: 82px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tma-innovation .backgroud-image {
    display: none;
  }
  #tma-innovation {
    position: relative;
    top: 4em;
  }
  .tma-innovation {
    display: block !important;
    height: auto;
    margin-top: 10em;
    .about-us-content {
      padding-bottom: 109px;
      padding-left: 24px;
      padding-right: 24px;
      .members-two-title {
        text-align: center;
      }
      .about-us-span {
        text-align: center;
        padding-right: unset !important;
      }
      .members-two-box {
        padding-top: 60px;
        padding-right: unset;
      }
    }
    .right-contain {
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        justify-content: center;
        width: 100%;
        align-items: center;
        height: auto;
        .content-box {
          display: none;
        }
        .image-members-1-top {
          right: 0;
          top: -5em;
          padding-left: 24px;
          padding-right: 24px;
          width: 100%;
          .item-1 {
            padding-bottom: 40px;
            .box-image {
              width: 35%;
              height: auto;
              display: flex;
              justify-content: center;
              padding-left: 70px;
              text-align: center;
              padding-right: 70px;
            }
            .image-right-1 {
              width: 65%;
              height: auto;
            }
          }
          .item-2 {
            padding-bottom: 40px;
            .box-image {
              width: 35%;
              height: auto;
              display: flex;
              justify-content: center;
              padding-left: 70px;
              text-align: center;
              padding-right: 70px;
            }
            .image-right-1 {
              width: 65%;
              height: auto;
            }
          }
        }
        .item-3 {
          padding-bottom: 40px;
          .box-image {
            width: 35%;
            height: auto;
            display: flex;
            justify-content: center;
            padding-left: 70px;
            text-align: center;
            padding-right: 70px;
          }
          .image-right-1 {
            width: 65%;
            height: auto;
          }
        }
      }
    }
    .col-lg-6 {
      max-width: 100%;
    }
  }
  .tma-innovation .box-image {
    padding-left: 82px;
  }
}
@media screen and (max-width: 767px) {
  .tma-innovation .backgroud-image {
    display: none;
  }
  #tma-innovation {
    position: relative;
    top: 4em;
  }
  .tma-innovation {
    display: block !important;
    height: auto;
    margin-top: 10em;
    .about-us-content {
      padding-bottom: 100px;
      padding-left: 16px;
      padding-right: 16px;
      .members-two-title {
        text-align: center;
      }
      .about-us-span {
        text-align: center;
        padding-right: unset;
      }
      .members-two-box {
        padding-top: 27px;
        flex-direction: column;
        padding-right: 0px;

        .col-box-left {
          max-width: 100%;
          padding-left: 0px;
          padding-right: 0px;
        }
        .col-box-right {
          max-width: 100%;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
    .right-contain {
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        justify-content: center;
        width: 100%;
        align-items: center;
        height: auto;
        .image-members-1-top {
          display: none;
        }
        .content-box {
          display: block;
          padding-left: 16px;
          padding-right: 16px;

          .d-flex {
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            position: relative;
            top: -7em;
            .box-1 {
              padding-top: 24px;
              width: 100%;
              padding-right: 0;
              .box-image {
                width: 100%;
                justify-content: center;
                height: 103px;
              }
              img {
                width: 100%;
              }
            }
            .box-2 {
              padding-top: 24px;
              width: 100%;
              padding-right: 0;

              .box-image {
                width: 100%;
                justify-content: center;
                height: 103px;
              }
              img {
                width: 100%;
              }
            }
            .box-3 {
              padding-top: 24px;
              width: 100%;
              padding-right: 0;

              .box-image {
                width: 100%;
                justify-content: center;
                height: 103px;
              }
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .col-lg-6 {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 320px) {
  .tma-innovation .backgroud-image {
    display: none;
  }
  .tma-innovation {
    display: block !important;
    height: auto;
    margin-top: 10em;
    .about-us-content {
      padding-bottom: 100px;
      padding-left: 16px;
      padding-right: 16px;
      .members-two-title {
        text-align: center;
      }
      .about-us-span {
        text-align: center;
      }
      .members-two-box {
        padding-top: 27px;
        flex-direction: column;
        padding-right: 0px;

        .col-box-left {
          max-width: 100%;
          padding-left: 0px;
          padding-right: 0px;
        }
        .col-box-right {
          max-width: 100%;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
    .right-contain {
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        justify-content: center;
        width: 100%;
        align-items: center;
        height: auto;
        .image-members-1-top {
          display: none;
        }
        .content-box {
          padding-left: 16px;
          padding-right: 16px;

          .d-flex {
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            position: relative;
            top: -7em;
            .box-1 {
              padding-top: 24px;
              width: 100%;
              padding-right: 0;
              .box-image {
                width: 100%;
                justify-content: center;
                height: 80px;
              }
              img {
                width: 100%;
              }
            }
            .box-2 {
              padding-top: 24px;
              width: 100%;
              padding-right: 0;

              .box-image {
                width: 100%;
                justify-content: center;
                height: 80px;
              }
              img {
                width: 100%;
              }
            }
            .box-3 {
              padding-top: 24px;
              width: 100%;
              padding-right: 0;

              .box-image {
                width: 100%;
                justify-content: center;
                height: 80px;
              }
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .col-lg-6 {
      max-width: 100%;
    }
  }
}
