.members-four {
  background: #1eb2ff;
  .members-four-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .members-four-contain {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .members-four-contain {
      padding-top: 50px;
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .members-four-contain {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }

  @media (-webkit-device-pixel-ratio: 0.9) {
    .members-four-contain {
      zoom: 80%;
      -moz-transform: scale(0.8);
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .members-four-contain {
      zoom: 80%;
      -moz-transform: scale(0.8);
    }
  }
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    padding-top: 35px;
  }
  .title-span {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #ffffff;
    padding-top: 10px;
  }
  .content {
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-icon {
    padding-bottom: 8px;
    .memberfour-icon {
      width: 48px;
      height: 48px;
    }
  }
  .content-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 146%;
    color: #ffffff;
  }
  .content-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 146%;
    text-align: center;
    color: #ffffff;
  }
  .image-lab-6 {
    // width: 480px;
    width: 100%;
    height: 238px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
  }
  .image-lab-8 {
    // width: 480px;
    width: 100%;
    height: 238px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
  }
  .image {
    padding: 24px 80px 51px 80px;
  }
  .image-col-lab-6 {
    padding-left: 0px;
    padding-right: 12px;
  }
  .image-col-lab-8 {
    padding-right: 0px;
    padding-left: 12px;
  }
  .hr {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 59px;
    margin-top: 15px;
    // transform: rotate(90deg);
    // width: 59px;
  }
  .content-letf {
    padding-right: 40px;
  }
  .content-betwen {
    padding-left: 40px;
    padding-right: 40px;
  }
  .content-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .content-right {
    padding-left: 40px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .members-four {
    .members-four-backgroud {
      .image {
        .image-memberfour {
          .image-col-lab-6 {
            padding-left: 98px !important;
            padding-right: 98px !important;
          }
          .image-col-lab-8 {
            padding-left: 98px !important;
            padding-right: 98px !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
  .members-four {
    margin-bottom: 252px;
    .members-four-backgroud {
      align-items: unset;
      justify-content: unset;
      background-image: none;
      height: auto;
      .members-four-contain {
        padding-left: unset;
        padding-right: unset;
      }
      .members-four-contain {
        .title {
          padding-top: 80px;
        }
      }
      .content {
        padding-top: 60px;
        position: relative;
        .content-member-four {
          display: grid !important;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 32px;
          .content-item {
            width: 100%;
            height: auto;
          }
          .content-letf {
            padding-left: unset;
            padding-right: unset;
          }
          .content-betwen {
            padding-left: unset;
            padding-right: unset;
          }
          .content-right {
            padding-left: unset;
            justify-content: unset;
          }
        }
        .hr {
          display: none;
          // height: unset;
          // width: 0px;
          // margin-top: 40px;
          // margin-bottom: 40px;
          // :last-child {
          //   display: none;
          // }
        }
        .hr1 {
          position: absolute;
          display: block;
          width: 0px;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-top: 0px;
        }
        .hr2 {
          position: absolute;
          display: block;
          width: 0px;
          top: 85%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-top: 0px;
        }
      }
      .image {
        padding-bottom: unset;
        .image-memberfour {
          position: relative;
          margin-bottom: -13em;
          flex-direction: column;
          .image-col-lab-6 {
            max-width: unset;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 36px;
            .image-lab-6 {
              width: 100%;
              height: auto;
            }
          }
          .image-col-lab-8 {
            max-width: unset;
            padding-left: 20px;
            padding-right: 20px;
            .image-lab-8 {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .members-four {
    width: 100%;
    height: auto;

    .title-span {
      display: none;
    }

    .members-four-contain {
      padding-left: unset;
      padding-right: unset;
    }

    .title {
      padding-top: 60px;
      font-size: 38px;
    }

    .members-four-backgroud {
      background-image: none;
      height: auto;
      align-items: unset;
      justify-content: unset;
    }

    .content {
      .content-member-four {
        flex-direction: column;
        align-items: center;
      }

      .content-letf {
        padding-left: unset;
        padding-right: unset;
      }

      .content-betwen {
        padding-left: unset;
        padding-right: unset;
      }

      .content-right {
        padding-left: unset;
      }

      .hr {
        height: unset;
        width: 75px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .image {
      padding-bottom: 60px;
      padding: 24px 0px 18px 0px;
      .image-memberfour {
        flex-direction: column;
        padding: 0px;
        .image-col-lab-6 {
          padding-left: unset;
          padding-right: unset;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 18px;
          .image-lab-6 {
            height: auto;
          }
        }
        .image-col-lab-8 {
          padding-left: unset;
          padding-right: unset;
          padding-left: 16px;
          padding-right: 16px;

          .image-lab-8 {
            height: auto;
          }
        }
      }
    }
  }
}
