@media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
  .TMA-solutions-Binh-Dinh {
    padding-top: 40px;
    zoom: 90%;
    -moz-transform: scale(0.9);
    .image-page-scale {
      bottom: -3em;
    }
  }
}
@media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
  .experienced-in-large-content {
    -moz-transform: scale(0.9);
    zoom: 90%;
  }
  .TMA-solutions-Binh-Dinh {
    .box-right {
      -moz-transform: scale(0.9);
      top: -90px !important;
      zoom: 90%;
    }
  }
}
@media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
  .experienced-in-large-content {
    padding-top: 50px;
    -moz-transform: scale(0.8);
    zoom: 80%;
    right: -50px;
  }
  .TMA-solutions-Binh-Dinh {
    .box-right {
      padding-top: 197px !important;
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: 143px !important;
    }
  }
}
.TMA-solutions-Binh-Dinh {
  flex-wrap: wrap;
  height: calc(100vh - 102px);
  justify-content: center;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 0.9) {
    .experienced-in-large-content {
      zoom: 85%;
      padding-left: 240px !important;
      -moz-transform: scale(0.85);
      .image-page-scale {
        bottom: -3em;
      }
    }
    .box-right {
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .experienced-in-large-content {
      zoom: 85%;
      padding-left: 180px !important;
      -moz-transform: scale(0.85);
      .image-page-scale {
        bottom: -3em;
      }
    }
    .box-right {
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
  }
  .experienced-in-large-content {
    padding-left: 16%;
    padding-right: 0px;
  }
  .members-two-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #222222;
  }
  .members-us-img {
    padding-right: 0 !important;
    background: #1eb2ff;
    height: 100vh;
    // width: 100vh;
    align-items: center;
    display: flex;
    // position: relative;
  }
  .box-right {
    position: relative;
    right: 110px;
    filter: drop-shadow(0px 0px 10px rgba(86, 86, 86, 0.25));
    top: -103px;
    padding-top: 95px;
  }
  .box-content {
    padding-bottom: 12px;
  }
  .box {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    width: 537px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #222222;
  }
  .members-two-box {
    padding-top: 50px;
    padding-right: 10px;
  }
  .about-us-backroud-frame {
    position: absolute;
    right: 0px;
    top: 0px;

    img {
      width: 222px;
      height: 370px;
    }
  }
  .experienced-in-large-right {
    padding-left: 65px;
    padding-right: 00px;
  }
  .image-tma-BD {
    filter: drop-shadow(0px 0px 10px rgba(86, 86, 86, 0.25));
    width: 100%;
    height: 384px;
  }
  .col-box-left {
    width: 100%;
    padding-right: 70px;
    padding-bottom: 125px;
  }
  .TMA-solutions-Binh-Dinh-backgroud {
    position: fixed;
    left: 0px;
    top: 32px;
    display: none;

    .backgroud-image {
      height: 100vh;
    }
  }
  .about-us-span {
    font-size: 20px;
  }
}
@media screen and (max-width: 1677px) {
  .TMA-solutions-Binh-Dinh {
    .experienced-in-large-content {
      padding-left: 9.5%;
    }
  }
}
@media screen and (max-width: 1650px) {
  .TMA-solutions-Binh-Dinh {
    .experienced-in-large-content {
      // padding-left: 60px;
      padding-left: 10%;
    }
  }
}
@media screen and (max-width: 1490px) {
  .TMA-solutions-Binh-Dinh {
    .experienced-in-large-content {
      // padding-left: 60px;
      padding-left: 8%;
    }
  }
}
@media screen and (max-width: 1440px) {
  .TMA-solutions-Binh-Dinh {
    .experienced-in-large-content {
      padding-left: 7%;
    }
  }
}
@media screen and (max-width: 1366px) {
  .TMA-solutions-Binh-Dinh {
    .experienced-in-large-content {
      padding-left: 60px;
    }
  }
  // .technologies-image-backgroud {
  //   top: 75px !important;
  // }
  // .technologies-page {
  //   .about-us-content {
  //     padding-left: 60px;
  //   }
  // }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .TMA-solutions-Binh-Dinh {
    display: block !important;
    height: auto;
    .col-lg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 100%;
    }
    .backgroud-image {
      display: none;
    }
    .experienced-in-large-content {
      margin: 0 auto;
      padding-left: 98px;
      padding-right: 98px;
      .members-two-title {
        text-align: center;
        padding-top: 80px;
      }
      .about-us-span {
        text-align: center;
      }
      .members-two-box {
        .col-box-left {
          width: 100%;
          padding-right: 71px;
          padding-bottom: 125px;
          padding-left: 71px;
          img {
            height: auto;
          }
        }
      }
    }
    .experienced-in-large-right {
      margin: 0 auto;
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        height: auto;
        display: block;
        .box-right {
          right: 0px;
          filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
          top: -160px;
          padding-top: 95px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding-right: 90px;
          padding-left: 85px;
          width: 100%;
          .box-content {
            padding-left: 6px;
            padding-right: 6px;
            width: 50%;
            .box {
              width: 100%;
            }
          }
        }
      }
    }

    .col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      /* flex: 0 0 41.666667%; */
      max-width: 100%;
    }
  }
  .TMA-solutions-Binh-Dinh .box {
    width: 444px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .TMA-solutions-Binh-Dinh {
    display: block !important;
    height: auto;
    .col-lg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 100%;
    }
    .backgroud-image {
      display: none;
    }
    .experienced-in-large-content {
      margin: 0 auto;
      padding-left: 24px;
      padding-right: 24px;
      .members-two-title {
        text-align: center;
        padding-top: 80px;
      }
      .about-us-span {
        text-align: center;
      }
      .members-two-box {
        .col-box-left {
          width: 100%;
          padding-right: 71px;
          padding-bottom: 125px;
          padding-left: 71px;
          img {
            height: auto;
          }
        }
      }
    }
    .experienced-in-large-right {
      margin: 0 auto;
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        height: auto;
        display: block;
        .box-right {
          right: 0px;
          filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
          top: -160px;
          padding-top: 95px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding-right: 24px;
          padding-left: 24px;
          width: 100%;
          .box-content {
            padding-left: 6px;
            padding-right: 6px;
            width: 50%;
            .box {
              width: 100%;
            }
          }
        }
      }
    }

    .col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      /* flex: 0 0 41.666667%; */
      max-width: 100%;
    }
  }
  .TMA-solutions-Binh-Dinh .box {
    width: 444px;
  }
}

@media screen and (max-width: 767px) {
  .TMA-solutions-Binh-Dinh {
    display: block !important;
    height: auto;
    .backgroud-image {
      display: none;
    }
    .experienced-in-large-content {
      margin: 0 auto;
      padding-left: 0px;
      padding-right: 0px;
      .members-two-title {
        font-family: "IBM Plex Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 120%;
        text-align: center;
        text-transform: capitalize;
        padding-top: 60px;
      }
      .about-us-span {
        font-family: "IBM Plex Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 10px;
      }
      .members-two-box {
        padding-right: 16px;
        padding-left: 16px;
        .col-box-left {
          width: 100%;
          padding-right: 0px;
          padding-bottom: 125px;
          padding-left: 0px;
          img {
            height: auto;
          }
        }
      }
    }
    .experienced-in-large-right {
      margin: 0 auto;
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        height: auto;
        justify-content: center;
        .box-right {
          position: relative;
          right: 0px;
          filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
          top: -190px;
          padding-top: 95px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding-right: 10px;
          padding-left: 10px;
          width: 100%;
          .box-content {
            padding-left: 6px;
            padding-right: 6px;
            width: 100%;
          }
        }
        .d-flex {
          width: 100%;
        }
      }
    }

    .col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      /* flex: 0 0 41.666667%; */
      max-width: 100%;
    }
  }
  .TMA-solutions-Binh-Dinh .box {
    width: 100%;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 120%;
    text-align: center;
  }
}
