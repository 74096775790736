.technologies-page {
	flex-wrap: wrap;
	height: calc(100vh - 102px);
	align-items: center;
	.about-us-content {
		// padding-top: 68px ;
		padding-left: 16%;
		padding-right: 40px;
		margin-top: -100px;
	}
	@media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
		.about-us-content {
			-moz-transform: scale(0.8);
			zoom: 80%;
			right: -50px;
		}

		.technologies-image-backgroud {
			-moz-transform: scale(0.8);
			zoom: 80%;
			right: -50px;
		}
	}

	@media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
		.about-us-content {
			zoom: 85%;
			right: -70px;
			-moz-transform: scale(0.85);
			top: -8% !important;
		}
		.technologies-image-backgroud {
			zoom: 85%;
			-moz-transform: scale(0.85);
			top: -10% !important;
		}
	}
	.technologies-title {
		font-weight: 700;
		font-size: 48px;
		line-height: 120%;
		color: #222222;
	}
	.title-mobile {
		display: none;
	}
	.technologies-backgroud-right {
		padding-left: 130px;
		padding-right: 0px;
	}
	.technologies-img {
		padding-right: 0 !important;
		background: #1eb2ff;
		height: 100vh;
		// width: 100vh;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
	.technologies-box {
		padding-top: 48px;
	}
	.technologies-backgroud-right {
		padding-left: 130px;
	}
	.box-content {
		height: 90px;
		align-items: center;
		background: #ffffff;
		box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
		margin-bottom: 16px;
		// width: 293px;
	}
	.box-icon {
		height: 100%;
		background: #1eb2ff;
		width: 112px;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.box-text {
		padding-left: 25px;
		font-weight: 600;
	}
	.box-icon-img {
		width: 48px;
		height: 48px;
	}
	.col-box-left {
		padding-left: 0px;
		padding-right: 8px;
	}
	.col-box-right {
		padding-left: 8px;
		padding-right: 0px;
	}
	.box-content-img {
		height: 32px;
		width: 32px;
	}
	.box-content-text {
		padding-left: 8px;
	}
	.technologies-backroud-frame {
		position: absolute;
		right: 0px;
		top: 0;
		img {
			width: 222px;
			height: 370px;
		}
	}
	.technologies-backgroud-one {
		padding-right: 110px;
	}
	.technologies-image-backgroud {
		position: relative;
		left: -10%;
		padding-top: -3%;
		top: -6%;
	}
	img.technologies-image-one {
		width: 580px;
		height: 181px;
	}

	.technologies-image-one {
		filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.16));
	}
	.technologies-image-two {
		filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.16));
		height: 316px;
		width: 332px;
	}
	.technologies-image-three {
		filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
		padding-left: 16px;
		height: 316px;
		width: 100%;
	}
	.technologies-image-bottom {
		padding-top: 16px;
	}
}
.technologies-cotnainer {
	.members-two-backgroud {
		position: fixed;
		left: 0px;
		top: 32px;
		display: none;

		.backgroud-image {
			height: 100vh;
		}
	}
	.col-right-page {
		padding-right: 0px;
	}
}
@media screen and (min-width: 1921px) {
	.technologies-page {
		.about-us-content {
			padding-left: 9.5%;
		}
		.technologies-backgroud-right {
			padding-left: 207px;
		}
		.technologies-image-backgroud {
			left: -16%;
		}
		.about-us-content {
			padding-left: 12.5%;
		}
	}
}
@media screen and (max-width: 1920px) {
	.technologies-page {
		.about-us-content {
			padding-left: 9.5%;
		}
		.technologies-backgroud-right {
			padding-left: 207px;
		}
		.technologies-image-backgroud {
			left: -14%;
		}
		.about-us-content {
			padding-left: 11.5%;
		}
	}
}
@media screen and (max-width: 1677px) {
	.technologies-page {
		.about-us-content {
			padding-left: 9.5%;
		}
	}
}
@media screen and (max-width: 1650px) {
	.technologies-page {
		.about-us-content {
			padding-left: 10%;
		}
	}
}
@media screen and (max-width: 1490px) {
	.technologies-page {
		.about-us-content {
			padding-left: 8%;
		}
	}
}
@media screen and (max-width: 1440px) {
	.technologies-page {
		.about-us-content {
			padding-left: 6%;
		}
	}
}
@media screen and (max-width: 1366px) {
	.technologies-page {
		.about-us-content {
			padding-left: 60px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.technologies-page .technologies-img {
		width: 100% !important;
	}
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
	.technologies-page {
		.title-mobile {
			display: block !important;
		}
		.title-desktop {
			display: none !important;
		}
	}
	.technologies-cotnainer {
		.technologies-page {
			.about-us-content {
				padding-left: 98px !important;
				padding-right: 98px !important;
			}
			.members-two-backgroud {
				.backgroud-image {
					display: none;
				}
			}
			.technologies-image-backgroud {
				.technologies-backgroud-one {
					margin-left: 98px;
					margin-right: 98px;
					padding: unset;
				}
			}
			.technologies-img {
				.technologies-image-backgroud {
					.technologies-image-bottom {
						padding-left: 98px;
						padding-right: 98px;
						margin-right: 0px;
						.image-left-techonology {
							width: 100%;
							.technologies-image-two {
								width: 97.7%;
							}
						}
						.image-right-techonology {
							width: 68%;
							.technologies-image-three {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
	.technologies-cotnainer {
		width: 100%;
		padding-top: 98px;
		height: auto;
		.technologies-box {
			font-size: 20px;
		}
		.technologies-page {
			.title-mobile {
				display: block !important;
			}
			.title-desktop {
				display: none !important;
			}
			.about-us-content {
				padding-left: 24px;
				padding-right: 24px;
			}
		}
		.about-us-content {
			flex: unset;
			max-width: unset;
		}
		.box-text {
			font-weight: bold;
		}
		.members-two-backgroud {
			.backgroud-image {
				display: none;
			}
		}
	}

	.technologies-page-backgroud .backgroud-image {
		display: none;
	}
	.container-techonologies {
		width: 767px;
	}

	.technologies-page {
		height: auto;
	}
	.technologies-backgroud-one .technologies-image-one {
		margin-top: -9em;
		width: 100% !important;
		height: auto !important;
	}
	.technologies-page .technologies-image-backgroud {
		left: 0 !important;
		position: relative;
		top: 75px;
		padding-bottom: 80px;
	}

	.technologies-cotnainer .technologies-title {
		width: 100%;
		display: flex;
		padding-top: 80px;
		text-align: center;
		justify-content: center;
	}

	.technologies-cotnainer .technologies-backgroud-right {
		padding-left: 0;
		padding-top: 5em;
		max-width: unset;
		flex: unset;
	}

	.technologies-backroud-frame {
		display: none;
	}

	.technologies-page .technologies-img {
		width: 100%;
		height: auto;
	}

	.technologies-page .technologies-backgroud-one {
		padding-right: 24px;
		padding-left: 24px;
	}
	.technologies-img .technologies-image-bottom {
		width: auto;
		padding-left: 24px;
		padding-right: 24px;
		height: auto;
	}
	.technologies-img .image-left-techonology {
		width: 101%;
		height: auto;
	}
	.technologies-img .image-right-techonology {
		width: 68%;
		height: auto;
	}
	.technologies-img .technologies-image-two {
		width: 97%;
		height: auto;
	}
	.technologies-img .technologies-image-three {
		width: 100%;
		height: auto;
		padding-left: unset;
	}
}

@media screen and (max-width: 767px) {
	.technologies-cotnainer {
		.members-two-backgroud {
			.backgroud-image {
				display: none;
			}
		}
		.technologies-page {
			height: auto;
			.col-box-left {
				flex: unset;
				max-width: unset;
				padding-left: 16px;
				padding-right: 16px;
				font-weight: bold;
			}
			.col-box-right {
				flex: unset;
				max-width: unset;
				padding-left: 16px;
				padding-right: 16px;
				font-weight: bold;
			}
		}
		.title-desktop {
			display: none;
		}
		.title-mobile {
			display: block;
		}
		.technologies-title {
			text-align: center;
			padding-left: 44px;
			padding-right: 44px;
			font-size: 38px;
		}

		.technologies-box {
			flex-direction: column;
			width: 100%;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			padding-bottom: 120px;
		}
		.about-us-content {
			margin-top: 60px;
			padding-left: unset !important;
			padding-right: unset;
			width: 100%;
			height: auto;
		}
		.box-right-mr {
			padding-left: 0;
		}
		.technologies-page-backgroud {
			.backgroud-image {
				display: none;
			}
		}
		.technologies-backgroud-right {
			padding-left: unset;
			.technologies-img {
				width: 100%;
				height: unset;
				.technologies-backroud-frame {
					display: none;
				}
				.technologies-image-backgroud {
					position: unset;
					left: 0px;
					width: 100%;

					padding-left: 16px;
					padding-right: 16px;
					position: relative;
					top: -7em !important;

					.technologies-backgroud-one {
						padding-right: unset;
						.technologies-image-one {
							width: 100%;
							height: auto;
						}
					}
					.technologies-image-bottom {
						padding-top: 10px;
						.image-left-techonology {
							padding-right: 9px;
							.technologies-image-two {
								width: 100%;
								height: auto;
							}
						}
						.image-right-techonology {
							.technologies-image-three {
								width: 100%;
								height: auto;
								padding-left: unset;
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (min-width: 535px) and (max-width: 645px) {
	.technologies-cotnainer {
		.technologies-page {
			.technologies-box {
			}
			.technologies-backgroud-right {
				.technologies-img {
					padding-top: 19px;
				}
			}
		}
	}
}
@media screen and (min-width: 435px) and (max-width: 534px) {
	.technologies-cotnainer {
		.technologies-page {
			.technologies-box {
				padding-bottom: 86px;
			}
			.technologies-backgroud-right {
				.technologies-img {
					padding-top: 38px;
				}
			}
		}
	}
}
@media screen and (min-width: 350px) and (max-width: 434px) {
	.technologies-cotnainer {
		.technologies-page {
			.technologies-image-backgroud {
				top: -6em !important;
			}
			.technologies-box {
				padding-bottom: 86px;
			}
			.technologies-backgroud-right {
				.technologies-img {
					padding-top: 38px;
				}
			}
		}
	}
}
@media screen and (min-width: 250px) and (max-width: 349px) {
	.technologies-cotnainer {
		.technologies-page {
			.technologies-image-backgroud {
				top: -6em !important;
			}
			.technologies-box {
				padding-bottom: 53px;
			}
			.technologies-backgroud-right {
				.technologies-img {
					padding-top: 64px;
				}
			}
		}
	}
}
