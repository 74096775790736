.members-two {
  flex-wrap: wrap;
  height: calc(100vh - 102px);
  justify-content: center;
  align-items: center;
  .about-us-content {
    // padding-top: 68px;
    padding-left: 16%;
    padding-right: 78px;
    margin-top: -150px;
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .members-two-title {
      padding-top: 40px;
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
    .membertwo-title {
      padding-top: 40px;
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
    .member-container-image {
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
    .member-container-image-vn {
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .membertwo-title {
      padding-top: 40px;
      -moz-transform: scale(0.8);
    }
    .member-container-image {
      .image-members-1-top {
        -moz-transform: scale(0.8);
        right: 90px;
        top: -66px;
        zoom: 80%;
      }
      .image-about-us-lab8 {
        zoom: 90%;
        -moz-transform: scale(0.8);
        top: 15px;
        margin-left: -27px;
      }
    }
    .member-container-image-vn {
      -moz-transform: scale(0.8);
      .image-members-1-top {
        zoom: 90%;
        right: 160px !important;
      }
    }
  }
  @media (-webkit-device-pixel-ratio: 0.9) {
    .membertwo-title {
      zoom: 80%;
      -moz-transform: scale(0.8);
    }
    .member-container-image {
      .image-members-1-top {
        -moz-transform: scale(0.9);
          top: -80px;
        zoom: 90%;
      }
      .image-about-us-lab8 {
        zoom: 90%;
        -moz-transform: scale(0.9);
        top: 15px;
    
      }
    }
    .member-container-image-vn {
      .image-members-1-top {
        zoom: 75%;
        top: -85px;
        right: 45px !important;
        -moz-transform: scale(0.8);
      }
      .image-about-us-lab8 {
        zoom: 75%;
        -moz-transform: scale(0.75);
        top: 15px;
    
      }
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .membertwo-title {
      zoom: 80%;
      -moz-transform: scale(0.8);
    }
    .member-container-image {
      .image-members-1-top {
        -moz-transform: scale(0.75);
          top: -80px;
        zoom: 75%;
      }
      .image-about-us-lab8 {
        zoom: 75%;
        -moz-transform: scale(0.75);
        top: 15px;
    
      }
    }
    .member-container-image-vn {
      .image-members-1-top {
        zoom: 75%;
        top: -85px;
        right: 45px !important;
        -moz-transform: scale(0.8);
      }
      .image-about-us-lab8 {
        zoom: 75%;
        -moz-transform: scale(0.75);
        top: 15px;
    
      }
    }
  }
  .members-two-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: #222222;
    padding-bottom: 8px;
    padding-top: 40px;
  }
  .about-us-span {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #222222;
    padding-right: 15px;
  }
  .members-us-img {
    padding-right: 0 !important;
    background: #1eb2ff;
    height: 100vh;
    // width: 100vh;
    align-items: center;
    display: flex;
    // position: relative;
  }
  .image-members-1-top {
    position: relative;
    right: 60px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    top: -94px;
  }
  .image-about-us-lab8 {
    padding-top: 194px;
    position: relative;
    left: -430px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    padding-bottom: 20px;
  }
  .image-members-1 {
    width: 489px;
    height: 263px;
  }
  .image-members-2 {
    width: 440px;
    height: 263px;
  }
  .members-two-box {
    padding-top: 32px;
    padding-right: 20px;
  }
  .box-content {
    justify-content: center;
    height: 89px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    margin-bottom: 12px;
    text-align: center;
  }
  .col-box-left {
    padding-left: 0px;
    padding-right: 6px;
  }
  .col-box-right {
    padding-left: 6px;
    padding-right: 0px;
  }
  .box-content-img {
    height: 32px;
    width: 32px;
  }
  .box-content-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #222222;
    padding-top: 23px;
  }
  .about-us-backroud-frame {
    position: absolute;
    right: 0px;
    top: 0px;
    img {
      width: 222px;
      height: 370px;
    }
  }
  .e-commerce-retail {
    padding-top: 23px;
    padding-right: 22px;
    padding-left: 20px;
  }
  .e-commerce-retail-VN {
    padding-top: 25px;
  }
  .members-two-backgroud {
    position: fixed;
    left: 0px;
    top: 32px;
    display: none;

    .backgroud-image {
      height: 100vh;
    }
  }
  .col-right-page {
    padding-right: 0px;
  }
}
@media screen and (max-width: 1677px) {
  .members-two {
    .about-us-content {
      padding-left: 9.5%;
    }
  }
}
@media screen and (max-width: 1650px) {
  .members-two {
    .about-us-content {
      padding-left: 10%;
    }
  }
}
@media screen and (max-width: 1490px) {
  .members-two {
    .about-us-content {
      padding-left: 8%;
    }
  }
}
@media screen and (max-width: 1440px) {
  .members-two {
    .about-us-content {
      padding-left: 6%;
    }
  }
}
@media screen and (max-width: 1369px) {
  .members-two {
    .e-commerce-retail {
      padding-top: 11px !important;
    }
  }
}

@media screen and (max-width: 1366px) {
  .members-two {
    .about-us-content {
      padding-left: 60px;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .members-two {
    .membertwo-title {
      .members-two-box {
        padding-left: 98px;
        padding-right: 98px;
      }
    }
    .members-us-img {
      .image-members-1-top {
        .image-members-1 {
          padding-left: 158px !important;
        }
      }
      .image-about-us-lab8 {
        .image-members-2 {
          padding-right: 98px !important;
        }
      }
    }
  }
  .members-us-img {
    .image-members-1-top {
      .image-members-1 {
        padding-left: 158px !important;
      }
    }
    .image-about-us-lab8 {
      .image-members-2 {
        padding-right: 98px !important;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .members-two {
    height: auto;
    width: 100%;
    //  margin-top: 252px;
    .backgroud-image {
      display: none;
    }
    .membertwo-title {
      padding-left: unset;
      margin-top: unset;
      text-align: center;
      padding-right: unset;
      flex: unset;
      max-width: unset;
    }
    .members-two-box {
      padding-left: 24px;
      padding-bottom: 120px;
      padding-top: 52px;
      .col-box-left {
        .box-content {
          padding-top: 20px;
          padding-bottom: 20px;
          .box-content-text {
            padding-top: unset !important;
            padding-bottom: 2px;
          }
        }
      }
      .col-box-right {
        .box-content {
          padding-top: 20px;
          padding-bottom: 20px;
          .box-content-text {
            padding-top: unset;
            padding-bottom: 2px;
          }
          .box-content-app {
            padding-left: 50px;
            padding-right: 50px;
          }
        }
      }
    }
    .membertwo-bottom {
      max-width: unset;
      flex: unset;
      padding-left: unset;
      height: auto;
      width: 100%;
    }
    .member-container-image {
      width: 100%;
      height: auto;
      flex-direction: column;
    }

    .member-container-image-vn {
      width: 100%;
      height: auto;
      flex-direction: column;
      .image-about-us-lab8 {
        left: unset;
        padding-top: unset;
        padding-bottom: unset;
        width: 100%;
        height: auto;
        .image-members-2 {
          width: 100%;
          padding-left: 13em;
          padding-right: 21px;
          margin-top: -60px;
          height: auto;
          padding-bottom: 10px;
        }
      }
      .image-members-1-top {
        width: 100%;
        height: auto;
        .image-members-1 {
          width: 83%;
          height: auto;
          padding-left: 83px;
        }
      }
    }
    .members-us-img {
      height: auto;

      .member-container-image {
        .image-about-us-lab8 {
          left: unset;
          padding-top: unset;
          padding-bottom: unset;
          width: 100%;
          height: auto;
          .image-members-2 {
            width: 100%;
            padding-left: 13em;
            padding-right: 21px;
            margin-top: -60px;
            height: auto;
            padding-bottom: 10px;
          }
        }
        .image-members-1-top {
          width: 100%;
          height: auto;
          .image-members-1 {
            width: 83%;
            height: auto;
            padding-left: 83px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .members-two .backgroud-image {
    display: none;
  }

  .members-two {
    margin-top: 12em;
    height: auto;
    .about-us-content {
      padding-left: 16px;
      padding-right: 16px;
    }
    .col-box-left {
      max-width: unset;
      padding-right: 0;
    }
    .col-box-right {
      max-width: unset;
    }
    .members-two-title {
      text-align: center;
    }
    .about-us-span {
      text-align: center;
    }
    .members-two-box {
      flex-direction: column;
      align-items: center;
      padding-right: unset;
      .col-box-right {
        padding-left: unset;
      }
      .box-content {
        height: auto;
        .membertwo-engineer {
          padding-bottom: 20px;
        }
      }
      .box-content-text {
        padding-top: 23px !important;
        padding-bottom: 5px;
        padding-left: unset;
        padding-right: unset;
      }
    }
    .col-right-page {
      padding-left: unset;
    }
    .members-us-img {
      height: auto;
      margin-top: 7em;
    }

    .members-us-img {
      .image-members-1-top {
        margin-top: -4em;
        margin-bottom: 17px;
        top: unset;
        display: flex;
        right: unset;
        justify-content: center;
        width: 100%;
        align-items: center;
        height: auto;
        .image-members-1 {
          width: 100%;
          height: auto;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      .image-about-us-lab8 {
        padding: unset;
        left: unset;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        .image-members-2 {
          width: 100%;
          height: auto;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }

    .member-container-image {
      width: 100%;
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .member-container-image-vn {
      width: 100%;
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image-members-1-top {
        justify-content: unset;
        .image-members-1 {
          width: 82% !important;
        }
      }
      .image-about-us-lab8 {
        justify-content: right;

        .image-members-2 {
          width: 82% !important;
        }
      }
    }
  }
}
