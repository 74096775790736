.university-cooperation {
  background: #1eb2ff;
  .university-cooperation-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // .box-content-uni{
  //   display: none;
  // }
  .university-cooperation-contain {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .university-cooperation-contain {
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .university-cooperation-contain {
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .university-cooperation-contain {
      -moz-transform: scale(0.8);
      zoom: 80%;
      padding-top: 70px !important;
    }
  }
  @media (-webkit-device-pixel-ratio: 0.8) {
    .university-cooperation-contain {
      padding-top: 40px;
  }

}
  @media (-webkit-device-pixel-ratio: 0.9) {
    .university-cooperation-contain {
      padding-top: 20px;
    zoom: 90%;
    -moz-transform: scale(0.9);
  }

}
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .university-cooperation-contain {
      zoom: 80%;
      padding-top: 20px;
      -moz-transform: scale(0.8);
    }
  
}
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    margin-top: -84px;
  }
  .content {
    padding-top: 48px;
  }
  .content-icon {
    padding-bottom: 8px;
    padding-top: 15px;
    .image-university {
      width: 48px;
      height: 48px;
    }
  }
  .content-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 146%;
    color: #ffffff;
  }
  .content-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 146%;
    color: #ffffff;
  }
  .content-letf {
    padding-left: 120px;
    padding-right: 65px;
  }
  .content-betwen {
    padding-right: 65px;
  }
  .contain-content {
    text-align: left;
    padding-left: 8px;
  }
  .image-inno-park {
    width: 100%;
    height: 238px;
  }
  .image-col-1 {
    padding-left: 0px;
    padding-right: 12px;
  }
  .image-col-2 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .image-col-3 {
    padding-left: 12px;
    padding-right: 0px;
  }
  .box-content {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    height: 282px;
    padding-top: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }
  .box > ul > li {
    text-align: left;
  }
  .box > ul {
    margin-left: 10px;
  }
  .box-li {
    padding-bottom: 16px;
  }
  .col-box {
    padding: 0px;
  }
  .col-box-right {
    padding-right: 0px;
    padding-left: 16px;
  }
  .image {
    width: 100%;
    height: 282px;
  }
  .content-image {
    padding-top: 48px;
  }
  .image-right-1 {
    padding-right: 12px;
  }
}
@media screen and (min-width: 1366px) {
  .university-cooperation {
    .box-content-2 {
      display: none;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .university-cooperation {
    margin-top: -5px;
    .university-cooperation-backgroud {
      .university-cooperation-contain {
        padding-left: 98px !important;
        padding-right: 98px !important;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
  .university-cooperation {
    margin-top: -5px;
    .box-content {
      display: none;
    }
    .box-content-2 {
      background: #ffffff;
      box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
      height: 282px;
      padding-top: 48px;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      height: auto;
      padding-bottom: 32px;
      padding-left: 15px;
      ul {
        padding-left: 25px;
        margin-left: unset;
        width: 33.33%;
      }
    }
    .university-cooperation-backgroud {
      margin-top: -1px;
      background-image: none;
      align-items: unset;
      height: unset;
      .university-cooperation-contain {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 15px;
        .title {
          // padding-top: 45px;
          margin-top: 80px;
        }
        .university-content {
          padding-top: 60px;
        }
        .university-content-flex {
          .image-university {
            margin-top: -6px;
          }
          flex-wrap: wrap;
          .content-letf {
            padding: unset;
            width: 50%;
            height: auto;
            padding-bottom: 32px;
          }
        }

        .content-image {
          .box-content {
            height: auto;
            padding-bottom: 48px;
          }

          .content-image-bottom {
            flex-direction: column;
            .col-box-bottom {
              max-width: unset;
              padding-bottom: 40px;
            }
            .col-box-right {
              max-width: unset;
              padding-left: unset;
              .image-right-1 {
                width: 61%;
                height: auto;
                .image {
                  width: 100%;
                  height: auto;
                }
              }
              .image-right-2 {
                width: 43%;
                height: auto;
                .image {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .university-cooperation {
    .box-content-2 {
      display: none;
    }

    .university-cooperation-backgroud {
      margin-top: -1px;
      background-image: none;
      align-items: unset;
      height: unset;
      .university-cooperation-contain {
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 4px;
        .title {
          margin-top: unset;
          padding-top: 60px;
          padding-bottom: 40px;
          font-size: 38px;
        }
        .contain-content {
          .content-title {
            font-size: 20px;
          }
          .content-text {
            font-size: 16px;
          }
        }
        .university-content {
          padding-top: unset;
          .university-content-flex {
            .content-letf {
              .content-icon {
                padding-top: unset;
              }
            }
            .content-betwen {
              padding-bottom: 32px;
              width: 100%;
              .content-icon {
                padding-top: unset;
              }
            }
            .content-right {
              // padding-bottom: 32px;
              .content-icon {
                padding-top: unset;
              }
            }
            .image-university {
              // margin-top: -6px;
            }
            flex-wrap: wrap;
            .content-letf {
              padding: unset;
              width: 100%;
              height: auto;
              padding-bottom: 32px;
            }
          }
        }
        .content-image {
          padding-top: 40px;
          .box-content {
            padding-top: unset;
            height: auto;
            padding-top: 32px;
            padding-left: 32px;
            padding-bottom: 32px;
            margin-bottom: 12px;
            .content-box {
              flex-direction: column;
            }
          }
          .content-image-bottom {
            flex-direction: column;
          }
          .col-box-right {
            padding-left: unset;
            flex-direction: column;
            .image-right-1 {
              width: 100%;
              padding-right: unset;
              height: auto;
              padding-bottom: 12px;
              .image {
                width: 100%;
                height: auto;
              }
            }
            .image-right-2 {
              width: 100%;
              height: auto;
              .image {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
  .university-cooperation {
    .image-right-1 {
      width: 61.5% !important;
    }
  }
}
@media screen and (min-width: 1300px) and (max-width: 1365px) {
  .university-cooperation {
    .image-right-2 {
      width: 43.2% !important;
    }
  }
}
