@media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
  .history-tow-page{
    .history-content {
      zoom: 90%;
      -moz-transform: scale(0.85);
    }
  }
  
}
.history-tow-page {
  flex-wrap: wrap;
  .history-page-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background: #1eb2ff;
    display: flex;
    align-items: center;
  }
  .history-page-backgroud-img {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 100%;
  }
 
  .history-img {
    width: 100%;
  }
  .history-page-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
  }
  .history-page-content {
    padding-top: 0px;
    width: 100%;
  }
  .history-content {
    display: flex;
    padding-top: 56px;
    position: relative;
    padding-right: 60px;
  }
  .history-content-img {
    width: 312px;
    height: 366px;
  }
  .history-line {
    width: 100%;
  }
  .history-content-item {
    width: 100%;
    position: relative;
  }

  .history-circle {
    border-radius: 50%;
    width: 14px;
    background: #f8f9fa;
    height: 14px;
    position: absolute;
    top: -7px;
  }
  .circle-1 {
    left: 5%;
  }
  .circle-2 {
    left: 38%;
  }
  .circle-3 {
    left: 71%;
  }
   .circle-1-vn {
    left: 5%;
  }
  .circle-2-vn {
    left: 38%;
  }
  .circle-3-vn {
    left: 71%;
  }
  .history-line-year {
    display: flex;
    padding-bottom: 30px;
    position: relative;
  }
  .history-box-year {
    width: 108px;
    height: 44px;
    background: #ffff;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #222222;
  }
  .box-1 {
    padding-left: 5%;
  }
  .box-2 {
    position: absolute;
    left: 38%;
  }
  .box-3 {
    position: absolute;
    left: 71%;
  }
  .history-text {
    border-top: 1px solid #ffffff;
    display: flex;
    padding-top: 38px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
    position: relative;
    padding-left: 5%;
  }
  .history-text-ul {
    padding-left: 20px;
  }
  .ul-1 {
    width: 289px;
  }
  .ul-2 {
    position: absolute;
    left: 38%;
    width: 220px;
  }
  .ul-2-VN {
    position: absolute;
    left: 38%;
    width: 272px;
  }
  .ul-3 {
    position: absolute;
    left: 71%;
    width: 244px;
    
  }
  .ul-3-VN {
    position: absolute;
    left: 71%;
    width: 222px;
    padding-right: 45px;
  }
  .history-text-ul > li {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 1920px) {
  .history-tow-page  {
    .history-page-content {
      // padding-top: 10%;
    }
    .ul-3-VN {
      width: unset;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .history-tow-page {
    .history-content{
      padding-left: 98px !important;
      padding-right: 98px !important;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
  .history-tow-page {
    width: 100%;
    height: auto;
    background-color: #1eb2ff;
  }
  .history-img {
    display: none;
  }

  .history-page-content {
    padding-top: 91px !important;
  }
  .history-tow-page .title-2 {
    display: none;
  }
  .history-tow-page .circle-1 {
    margin-left: 2px;
    z-index: 1;
    left: unset;
    margin-top: 46px;
  } .history-tow-page .circle-1-vn {
    margin-left: 2px;
    z-index: 1;
    left: unset;
    margin-top: 23px;
  }
  .history-tow-page .circle-2 {
    margin-top: 20em !important;
    margin-left: 2px;
    left: unset;
  } .history-tow-page .circle-2-vn {
    margin-top: 272px !important;
    margin-left: 2px;
    left: unset;
  }
  .history-tow-page .history-text {
    margin-left: 10px;
    width: 88%;
    margin-top: -27em;
    height: 47em;
    border-top: none;
    border-left: 2px solid;
    line-height: 150%;
  }
  .history-tow-page .ul-3-VN {
    margin-top: 30em !important;
  }
  .history-tow-page .ul-two-2 {
    margin-left: -8px;
  
    min-width: 100%;
    padding-top: 326px;

    left: unset;
  }
  .history-tow-page .ul-2-VN {
    margin-left: -8px;
  
    min-width: 100%;
    padding-top: 280px;

    left: unset;
  }
  .history-tow-page .box-two-1 {
    position: absolute;
    margin-left: 45px;
    padding-left: unset;
    margin-top: -382px;
  }
  .history-tow-page .box-two-1-vn {
    position: absolute;
    margin-left: 45px;
    padding-left: unset;
    margin-top: -404px;
  }
  .history-tow-page .ul-two-1 {
    margin-left: -8px;
    margin-top: 46px;
    min-width: 87%;
  }
  .history-tow-page .ul-two-1-vn {
    margin-left: -8px;
    min-width: 100%;
    padding-top: 25px;
    left: unset;
  }

  .history-tow-page .box-two-2 {
    margin-top: -295px;
    margin-left: 45px;
    left: unset;
  }
  .history-tow-page .box-two-2-vn {
    margin-top: -345px;
    margin-left: 45px;
    left: unset;
  }
  .history-tow-page .history-tow-page .circle-2 {
    left: 125px;
    margin-top: 29%;
  }


  .history-tow-page .box-two-3 {
    margin-left: 45px;
    margin-top: 147px;
    left: unset !important;
  } .history-tow-page .box-two-3-vn {
    margin-left: 45px;
    margin-top: 98px;
    left: unset !important;
  }
  .history-tow-page .circle-3 {
    left: 10px;
    top: 100px;
  } .history-tow-page .circle-3-vn {
    left: 10px;
    top: 520px;
  }

  .history-tow-page .ul-two-3 {
    margin-top: 32em;
    min-width: 100%;
    margin-left: -8px;
    left: unset;
  }

  .history-tow-page .history-page-backgroud {
    height: auto !important;
  }
  .history-tow-page .img-two-page {
    padding-bottom: 80px;
  }
  .history-tow-page .history-content-vn {
    padding-top: 125px !important;
  }
  .history-tow-page .history-text-ul {
    font-size: 18px;
  }
  .history-tow-page .history-page-backgroud-img {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .history-tow-page {
    width: 100%;
    height: auto;
    .history-page-backgroud {
      height: auto;
    }
    .history-content-item {
      height: 715px;
    }
    .title-2 {
      display: none;
    }
    .history-content {
      flex-direction: column;
      padding-right: unset;
      padding-top: unset;
      margin-top: -73px;
    }
    .img-two-page {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      height: auto;
      padding-bottom: 60px;
    }
    .history-page-content {
      padding-top: unset;
    }
    .history-line-year {
      .box-1{
        padding-top: 35px;
      }
      .box-two-1 {
        margin-left: 3em;
        padding-left: unset;
       
      }
      .box-two-1-vn {
        margin-left: 3em;
        padding-left: unset;
       
      }
      .box-two-2 {
        left: unset;
        padding-top: 318px;
        padding-left: 3em;
      }
      .box-two-2-vn {
        left: unset;
        padding-top: 290px;
        padding-left: 3em;
      }
      .box-two-3 {
        left: 0;
        margin-top: 565px;
        margin-left: 3em;
      }
      .box-two-3-vn{
        left: 0;
        margin-top: 530px;
        margin-left: 3em;
      }
    }
    .history-text {
      font-size: 18px;
      padding-left: 2em;
      margin-top: -4em;
      margin-left: 25px;
      border-top: none;
      border-left: 1px solid;
      height: 715px;
      padding-right: 15px;
      width:90%;
      .ul-two-1 {
        margin-top: 23px;
        padding-left: unset;
        margin-left: 11px;
        width: 100%;
      }  .ul-two-1-vn {
        margin-top: 23px;
        padding-left: unset;
        margin-left: 11px;
        width: 100%;
      }
      .ul-two-2 {
        left: 0;
        margin-top: 17em;
        margin-left: 23px;
        width:90%;
      }
      .ul-2-VN{
        left: 0;
        margin-top: 280px;
        margin-left: 23px;
        width: 90%;
      }
      .ul-two-3 {
        left: 0;
        margin-top: 555px;
        margin-left: 23px;
        width: 95%;
      }
      .ul-3-VN {
        left: 0;
        margin-top: 29em;
        margin-left: 23px;
        width: 90%;
      }
    }
    .history-content-item {
     
      .circle-1 {
        left: unset;
        margin-left: 18px;
        margin-top: 20px;
      }
      .circle-1-vn {
        left: unset;
        margin-left: 18px;
        margin-top: 20px;
      }
      .circle-2 {
        left: 0;
        margin-top: 19em;
        margin-left: 18px;
      }
      .circle-2-vn{
        left: 0;
        margin-top: 275px;
        margin-left: 18px;
      }
      .circle-3 {
        left: 0;
        margin-top: 556px;
        margin-left: 18px;
      }
      .circle-3-vn {
        left: 0;
        margin-top: 518px;
        margin-left: 18px;
      }
    }
    .history-page-backgroud-img {
      display: none;
    }
  }
}
@media screen and (max-width:542px) {
  .history-tow-page{
    .history-content-item{
      height: 639px;
    }
    .history-line{
      .box-two-2{
        padding-top: 289px;
      }.box-two-2-vn{
        padding-top: 271px;
      }
      .box-two-3{
        margin-top: 505px;
      }.box-two-3-vn{
        margin-top: 485px;
      }
      .circle-1{
        margin-top: 6px;
      }.circle-1-vn{
        margin-top: 6px;
      }.circle-2-vn{
        margin-top: 241px;
      }
      .circle-2{
        margin-top: 275px;
      }
      .circle-3{
        margin-top: 475px;
      }.circle-3-vn{
        margin-top: 453px;
      }
      .history-text{
        height: 639px;
        font-size: 15px;
        line-height: 120%;
        padding-top: 22px;
        .ul-two-2{
          margin-top: 275px;
        } .ul-2-VN{
          margin-top: 260px;
        }
        .ul-3-VN{
          margin-top: 480px !important;
        }
        .ul-two-3{
          margin-top: 497px
        }
      }
    }
  }
  
}
@media screen and (max-width:348px) {
  .history-tow-page{
    .history-content-item{
      height: 639px;
    }
    .history-line{
      .box-two-2{
        padding-top: 289px;
      }.box-two-2-vn{
        padding-top: 271px;
      }
      .box-two-3{
        margin-top: 505px;
      }.box-two-3-vn{
        margin-top: 485px;
      }
      .circle-1{
        margin-top: 6px;
      }.circle-1-vn{
        margin-top: 6px;
      }.circle-2-vn{
        margin-top: 241px;
      }
      .circle-2{
        margin-top: 275px;
      }
      .circle-3{
        margin-top: 475px;
      }.circle-3-vn{
        margin-top: 453px;
      }
      .history-text{
        height: 639px;
        font-size: 15px;
        line-height: 120%;
        padding-top: 22px;
        .ul-two-2{
          margin-top: 275px;
        } .ul-2-VN{
          margin-top: 260px;
        }
        .ul-3-VN{
          margin-top: 480px !important;
        }
        .ul-two-3{
          margin-top: 497px
        }
      }
    }
  }
  
}
@media screen and (max-width:320px) {
  .history-tow-page{
    .history-content-item{
      height: 639px;
    }
    .history-line{
      .box-two-2{
        padding-top: 289px;
      }.box-two-2-vn{
        padding-top: 271px;
      }
      .box-two-3{
        margin-top: 505px;
      }.box-two-3-vn{
        margin-top: 485px;
      }
      .circle-1{
        margin-top: 6px;
      }.circle-1-vn{
        margin-top: 6px;
      }.circle-2-vn{
        margin-top: 241px;
      }
      .circle-2{
        margin-top: 275px;
      }
      .circle-3{
        margin-top: 475px;
      }.circle-3-vn{
        margin-top: 453px;
      }
      .history-text{
        height: 639px;
        font-size: 15px;
        line-height: 120%;
        padding-top: 22px;
        .ul-two-2{
          margin-top: 275px;
        } .ul-2-VN{
          margin-top: 260px;
        }
        .ul-3-VN{
          margin-top: 480px !important;
        }
        .ul-two-3{
          margin-top: 497px
        }
      }
    }
  }
  
}
@media screen and (max-width:297px) {
  .history-tow-page{
    .history-content-item{
      height: 737px;
    }
    .history-line{
      .box-two-2{
        padding-top:327px;
      }.box-two-2-vn{
        padding-top: 313px;
      }
      .box-two-3{
        margin-top: 570px;
      }.box-two-3-vn{
        margin-top: 544px;
      }
      .circle-1{
        margin-top: 8px;
      }.circle-1-vn{
        margin-top: 6px;
      }.circle-2-vn{
        margin-top: 285px;
      }
      .circle-2{
        margin-top: 304px;
      }
      .circle-3{
        margin-top: 548px;
      }.circle-3-vn{
        margin-top: 516px;
      }
      .history-text{
        height: 737px;
        font-size: 15px;
        line-height: 120%;
        padding-top: 22px;
        .ul-two-2{
          margin-top: 318px;
        } .ul-2-VN{
          margin-top: 294px;
        }
        .ul-3-VN{
          margin-top: 518px !important ;
        }
        .ul-two-3{
          margin-top: 563px !important;
        }
      }
    }
  }
}