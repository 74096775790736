.technology-product {
  background: #1eb2ff;
  .contact-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .technology-product-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .technology-product-contain {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
  @media (-webkit-min-device-pixel-ratio: 1.01) and (-webkit-max-device-pixel-ratio: 1.19) {
    .technology-product-contain {
      padding-top: 80px !important;
    }
  }

  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .technology-product-contain {
      padding-top: 50px;
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .technology-product-contain {
      padding-top: 20px;
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .technology-product-contain {
      -moz-transform: scale(0.8);
      zoom: 80%;
      padding-top: 70px !important;
    }
  }
  @media (-webkit-device-pixel-ratio: 0.8) {
    .technology-product-contain {
      padding-top: 100px;
  }

}
  @media (-webkit-device-pixel-ratio: 0.9) {
    .technology-product-contain {
      padding-top: 100px;
    zoom: 85%;
    -moz-transform: scale(0.85);
  }

}
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .technology-product-contain {
      zoom: 80%;
      padding-top: 100px;
      -moz-transform: scale(0.8);
    }
  
}
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  .content {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 48px;
    padding-bottom: 83px;
  }
  .col-left {
    padding-left: 0px;
    padding-right: 12px;
  }
  .col-right {
    padding-right: 0px;
    padding-left: 12px;
  }
  .image {
    width: 100%;
    height: 384px;
  }
  .col-image-1 {
    padding-left: 0px;
    padding-right: 6px;
  }
  .col-image-2 {
    padding-left: 6px;
    padding-right: 0px;
  }
  .box {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-box-1 {
    padding-left: 0px;
    padding-right: 6px;
  }
  .col-box-2 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .col-box-3 {
    padding-left: 6px;
    padding-right: 0px;
  }
  .box-content {
    padding-bottom: 12px;
  }
  .box-text-2 {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .technology-product {
    margin-top: -9em !important;
    .content {
      padding: unset;
      .content-product {
        flex-direction: column-reverse;
        .col-right {
          padding-left: 98px !important;
          padding-right: 98px !important;
        }
        .col-left {
          padding-left: 98px !important;
          padding-right: 98px !important;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
  .technology-product {
    margin-top: -11em;
    position: relative;
    .technology-product-backgroud {
      background-image: none;
      height: auto;
      align-items: unset;
      .technology-product-contain {
        .title {
          padding-bottom: 60px;
        }
        .content {
          padding: unset;
          .content-product {
            .image {
              height: auto;
            }

            flex-direction: column-reverse;
            padding-bottom: 25px;
            .col-right {
              max-width: unset;
              padding-left: 24px;
              padding-right: 24px;
              padding-bottom: 24px;
            }
            .col-left {
              max-width: unset;
              padding-bottom: 40px;
              padding-left: 24px;
              padding-right: 24px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .technology-product {
    position: relative;
    margin-top: -11em;
    .technology-product-backgroud {
      background-image: none;
      height: auto;
      align-items: unset;
      .technology-product-contain {
        .title {
          padding-left: 28px;
          padding-right: 28px;
          font-size: 38px;
          padding-bottom: 40px;
        }
        .content {
          padding: unset;
          .content-product {
            flex-direction: column;
            .col-left {
              .image {
                height: auto;
              }
            }
            .col-right {
              padding-left: 16px;
              padding-right: 16px;
              padding-bottom: 60px;

              .col-right-content {
                flex-wrap: wrap;
                flex-direction: row;
                padding-bottom: 2px;
                .col-box-2 {
                  padding-right: unset;
                  padding-left: 4px;
                }
              }
              .right-content-box {
                padding-right: 4px;
              }
              .col-xl-4 {
                width: 50%;
              }
              .col-right-content {
                .col-box-content-3 {
                  padding: unset;
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  .colbox-content {
                    padding-right: 4px;
                    width: 50%;
                  }
                  .colbox-content-2 {
                    padding-left: 4px;
                  }
                }
              }
            }
            .col-left {
              padding-left: 16px;
              padding-right: 16px;
              padding-bottom: 24px;
              .col-image-1 {
                width: 50%;
                padding-right: 3px;
              }
              .col-image-2 {
                width: 50%;
                padding-left: 3px;
              }
            }
          }
        }
      }
    }
  }
}
