html,
body {
  color: #444;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
}

.active-scroll-spy {
  color: #1eb2ff !important;
  font-weight: 500;
}

.padding-0 {
  padding: 0px;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-top-8 {
  padding-top: 8px;
}
.padding-top-12 {
  padding-top: 12px;
}
.padding-top-13 {
  padding-top: 13px;
}
.padding-top-16 {
  padding-top: 16px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-32 {
  padding-top: 32px;
}
.padding-top-40 {
  padding-top: 40px;
}
.padding-top-48 {
  padding-top: 48px;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-56 {
  padding-top: 56px;
}
.padding-top-64 {
  padding-top: 64px;
}
.padding-top-74 {
  padding-top: 74px;
}
.padding-top-78 {
  padding-top: 78px;
}
.padding-top-120 {
  padding-top: 120px;
}
.padding-top-134 {
  padding-top: 134px;
}
.padding-top-143 {
  padding-top: 143px;
}
.padding-top-100 {
  padding-top: 100px;
}
.padding-top-200 {
  padding-top: 200px;
}
.padding-top-281 {
  padding-top: 281px;
}

// padding-right
.padding-right-0 {
  padding-right: 0px;
}
.padding-right-16 {
  padding-right: 16px;
}
.padding-right-20 {
  padding-right: 20px;
}
.padding-right-51 {
  padding-right: 51px;
}
.padding-right-60 {
  padding-right: 60px;
}

// padding-bottom
.padding-bottom-8 {
  padding-bottom: 8px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-12 {
  padding-bottom: 12px;
}
.padding-bottom-16 {
  padding-bottom: 16px;
}
.padding-bottom-30 {
  padding-bottom: 30px;
}
.padding-bottom-37 {
  padding-bottom: 37px;
}
.padding-bottom-59 {
  padding-bottom: 59px;
}

// padding-left
.padding-left-0 {
  padding-left: 0px;
}
.padding-left-4 {
  padding-left: 4px;
}
.padding-left-16 {
  padding-left: 16px;
}
.padding-left-17 {
  padding-left: 17px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-27 {
  padding-left: 27px;
}
.padding-left-50 {
  padding-left: 50px;
}
.padding-left-52 {
  padding-left: 52px;
}
.padding-left-55 {
  padding-left: 55px;
}
.padding-left-64 {
  padding-left: 64px;
}

// margin-left
.margin-left-70 {
  margin-left: -70px;
}
.margin-left-34 {
  margin-left: 34px;
}
.margin-left-42 {
  margin-left: 42px;
}
.margin-left-48 {
  margin-left: 48px;
}
.margin-left-49 {
  margin-left: 49px;
}
.margin-left-50 {
  margin-left: 50px;
}
.margin-left-57 {
  margin-left: 57px;
}
.margin-left--5 {
  margin-left: -5px;
}

.margin-right-5 {
  margin-right: 5px;
}

.word-break {
  word-break: break-word;
}
.text-decoration:hover {
  text-decoration: none;
}

.container {
  max-width: 1366px;
}

// @media (min-width: 1200px) {
//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl {
//     max-width: 1366px;
//     padding-left: 98px;
//     padding-right: 98px;
//   }
// }

// @media (max-width: 768px) {
//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl {
//     max-width: 768px;
//     padding-left: 16px;
//     padding-right: 16px;
//   }
// }

// @media (max-width: 768px) {
//   .container-media,
//   .container-media-lg,
//   .container-media-md,
//   .container-media-sm,
//   .container-media-xl {
//     max-width: 768px;
//     padding-left: 16px;
//   }
// }

// @media (min-width: 1400px) {
//   .contain-banner .container {
//     width: 1366px;
//   }
// }
// @media (min-width: 1366px) {
//   .contain-banner .container {
//     width: 100%;
//   }
// }
