.solutions-two {
  background: #1eb2ff;
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .solutions-content {
      zoom: 90%;
      width: 90%;
      margin: 0 auto;
      -moz-transform: scale(0.9);
    }
  }
  .solutions-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .solutions-contain {
    width: 100%;
  }
  .solutions-content-col {
    padding: 0px;
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .solutions-contain {
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }
  .solutions-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    padding-bottom: 35px;
  }
  .solutions-content {
    padding-left: 60px;
    padding-right: 60px;
  }
  .finance-insurance-col {
    padding-left: 0px;
    padding-right: 0px;
  }
  .finance-insurance {
    background: #ffffff;
    padding-top: 35px;
    padding-left: 32px;
    // padding-bottom: 38px;
    height: 438px;
    width: 326px;
  }
  .box-title-finance {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #222222;
    padding-left: 14px;
    padding-top: 5px;
  }
  .box-icon {
    width: 34px;
    height: 34px;
  }
  .finance-insurance-text {
    padding-top: 20px;
    text-align: left;
  }
  li::marker {
    color: #1eb2ff;
  }
  .finance-insurance-text > ul {
    line-height: 190%;
    padding-left: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
  }
  .healthcare-col {
    padding-left: 44px;
    padding-right: 0px;
  }
  .healthcare {
    background: #ffffff;
    padding-top: 35px;
    padding-left: 32px;
    // padding-bottom: 101px;
    height: 438px;
    width: 326px;
  }
  .unactive {
    display: none;
  }
  .solutions-image-col {
    padding-left: 90px;
  }
  .image-5 {
    width: 100%;
    height: 211px;
  }
  .image-4 {
    width: 100%;
    height: 211px;
  }
  .solutions-image-5 {
    padding-bottom: 8px;
  }
  .solutions-image-4 {
    padding-top: 8px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .solutions-two {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 98px;
        padding-right: 98px;
        .solutions-title {
          display: none;
        }
        display: none;
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 0px 0px 0px 0px;
          margin: 0 auto;
          align-items: center;
          .solutions-content-col {
            flex-direction: column;
            max-width: 100% !important;
            padding-bottom: 24px;
            .col-xl-6 {
              -ms-flex: 0 0 50%;
              flex: 0 0 50%;
              max-width: 100%;
            }
            .finance-insurance-col {
              padding-bottom: 8px;

              .finance-insurance {
                width: 100%;
                height: auto;
                .finance-insurance-text {
                  ul {
                    flex-direction: column;
                    flex-wrap: wrap;
                    height: 149px;
                    display: flex;
                  }
                  ul li {
                    width: 32%;
                    padding-right: 15px;
                  }
                }
              }
            }
            .healthcare-col {
              padding-left: 0px;
              padding-right: 0px;
              .healthcare {
                width: 100%;
                height: auto;
                padding-bottom: 1em;
                .finance-insurance-text {
                  ul {
                    display: flex;
                    flex-flow: wrap;
                  }
                  ul li {
                    width: 50%;
                    padding-right: 15px;
                  }
                  .unactive {
                    display: none;
                  }
                }
              }
            }
          }
          .solutions-image-col {
            padding-left: 0px;
            padding-right: 0px;
            max-width: 100% !important;

            .solutions-image-5 {
              padding-bottom: 18px;

              img {
                width: 100%;
                height: auto;
              }
            }
            .solutions-image-4 {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .solutions-two {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 24px;
        padding-right: 20px;
        .solutions-title {
          display: none;
        }
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 0px 0px 0px 0px;
          margin: 0 auto;
          align-items: center;
          .solutions-content-col {
            flex-direction: column;
            max-width: 100% !important;
            padding-bottom: 24px;
            .col-xl-6 {
              -ms-flex: 0 0 50%;
              flex: 0 0 50%;
              max-width: 100%;
            }
            .finance-insurance-col {
              padding-bottom: 8px;

              .finance-insurance {
                width: 100%;
                height: auto;
                .finance-insurance-text {
                  ul {
                    flex-direction: column;
                    flex-wrap: wrap;
                    height: 149px;
                    display: flex;
                  }
                  ul li {
                    width: 32%;
                    padding-right: 15px;
                  }
                }
              }
            }
            .healthcare-col {
              padding-left: 0px;
              padding-right: 0px;
              .healthcare {
                width: 100%;
                height: auto;
                padding-bottom: 1em;
                .finance-insurance-text {
                  ul {
                    display: flex;
                    flex-flow: wrap;
                  }
                  ul li {
                    width: 47%;
                    padding-right: 15px;
                  }
                  .unactive {
                    display: none;
                  }
                }
              }
            }
          }
          .solutions-image-col {
            padding-left: 0px;
            padding-right: 0px;
            max-width: 100% !important;

            .solutions-image-5 {
              padding-bottom: 18px;

              img {
                width: 100%;
                height: auto;
              }
            }
            .solutions-image-4 {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .solutions-two {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -60px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 17px;
        padding-right: 15px;
        .solutions-title {
          display: none;
        }
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 0px 0px 0px 0px;
          margin: 0 auto;
          align-items: center;
          .solutions-content-col {
            flex-direction: column;
            max-width: 100% !important;
            padding-bottom: 24px;
            .col-xl-6 {
              -ms-flex: 0 0 50%;
              flex: 0 0 50%;
              max-width: 100%;
            }
            .finance-insurance-col {
              padding-bottom: 24px;

              .finance-insurance {
                width: 100%;
                height: auto;
                padding: 30px 5px 20px 32px;
              }
            }
            .healthcare-col {
              padding-left: 0px;
              padding-right: 0px;
              .healthcare {
                width: 100%;
                height: auto;
                padding: 30px 5px 20px 32px;
              }
            }
          }
          .solutions-image-col {
            padding-left: 0px;
            padding-right: 0px;
            max-width: 100% !important;

            .solutions-image-5 {
              padding-bottom: 18px;

              img {
                width: 100%;
                height: auto;
              }
            }
            .solutions-image-4 {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
