.tma-innovation-vn {
  .tma-innovation-vn-contain {
    padding-left: 60px;
    position: relative;
  }
  @media (-webkit-device-pixel-ratio: 0.9) {
    .tma-innovation-vn-contain {
      .content-box {
        padding-top: 50px;
        zoom: 90%;
      }
      .title {
        zoom: 90%;
      }
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-device-pixel-ratio: 1) {
    .tma-innovation-vn-contain {
      .content-box {
        padding-top: 50px;
        zoom: 90%;
      }
      .title {
        zoom: 90%;
      }
    }
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .tma-innovation-vn-contain {
      .content-box {
        padding-top: 50px;
        zoom: 90%;
      }
      .title {
        zoom: 90%;
      }
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .tma-innovation-vn-contain {
      .content-box {
        zoom: 90%;
        padding-top: 20px;
        -moz-transform: scale(0.9);
      }
      .title {
        -moz-transform: scale(0.8);
        zoom: 80%;
      }
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .tma-innovation-vn-contain {
      .content-box {
        -moz-transform: scale(0.8);
        zoom: 80%;
      }
      .title {
        -moz-transform: scale(0.8);
        padding-left: 111px;
        zoom: 80%;
        width: 753px;
      }
    }
  }
  .backgroud-right {
    background: #1eb2ff;
    height: calc(100vh - 102px);
    justify-content: flex-end;
    padding-right: 75px;
    display: flex;
  }
  .col-right {
    padding-right: 0px;
    padding-left: 50px;
  }
  .col-left {
    padding-left: 0px;
    .title {
      padding-top: 42px;
      display: grid;
      justify-content: flex-end;
    }
  }
  .title-top {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-transform: capitalize;
    color: #222222;
    padding-top: 48px;
  }
  .title-bottom {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #222222;
    padding-top: 8px;
  }
  .backgroud-right {
    position: relative;
  }
  .backroud-frame {
    position: absolute;
    right: 0px;
    img {
      width: 222px;
      height: 370px;
    }
  }
  .content-box {
    position: absolute;
    top: 29%;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    justify-content: center;
    display: flex;
  }
  .box-1 {
    padding-right: 24px;
    img {
      width: 399px;
    }
  }
  .box-2 {
    padding-right: 24px;
    img {
      width: 399px;
    }
  }
  .box-3 {
    img {
      width: 399px;
    }
  }
  .box-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-transform: capitalize;
    color: #222222;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    height: 103px;
    width: 399px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tma-innovation-vn-backgroud {
    position: fixed;
    left: 0px;
    top: 32px;
    .backgroud-image {
      height: 100vh;
    }
  }
}

@media screen and (max-width: 1920px) {
  .tma-innovation-vn {
    .col-left {
      padding-left: 0px;
      .title {
        padding-top: 75px;
        display: grid;
        justify-content: flex-end;
      }
    }
    .content-box {
      position: absolute;
      top: 33%;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      justify-content: center;
      display: flex;
    }
  }
}

@media screen and (max-width: 1400px) {
  .tma-innovation-vn {
    .col-left {
      padding-left: 0px;
      .title {
        padding-top: 40px;
        display: grid;
        justify-content: flex-end;
      }
    }
    .content-box {
      position: absolute;
      top: 30%;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      justify-content: center;
      display: flex;
    }
  }
}

@media screen and (max-width: 1366px) {
  .tma-innovation-vn {
    .col-left {
      padding-left: 0px;
      .title {
        padding-top: 40px;
        display: grid;
        justify-content: flex-end;
      }
    }
    .content-box {
      position: absolute;
      top: 30%;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      justify-content: center;
      display: flex;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .tma-innovation-vn .backgroud-image {
    display: none;
  }
  .tma-innovation-vn {
    background-color: #fff;
    position: relative;
    top: -3em;
    .tma-innovation-vn-contain {
      padding-left: 0px;
      position: relative;
      .d-flex {
        flex-direction: column;
        .col-left {
          max-width: 100%;
          padding-left: 98px;
          padding-right: 98px;
          padding-bottom: 210px;

          .title {
            text-align: center;
            padding-top: 20px;
            display: block;
            justify-content: center;
          }
        }
        .col-right {
          max-width: 100%;
          padding-right: 0px;
          padding-left: 0px;
          max-width: 100%;
          height: auto;
          display: none;
          .backgroud-right {
            padding-left: 98px;
            padding-right: 98px;
            height: calc(77vh - 102px);
            .backroud-frame {
              display: none;
            }
          }
        }
      }
      .content-box {
        padding-left: 98px;
        padding-right: 98px;
        position: relative;
        background-color: #1eb2ff;
        .d-flex {
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          position: relative;
          top: -11em;
          .box-1 {
            padding-top: 24px;
            padding-right: 12px;
            width: 50%;
            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          .box-2 {
            padding-top: 24px;
            padding-left: 12px;
            padding-right: 0;
            width: 50%;
            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          .box-3 {
            padding-top: 24px;
            width: 50%;
            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tma-innovation-vn .backgroud-image {
    display: none;
  }
  .tma-innovation-vn {
    background-color: #fff;
    position: relative;
    top: -3em;
    .tma-innovation-vn-contain {
      padding-left: 0px;
      position: relative;
      .d-flex {
        flex-direction: column;
        .col-left {
          max-width: 100%;
          padding-left: 24px;
          padding-right: 24px;
          padding-bottom: 210px;
          .title {
            text-align: center;
            padding-top: 20px;
            justify-content: center;
          }
        }
        .col-right {
          max-width: 100%;
          padding-right: 0px;
          padding-left: 0px;
          max-width: 100%;
          height: auto;
          display: none;
          .backgroud-right {
            padding-left: 24px;
            padding-right: 24px;
            height: calc(77vh - 102px);
            .backroud-frame {
              display: none;
            }
          }
        }
      }
      .content-box {
        top: 18%;
        padding-left: 24px;
        padding-right: 24px;
        position: relative;
        background-color: #1eb2ff;
        .d-flex {
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          position: relative;
          top: -11em;
          padding-bottom: 40px;
          .box-1 {
            padding-top: 24px;
            padding-right: 12px;
            width: 50%;
            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          .box-2 {
            padding-top: 24px;
            padding-left: 12px;
            padding-right: 0;
            width: 50%;
            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          .box-3 {
            padding-top: 24px;
            width: 50%;
            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .tma-innovation-vn .backgroud-image {
    display: none;
  }
  .tma-innovation-vn {
    background-color: #fff;
    position: relative;
    top: -6em;
    .tma-innovation-vn-contain {
      padding-left: 0px;
      position: relative;
      .d-flex {
        flex-direction: column;
        .col-left {
          max-width: 100%;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 210px;

          .title {
            text-align: center;
            padding-top: 12px;
            justify-content: center;

            .title-top {
              font-family: "IBM Plex Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 38px;
              line-height: 120%;
              text-align: center;
              text-transform: capitalize;
            }
            .title-bottom {
              font-family: "IBM Plex Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 150%;
              text-align: center;
            }
          }
        }
        .col-right {
          max-width: 100%;
          padding-right: 0px;
          padding-left: 0px;
          max-width: 100%;
          height: auto;
          display: none;

          .backgroud-right {
            .backroud-frame {
              display: none;
            }
          }
        }
      }
      .content-box {
        padding-left: 16px;
        padding-right: 16px;
        background-color: #1eb2ff;
        position: relative;
        .d-flex {
          flex-direction: column;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          position: relative;
          top: -11em;
          .box-1 {
            width: 100%;
            padding-right: 0;
            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
            }
          }
          .box-2 {
            padding-top: 24px;
            width: 100%;
            padding-right: 0;

            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
            }
          }
          .box-3 {
            padding-top: 24px;
            width: 100%;
            padding-right: 0;

            .box-text {
              width: 100%;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
