.members-three {
	background: #1eb2ff;
	.members-three-backgroud {
		height: calc(100vh - 102px);
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		background-image: url('../images/Solutions/backgroud-frame-one.webp');
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.content-title {
		font-weight: 700;
		font-size: 48px;
		line-height: 120%;
		text-align: center;
		text-transform: capitalize;
		color: #ffffff;
		padding-bottom: 56px;
	}
	.members-three-content {
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
		justify-content: center;
		display: flex;
	}
	@media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
		.members-three-contain {
			-moz-transform: scale(0.9);
			zoom: 90%;
		}
	}
	@media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
		.members-three-contain {
			padding-top: 50px;
			-moz-transform: scale(0.9);
			zoom: 80%;
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
		.members-three-contain {
			-moz-transform: scale(0.8);
			zoom: 80%;
		}
	}
	@media (-webkit-device-pixel-ratio: 0.9) {
		.members-three-contain {
			top: 90px;
			zoom: 80%;
			left: 30%;
			-moz-transform: scale(0.7);
		}
	}
	@media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
		.members-three-contain {
			top: 90px !important;
			zoom: 70%;
			left: 25%;
			-moz-transform: scale(0.7);
		}
	}
	.services-content {
		justify-content: center;
	}
	.services-box {
		width: 158px;
		height: 122px;
		background: #ffffff;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
		text-align: center;
	}
	.icon-arrow1 {
		position: absolute;
		top: 190px;
		left: 185px;
	}
	.icon-arrow2 {
		position: absolute;
		top: 190px;
		left: 185px;
	}
	.icon-arrow3 {
		position: absolute;
		top: 190px;
		left: 185px;
	}
	.icon-arrow4 {
		position: absolute;
		top: 190px;
		left: 185px;
	}
	.service-group {
		margin-left: 64px;
	}
	.service-group-box-1 {
		margin-left: 20px !important;
	}
	.services-box-text {
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		text-align: center;
		color: #222222;
	}
	.product-planning-title {
		padding-top: 8px;
		padding-right: 40px;
		padding-left: 40px;
	}
	.rd-poc-prototype-EN {
		padding-top: 8px;
		padding-right: 20px;
		padding-left: 20px;
	}
	.rd-poc-prototype-VN {
		padding-top: 18px;
		padding-right: 20px;
		padding-left: 20px;
	}
	.service-group {
		.icon-memberthree-1 {
			width: 42px;
			height: 42px;
		}

		.icon-memberthree-2 {
			width: 32px;
			height: 32px;
		}
	}
}
@media screen and (max-width: 1365px) {
	.members-three {
		margin-top: -5px;
		.content-title {
			padding-top: 80px;
			padding-bottom: 4px;
		}

		.members-three-backgroud {
			justify-content: unset;
			background-image: none;
			width: 100%;
			height: auto;
			align-items: unset;

			.services-content {
				flex-direction: column;
				padding-bottom: 15px;

				.service-group {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					margin-left: unset !important;
					padding-top: 56px;
					flex: unset;
					max-width: unset;
					.padding-top-12 {
						padding-top: unset;
						padding-left: 12px;
					}
					.item-server-box {
						padding-top: 12px;
					}

					.service-group-3-top {
						display: flex;
						flex-direction: row;
					}
				}
				.group-item-three {
					display: flex;
					flex-direction: column;
				}
				.icon-three-arow {
					transform: rotate(90deg);
					margin-top: 2px;
					margin-left: -36px;
				}
				.icon-arrow4 {
					margin-top: 138px;
				}
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.members-three {
		.members-three-backgroud {
			.content-title {
				font-size: 38px;
			}
		}
	}
}
@media screen and (max-width: 340px) {
	.members-three {
		.members-three-backgroud {
			.members-three-contain {
				.members-three-content {
					.services-content {
						width: 100%;
						height: auto;
						.padding-right-0 {
							width: 100%;
							.services-box {
								width: 50%;
							}
							.padding-top-12 {
								width: 50%;
								.services-box {
									width: 100%;
								}
							}
							.icon-three-arow {
								left: unset;
								margin-left: 10px;
							}
						}
					}
					.group-item-three {
						width: 100%;
						.service-group-3-top {
							width: 100%;
							.services-box {
								width: 100%;
							}
							.padding-top-12 {
								width: 100%;
								.services-box {
									width: 100%;
								}
							}
						}
						.item-server-box {
							width: 60%;
						}
					}
					.services-box {
						.services-box-text {
							padding-left: unset;
							padding-right: unset;
						}
					}
				}
			}
		}
	}
}
