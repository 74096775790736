.large-talent-pool {
	.large-talent-pool-backgroud {
		height: calc(100vh - 102px);
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.large-talent-pool-contain {
		padding-left: 0px;
		padding-right: 0px;
		width: 100%;
	}
	.title {
		font-weight: 700;
		font-size: 48px;
		line-height: 120%;
		text-align: center;
		text-transform: capitalize;
		color: #222222;
	}
	.image-backgroud {
		height: 100vh;
	}
	.content {
		padding-left: 130px;
		padding-right: 130px;
		padding-top: 25px;
		padding-bottom: 25px;
		text-align: center;
	}
	.large-talent-pool-backgroud-image {
		position: absolute;
		right: 0px;
	}
	.content-image-mobile {
		display: none;
	}
	.content-image-desktop {
		display: block;
		
	}

	.content-image {
		display: none !important;
		display: flex;
		flex-direction: row;
		.box-left {
			display: flex;
			flex-direction: column;
			width: 100%;
			.box-content {
				display: flex;
				max-width: 100%;
				flex: 0 0 25%;
				padding-left: 0;
				.content-icon {
					height: 48px;
					width: 48px;
					background: #ffff;
					display: flex;
					border-radius: 10vh;
					align-items: center;
					justify-content: center;
					position: absolute;
					left: 15px;
				}
				.box {
					background: #1eb2ff;
					border: 1px solid #d2d2d2;
					width: 100%;
					height: 101px;
					display: flex;
					align-items: center;
					font-weight: 600;
					font-size: 20px;
					line-height: 120%;
					color: #ffff;
					padding-left: 70px;
					position: relative;
					.box-title {
						width: 248px;
						padding-top: 14px;
					}
					.box-title span {
						font-family: 'IBM Plex Sans';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 140%;
						text-align: center;
						color: rgba(255, 255, 255, 0.8);
					}
				}
			}
		}
		.box-right {
			display: flex;
			flex-wrap: wrap;
			.box-content {
				padding-bottom: 12px;
				padding-right: 12px;
				display: flex;
				justify-content: center;

				.box {
					background: #ffffff;
					width: 100%;
					height: auto;
					display: flex;
					align-items: center;
					font-weight: 600;
					font-size: 20px;
					line-height: 120%;
					color: #222222;
					position: relative;
					border: 1px solid #d2d2d2;
					.box-title {
						width: 100%;
						padding-top: 14px;
						text-align: center;
						// margin-right: 40px;
					}
					.box-title p {
						font-family: 'IBM Plex Sans';
						font-style: normal;
						font-weight: 700;
						font-size: 22px;
						line-height: 120%;
						text-align: center;
						color: #222222;
					}
					.box-title p .box-icon {
						margin-top: 4px;
						padding-right: 12px;
					}
					.box-title span {
						font-family: 'IBM Plex Sans';
						font-style: normal;
						font-weight: 400;
						font-size: 15px;
						line-height: 120%;
						text-align: center;
						color: #222222;
					}
				}
			}
		}
		.box-right div:nth-child(1) {
			max-width: 100%;
			flex: 0 0 100%;
			padding-left: 0px;
			justify-content: center;
			.box img {
				position: absolute;
				top: 0em;
				right: 49vh;
			}
		}
		.box-right div:nth-child(2) {
			max-width: 70%;
			flex: 0 0 70%;
			padding-left: 0px;

			.box-title {
				padding-left: 20px;
			}
			.box img {
				position: absolute;
				top: 4vh;
				right: 47vh;
			}
		}
		.box-right div:nth-child(3) {
			max-width: 35%;
			flex: 0 0 35%;
			padding-left: 0px;

			.box {
				justify-content: center;
				align-items: center;
				flex-direction: column;
			}
		}
		.box-right div:nth-child(4) {
			max-width: 35%;
			flex: 0 0 35%;
			padding-left: 0px;

			.box {
				justify-content: center;
				align-items: center;
				flex-direction: column;
			}
		}
		.box-right div:nth-child(5) {
			max-width: 30%;
			flex: 0 0 30%;
			padding-left: 0px;

			.box {
				justify-content: center;
				align-items: center;
				flex-direction: column;
				height: fit-content;
			}
		}
		.box-right div:nth-child(6) {
			max-width: 100%;
			flex: 0 0 100%;
			padding-left: 0px;
		}
	}
	@media (-webkit-device-pixel-ratio: 0.9) {
		.content-image-desktop {
			zoom: 90%;
			width: 90%;
			margin: 0 auto;
			display: flex !important;
			align-items: center;
			justify-content: center;
			-moz-transform: scale(0.9);
		}
		.title {
			zoom: 70%;
			-moz-transform: scale(0.7);
		}
		
	}
	@media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
		.content-image-desktop {
			zoom: 75%;
			width: 90%;
			margin: 0 auto;
			display: flex !important;
			align-items: center;
			justify-content: center;
			-moz-transform: scale(0.75);
			.image_content {
				width: 60vw;
			}
			
		}
		.title {
			zoom: 70%;
			-moz-transform: scale(0.7);
		}
	}
	@media screen and (max-height: 768px) {
		.content-image-desktop {
			.image_content {
				width: 60vw;
			}
		}
		.title {
			
			font-size: 3vw;
			
		}
	}
	@media screen and (max-height: 650px) {
		.content-image-desktop {
			.image_content {
				width: 50vw;
			}
		}
		.title {
			
			font-size: 2vw;
			
		}
	}
	@media screen and (max-height: 560px) {
		.content-image-desktop {
			.image_content {
				width: 40vw;
			}
		}
	}
	@media screen and (max-height: 560px) {
		.content-image-desktop {
			.image_content {
				width: 30vw;
			}
			
		}
		.title {
			
			font-size: 1vw;
			
		}
	}
	@media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
		.large-talent-pool-contain {
			padding-top: 50px;
			zoom: 90%;
			-moz-transform: scale(0.9);
		}
	}
	@media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
		.large-talent-pool-contain {
			padding-top: 60px;
			zoom: 90%;
			-moz-transform: scale(0.9);
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
		.large-talent-pool-contain {
			padding-top: 50px;
			-moz-transform: scale(0.8);
			zoom: 80%;
		}
	}

}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
	.large-talent-pool {
		.large-talent-pool-backgroud-image {
			display: none;
		}
		.large-talent-pool-backgroud {
			width: 100%;
			height: auto;
			background-color: #f3f8fb;

			.large-talent-pool-contain {
				.title {
					padding-top: 60px;
				}
				.content {
					.content-image {
						display: none;
					}
					.content-image-desktop {
						display: none !important;
					}
					.content-image-mobile {
						display: block;
						.box-left {
							display: flex;
							flex-wrap: wrap;
							.box-content {
								padding-bottom: 12px;
								padding-right: 12px;
								display: flex;
								justify-content: center;
								.content-icon {
									height: 48px;
									width: 48px;
									background: #ffff;
									display: flex;
									border-radius: 10vh;
									align-items: center;
									justify-content: center;
									position: absolute;
									left: 15px;
									.box-icon {
										width: 24px;
										height: 24px;
									}
								}
								.box {
									background: #1eb2ff;
									border: 1px solid #d2d2d2;
									width: 100%;
									height: 101px;
									display: flex;
									align-items: center;
									font-weight: 600;
									font-size: 20px;
									line-height: 120%;
									color: #ffff;
									padding-left: 70px;
									position: relative;
									.box-title {
										width: 248px;
										padding-top: 14px;
									}
									.box-title span {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 400;
										font-size: 18px;
										line-height: 140%;
										text-align: center;
										color: rgba(255, 255, 255, 0.8);
									}
								}
							}
						}
						.box-right {
							display: flex;
							flex-wrap: wrap;
							.box-content {
								padding-bottom: 12px;
								padding-right: 12px;
								display: flex;
								justify-content: center;

								.box {
									background: #ffffff;
									width: 100%;
									height: 147px;
									display: flex;
									align-items: center;
									font-weight: 600;
									font-size: 20px;
									line-height: 120%;
									color: #222222;
									position: relative;
									border: 1px solid #d2d2d2;
									.box-title {
										width: 100%;
										padding-top: 14px;
										text-align: center;
										// margin-right: 40px;
									}
									.box-title p {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 700;
										font-size: 22px;
										line-height: 120%;
										text-align: center;
										color: #222222;
									}
									.box-title p .box-icon {
										margin-top: -15px;
										padding-right: 12px;
									}
									.box-title .line-text {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 400;
										font-size: 15px;
										line-height: 120%;
										text-align: center;
										color: #222222;
										padding-left: 39px;
										padding-right: 51px;
										padding-top: 8px;
									}
									.box-title div:nth-child(1) {
										display: flex;
										justify-content: center;
										.line-title {
											position: relative;
											top: 1em;
											right: -15px;
											margin-bottom: 25px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.large-talent-pool .content {
		padding-left: 80px;
		padding-right: 80px;
		padding-bottom: 80px;
	}
	.large-talent-pool .title {
		padding-left: 98px;
		padding-right: 98px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.large-talent-pool {
		.large-talent-pool-backgroud-image {
			display: none;
		}
		.large-talent-pool-backgroud {
			width: 100%;
			height: auto;
			background-color: #f3f8fb;
			.large-talent-pool-contain {
				.title {
					padding-top: 60px;
				}
				.content {
					.content-image {
						display: none;
					}
					.content-image-desktop {
						display: none !important;
					}
					.content-image-mobile {
						display: block;
						.box-left {
							display: flex;
							flex-wrap: wrap;
							.box-content {
								padding-bottom: 12px;
								padding-right: 12px;
								display: flex;
								justify-content: center;
								.content-icon {
									height: 48px;
									width: 48px;
									background: #ffff;
									display: flex;
									border-radius: 10vh;
									align-items: center;
									justify-content: center;
									position: absolute;
									left: 15px;
									.box-icon {
										width: 24px;
										height: 24px;
									}
								}
								.box {
									background: #1eb2ff;
									border: 1px solid #d2d2d2;
									width: 100%;
									height: 101px;
									display: flex;
									align-items: center;
									font-weight: 600;
									font-size: 20px;
									line-height: 120%;
									color: #ffff;
									padding-left: 70px;
									position: relative;
									.box-title {
										width: 248px;
										padding-top: 14px;
									}
									.box-title span {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 400;
										font-size: 18px;
										line-height: 140%;
										text-align: center;
										color: rgba(255, 255, 255, 0.8);
									}
								}
							}
						}
						.box-right {
							display: flex;
							flex-wrap: wrap;
							.box-content {
								padding-bottom: 12px;
								padding-right: 12px;
								display: flex;
								justify-content: center;

								.box {
									background: #ffffff;
									width: 100%;
									height: 147px;
									display: flex;
									align-items: center;
									font-weight: 600;
									font-size: 20px;
									line-height: 120%;
									color: #222222;
									position: relative;
									border: 1px solid #d2d2d2;
									.box-title {
										width: 100%;
										padding-top: 14px;
										text-align: center;
										// margin-right: 40px;
									}
									.box-title div:nth-child(1) {
										display: flex;
										justify-content: center;
										align-items: flex-end;
										.line-title {
											position: relative;
											top: 1em;
											right: -15px;
											margin-bottom: 25px;
										}
									}
									.box-title p {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 700;
										font-size: 22px;
										line-height: 120%;
										text-align: center;
										color: #222222;
									}
									.box-title p .box-icon {
										margin-top: -15px;
										padding-right: 12px;
									}
									.box-title .line-text {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 400;
										font-size: 15px;
										line-height: 140%;
										text-align: center;
										color: #222222;
										padding-left: 30px;
										padding-right: 30px;
										padding-top: 8px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.large-talent-pool .content {
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 80px;
	}
	.large-talent-pool .title {
		padding-left: 24px;
		padding-right: 24px;
	}
}

@media screen and (max-width: 767px) {
	.large-talent-pool {
		.large-talent-pool-backgroud-image {
			display: none;
		}
		.large-talent-pool-backgroud {
			width: 100%;
			height: auto;
			background-color: #f3f8fb;

			.large-talent-pool-contain {
				.title {
					font-size: 38px;
					padding-left: 16px;
					padding-right: 16px;
					padding-top: 60px;
				}
				.content {
					.content-image {
						display: none;
					}
					.content-image-desktop {
						display: none !important;
					}
					.content-image-mobile {
						display: block;
						.box-left {
							display: flex;
							flex-wrap: wrap;
							flex-direction: column;
							.box-content {
								padding-bottom: 12px;
								padding-right: 0px;
								padding-left: 0px;
								display: flex;
								justify-content: center;
								.content-icon {
									height: 48px;
									width: 48px;
									background: #ffff;
									display: flex;
									border-radius: 10vh;
									align-items: center;
									justify-content: center;
									position: absolute;
									left: 16px;
									.box-icon {
										width: 24px;
										height: 24px;
									}
								}
								.box {
									background: #1eb2ff;
									border: 1px solid #d2d2d2;
									width: 100%;
									height: 101px;
									display: flex;
									align-items: center;
									font-weight: 600;
									font-size: 20px;
									line-height: 120%;
									color: #ffff;
									padding-left: 70px;
									.box-title {
										width: 248px;
										padding-top: 14px;
										padding-left: 6px;
									}
									.box-title span {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 400;
										font-size: 18px;
										line-height: 140%;
										text-align: center;
										color: rgba(255, 255, 255, 0.8);
									}
								}
							}
							.col-6 {
								max-width: 100%;
							}
						}
						.box-right {
							display: flex;
							flex-wrap: wrap;
							flex-direction: column;
							.box-content {
								padding-bottom: 12px;
								padding-right: 0px;
								padding-left: 0px;
								justify-content: center;
								display: flex;
								flex-direction: column;

								.box {
									background: #ffffff;
									width: 100%;
									height: 147px;
									display: flex;
									align-items: center;
									font-weight: 600;
									font-size: 20px;
									line-height: 120%;
									color: #222222;
									border: 1px solid #d2d2d2;
									.box-title {
										width: 100%;
										padding-top: 14px;
										text-align: center;
									}
									.box-title div:nth-child(1) {
										display: flex;
										justify-content: center;
										.line-title {
											position: relative;
											top: 1em;
											right: -6px;
										}
									}
									.box-title p {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 700;
										font-size: 22px;
										line-height: 120%;
										text-align: center;
										color: #222222;
									}
									.box-title p .box-icon {
										padding-right: 12px;
									}
									.box-title .line-text {
										font-family: 'IBM Plex Sans';
										font-style: normal;
										font-weight: 400;
										font-size: 15px;
										line-height: 120%;
										text-align: center;
										color: #222222;

										padding-top: 8px;
									}
								}
							}
							.col-6 {
								-ms-flex: 0 0 50%;
								flex: 0 0 50%;
								max-width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.large-talent-pool .content {
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 60px;
	}
	.large-talent-pool .title {
		padding-top: 3em;
		padding-left: 100px;
		padding-right: 100px;
	}
}
