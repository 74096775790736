.members-one {
  .content-left {
    background: #1eb2ff;
    height: calc(100vh - 102px);
    // padding-left: 60px;
    justify-content: flex-end;
    padding-right: 140px;
    display: flex;
  }
  .content-left-col {
    padding-left: 0px;
    padding-right: 5%;
    width: 50%;
  }
  .content-left-title {
    padding-left: 38px;
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    text-transform: capitalize;
    color: #ffffff;
    text-align: right;
    width: 500px;
    display: flex;
    align-items: center;
  }
  .content-left-title-VN {
    padding-left: 38px;
    padding-top: 12%;
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    text-transform: capitalize;
    color: #ffffff;
  }
  .content-left-backroud-frame {
    position: absolute;
    bottom: 0px;
    .backroud-frame-bottom {
      width: 222px;
      height: 370px;
    }
  }
  .box-top {
    display: flex;
  }
  .box-bottom {
    display: flex;
  }
  .content-box {
    position: absolute;
    top: 16%;
    margin: 0 auto;
    right: 40px;
    text-align: center;
    justify-content: center;
    display: flex;
  }
  .content-box-vn {
    position: absolute;
    top: 35%;
    left: 74px;
    margin: 0 auto;
    right: 40px;
    text-align: center;
    justify-content: center;
    display: flex;
    .box-contain {
      display: flex;
      flex-direction: row;
    }
  }
  .item-archi {
    text-decoration: none;
  }
  .item-box {
    // width: 396px;
    // height: 244px;
    height: 285px;
    width: 370px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
  }
  .box-content {
    padding-right: 30px;
    padding-bottom: 28px;
  }
  .item-box-logo {
    padding-top: 42px;
    .logomemberone-1 {
      width: 100px;
      height: 46px;
    }
  }
  .item-box-logo-BD {
    padding-top: 42px;
    .logomemberone-2 {
      width: 85px;
      height: 58px;
    }
  }
  .box-content-tma-inno {
    .item-box-logo {
      .logomemberone-3 {
        width: 100px;
        height: 43px;
      }
    }
  }
  .item-box-name {
    padding-top: 23px;
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    color: #222222;
  }
  .item-box-text {
    padding-top: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: #222222;
    padding-right: 16px;
    padding-left: 16px;
  }
  .item-box-text-VN-INNO {
    padding-top: 12px;
    font-weight: 400;
    font-size: 19.7px;
    line-height: 150%;
    text-align: center;
    color: #222222;
    padding-right: 15px;
    padding-left: 15px;
  }
  .members-one-backgroud-nav {
    position: fixed;
    right: 0px;
    top: 32px;
    display: none;

    .backgroud-image {
      height: 100vh;
    }
  }
  .members-one-contain {
    display: flex;
  }
  .box-contain {
    display: flex;
    flex-direction: column;
  }
  @media (-webkit-min-device-pixel-ratio: 1.01) and (-webkit-max-device-pixel-ratio: 1.19) {
    .content-box {
      top: 90px;
      -moz-transform: scale(0.9);
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .content-box {
      top: 40px;
      -moz-transform: scale(0.9);
      right: 200px !important;
      zoom: 90%;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .content-box {
      padding-right: 40px;
      top: 9%;
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }

  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .content-box {
      top: -5px !important;
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: 73px;
    }
  }
  @media (-webkit-device-pixel-ratio: 0.8) {
   
      .content-box {
        top: 90px;
        zoom: 90%;
        left: 30%;
        -moz-transform: scale(0.7);
      }
    
  }
  @media (-webkit-device-pixel-ratio: 0.9) {
        .content-box {
        top: 90px;
        zoom: 80%;
        left: 30%;
        -moz-transform: scale(0.8);
      }
    
  }
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
      .content-box {
        top: 90px !important;
        zoom: 70%;
        left: 25%;
        -moz-transform: scale(0.7);
      }
    
  }
  .a-href {
    text-decoration: none !important;
  }
  .a-href:hover {
    text-decoration: none !important;
  }
  .mobile-members {
    display: none;
  }
  .desktop-members {
    display: block;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1509px) {
  .members-one {
    .content-box {
      top: 11%;
    }
  }
}
@media screen and (min-width: 1850px) {
  .members-one {
    .content-box {
      right: 360px !important;
    }
    .content-box-vn {
      left: 320px !important;
    }
  }
}
@media screen and (min-width: 1770px) and (max-width: 1849px) {
  .members-one {
    .content-box {
      right: 270px !important;
    }
    .content-box-vn {
      left: 256px !important;
    }
  }
}
@media screen and (min-width: 1650px) and (max-width: 1769px) {
  .members-one {
    .content-box {
      right: 240px !important;
    }
    .content-box-vn {
      left: 236px !important;
    }
  }
}
@media screen and (min-width: 1600px) {
  .members-one {
    .content-box {
      right: 220px;
    }
    .content-box-vn {
      left: 148px;
    }
  }
}
@media screen and (min-width: 1441px) {
  .members-one {
    .content-box {
      right: 150px;
    }
    .content-left {
      padding-right: 200px;
    }
    .content-left-VN {
      padding-right: 160px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .members-one {
    .content-left {
      padding-right: 200px;
    }
    .content-left-VN {
      padding-right: 130px;
    }
  }
}

@media screen and (max-width: 1365px) {
  .members-one {
    .backgroud-image {
      display: none;
    }
    .content-left-backroud-frame {
      display: none;
    }
    .content-left {
      background: unset;
      display: block;
      margin-top: -50px;
      height: unset;
      padding-bottom: 548px !important;
    }
    .members-one-backgroud {
      background: #ffffff;
    }
    .members-one-contain {
      display: unset;
    }
    .content-left-col {
      padding-right: 0px;
    }
    .content-left {
      padding-right: 0px;
    }
    .content-left-title {
      padding-top: 80px;
      padding-bottom: 209px;
      padding-left: 0px;
      width: 100%;
      text-align: center;
      background: #1eb2ff;
    }
    .content-left-title-VN {
      padding-top: 60px;
      padding-bottom: 209px;
      padding-left: 0px;
      width: 100%;
      text-align: center;
      background: #1eb2ff;
    }
    .content-box {
      top: 250px;
      left: unset;
      right: unset;
    }
    .box-content-tma-inno {
      padding-right: 16px;
    }
    .item-box {
      width: 352px;
      height: 285px;
      background: #ffffff;
      box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    }
    .item-box-text {
      padding-left: 0px;
      padding-right: 0px;
    }
    .item-box-logo-BD {
      padding-top: 34px;
    }
    .item-box-text-bd {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .content-left {
    padding-bottom: 600px !important;
  }
  .box-contain {
    flex-wrap: wrap !important;
  }
  .box-content-tma-solution {
    flex: 50%;
    justify-content: flex-end;
    padding-right: 8px !important;
    display: flex;
  }
  .box-content-tma-bd {
    flex: 50%;
    padding-right: 0px !important;
    padding-left: 8px;
  }
  .box-content-tma-inno {
    flex: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
  .members-one {
    width: 100%;
    height: auto;
    margin-top: 2em;
    .item-box-name {
      font-size: 26px;
    }
    .mobile-members {
      display: block;
    }
    .desktop-members {
      display: none;
    }
    .members-one-backgroud {
      background: none;
      .content-left-col {
        width: 100%;
      }
      .content-left-title {
        width: 100%;
        height: auto;
        padding-top: 60px;
        padding-left: 134px;
        padding-right: 135px;
        display: flex;
        justify-content: center;
      }
      .content-left {
        padding: unset !important;
      }
      .box-contain {
        justify-content: center;
      }
      .content-box {
        top: unset;
        position: relative;
        .box-contain {
          margin-top: -145px;
          padding-bottom: 80px;
          text-decoration: none;
        }
        .box-content-tma-solution {
          padding-right: 16px !important ;
          flex: unset;
        }

        .box-content-tma-bd {
          padding-left: unset;
          flex: unset;
          padding-right: 16px;
          .item-box-text-bd {
            padding-left: 30px;
            padding-right: 31px;
          }
        }
        .item-box-archi {
          .item-box-name {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }
    }
    .item-box-text {
      padding-left: 49px;
      padding-right: 50px;
    }
    .item-box-text-1 {
      padding-left: 12px;
      padding-right: 11px;
    }
  }
  .box-content-tma-inno {
    flex: none;
    padding-top: unset;
  }
}

@media screen and (max-width: 767px) {
  .members-one {
    width: 100%;
    height: auto;
    margin-top: 45px;
    .mobile-members {
      display: block;
    }
    .desktop-members {
      display: none;
    }
    .members-one-backgroud {
      background: none;
    }
    .content-left-col {
      width: 100%;
    }
    .content-left-title {
      width: 100%;
      height: auto;
      padding-top: 20px;
      padding-left: 41px;
      padding-right: 38px;
      font-size: 38px;
      display: flex;
      justify-content: center;
    }
    .content-left-title-VN {
      width: 100%;
      height: auto;
      padding-left: 69px;
      padding-right: 70px;
      font-size: 38px;
      padding-top: 20px;
    }
    .content-box {
      margin-bottom: 128px;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      top: unset;
      margin-top: -44em;
      .box-content-tma-bd {
        padding-left: unset;
      }
      .box-contain {
        width: 100%;
        flex-direction: column;
        flex-wrap: unset !important;
        // .item-box-2 {
        //   // width: 100%;
        // }
        .item-box-text {
          padding-bottom: 50px;
        }
      }
      .box-content-tma-bd {
        padding-right: unset;
      }
      .box-content {
        margin-bottom: unset;
        padding-right: unset !important;
        padding-top: unset;
      }
      .box-top {
        flex-direction: column;
      }
      .box-bottom {
        flex-direction: column;
      }
    }
    .item-box {
      width: 100%;
      height: auto;
      // margin-left: 16px;
      // margin-right: 16px;

      .item-box-text {
        margin-left: 28px;
        margin-right: 37px;
      }
    }
    .a-href {
      text-decoration: none !important;
      width: 100%;
    }
    .item-box-text-VN-INNO {
      font-size: 19px;
    }
  }
}
@media screen and (max-width: 370px) {
  .members-one {
    .mobile-members {
      display: block;
    }
    .desktop-members {
      display: none;
    }
    .content-left {
      .content-left-title {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }
}
