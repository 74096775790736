.infographics {
    flex-wrap: wrap;
    background: #E9FAFD;
  
    @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
      .infographics-img {
        zoom: 70%;
      }
    }
    .infographics-backgroud {
      height: calc(100vh - 102px);
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;
      display: grid;
      align-items: center;
     
    }
    .infographics-text {
      font-size: 48px;
      line-height: 120%;
      text-transform: capitalize;
      color: #222222;
      font-weight: 700;
    }
    .infographics-content {
      max-width: 1920px;
      margin: auto;
    }
   
    .infographics-img {
      img {
        width: 100%;
      }
    }
  }

  .tma_25_years{
    width: 100%;
    min-height: 500px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .TMA-25-years-infographics{
      width: 100%;
      height: auto;
    }
    // .title{
    //   color: #1eb2ff;
    //   font-size: 32px;
    //   padding-top: 50px;
    //   padding-left: 40px;
    // }

    .cf-title-12 h3 {
      font-size:23px; 
      font-weight:700;  
      letter-spacing:1px; 
      text-transform:uppercase; 
      width: 390px;
      white-space:nowrap; 
      padding-bottom:13px;
      padding-top: 50px;
      position: relative;
      padding-left: 40px;
      color: #1eb2ff;
    }
    .cf-title-12 h3:before {
        background-color: #4fc2bb;
        content: '';
        display: block;
        height: 3px;
        width: 75px;
        margin-bottom: 5px;
    }
    .cf-title-12 h3:after {
        background-color: #4fc2bb;
        content: '';
        display: block;
        position:absolute; right:0; bottom:0;
        height: 3px;
        width: 75px;
        margin-bottom: 0.25em;
    }
  }
  @media screen and (max-width: 1366px) {
    .tma_25_years{
      width: 100%;
      min-height: 500px;
      .title{
       padding-top: 90px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .tma_25_years{
 
      .title{
       font-size: 18px;
      }
    }
  }