.App .backgroud-about-us {
  display: block;
  position: absolute;
  top: 32px;
}
.App .backgroud-none {
  display: none;
}
.App .backgroud-image-1 {
  height: calc(100vh - 32px);
}
.App .backgroud-members {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.active-scroll-spy {
  color: #0a0a0a !important;
  font-weight: 500;
}

@media screen and (max-width: 1365px) {
  .App .backgroud-about-us {
    display: none !important;
  }
  .App .backgroud-members {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .App .backgroud-about-us {
    display: none !important;
  }
  .App .backgroud-members {
    display: none !important;
  }
}
@media screen and (max-width: 320px) {
  .App .backgroud-about-us {
    display: none !important;
  }
  .App .backgroud-members {
    display: none !important;
  }
}
