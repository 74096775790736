.solutions-four {
  background: #1eb2ff;
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .solutions-content {
      zoom: 90%;
      width: 90%;
      margin: 0 auto;
      -moz-transform: scale(0.9);
    }
  }
  .solutions-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .solutions-contain {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .solutions-contain {
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }
  .solutions-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  .solutions-content {
    padding: 35px 60px;
  }
  .solutions-content-col {
    padding: 0px;
  }
  .box-text {
    // width: 613px;
    height: 210px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    // padding: 35px;
    padding: 35px 0px 70px 32px;
  }
  .box-text-mobile {
    display: none;
  }
  .box-text-mobile {
    // width: 613px;
    height: 210px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    // padding: 35px;
    padding: 35px 0px 70px 32px;
  }
  .box-title-telecom {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #222222;
    padding-left: 14px;
    padding-top: 5px;
  }

  .box-text-content {
    padding-top: 20px;
    // padding-bottom: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #222222;
  }
  .box-text-content-col {
    text-align: left;
  }
  li::marker {
    color: #1eb2ff;
  }
  .box-text-content-col > ul {
    line-height: 130%;
    padding-left: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
  }
  .box-text-content-col > ul > li {
    line-height: 180%;
  }
  .col-right {
    padding-left: 10px;
  }
  .col-left {
    padding-right: 10px;
  }
  .box-image {
    padding-bottom: 30px;
  }
  .box-image-mobile {
    padding-bottom: 24px;
  }
  .box-image-mobile {
    display: none !important;
  }
  .box-image-col-1 {
    padding-left: 0px;
  }
  .box-image-col-2 {
    padding-right: 0px;
  }
  .box-image-1 {
    width: 100%;
    height: 210px;
  }
  .box-image-2 {
    width: 100%;
    height: 210px;
  }
  .box-icon {
    width: 34px;
    height: 34px;
  }
  .box-image-right {
    padding-top: 30px;
  }
  .box-image-right-mobile {
    padding-top: 24px;
  }
  .box-image-right-mobile {
    display: none !important;
  }
  .box-title-commerce-right {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #222222;
    padding-left: 14px;
  }
  .box-image-col-3 {
    width: 100%;
  }
  .unactive {
    display: none;
  }
  .box-text-logistics-VN {
    width: 300px;
  }
  .box-text-logistics-rightVN {
    padding-right: 0px;
    padding-left: 38px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .solutions-four {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 98px;
        padding-right: 98px;
        padding-bottom: 40px;

        .solutions-title {
          display: none;
        }
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 0px 0px 0px 0px;
          margin: 0 auto;
          .solutions-content-col {
            flex-direction: column;
            max-width: 100% !important;
            padding-bottom: 24px;
            padding-right: 0;
          }
          .col-right {
            .box-text {
              display: none;
            }
            .box-text-mobile {
              margin-top: 24px;
              height: auto;
              padding: 35px 0px 1px 32px;
              display: block;
              .box-text-content {
                .box-text-content-col {
                  padding-right: 0px;
                  flex: unset;
                }
              }
            }
            .box-image-right {
              display: none !important;
            }
            .box-image-right-mobile {
              display: block !important;
            }
          }
          .col-left {
            .box-text {
              display: none;
            }
            .box-text-mobile {
              height: auto;
              padding: 35px 0px 1px 32px;
              display: block;
              .box-text-content {
                .box-text-content-col {
                  padding-right: 0px;
                  width: unset;
                  flex: unset;
                }
              }
            }
            .box-image {
              display: none !important;
            }
            .box-image-mobile {
              display: block !important;
            }
          }
        }

        .box-text-content {
          .box-text-content-col {
            ul {
              display: flex;
              flex-flow: wrap;
              width: auto;
              line-height: 130%;
            }
            ul li {
              width: 100%;
              padding-right: 20px;
              padding-bottom: 8px;
            }
            .unactive {
              display: none;
            }
          }
        }
      }
    }
  }
  .box-text-content-col > ul {
    line-height: 170%;
  }
  .solutions-four .box-image-col-1 {
    padding-left: 0;
    width: -webkit-fill-available;
  }
  .solutions-four .box-image-1 {
    height: auto;
  }
  .solutions-four .box-image-right {
    padding-top: 24px;
  }
  //swap solutions-content
  .solutions-content-col {
    display: contents;
  }
  div#group-1 {
    order: 2;
    margin-top: 24px;
  }
  div#group-2 {
    order: 3;
  }
  div#group-3 {
    order: 1;
  }
  div#group-4 {
    order: 4;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .solutions-four {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 24px;
        padding-right: 20px;
        padding-bottom: 40px;

        .solutions-title {
          display: none;
        }
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 0px 0px 0px 0px;
          margin: 0 auto;
          .solutions-content-col {
            flex-direction: column;
            max-width: 100% !important;
            padding-bottom: 24px;
            padding-right: 0;
          }
          .col-right {
            .box-text {
              display: none;
            }
            .box-text-mobile {
              margin-top: 24px;
              height: auto;
              padding: 35px 0px 1px 32px;
              display: block;
              .box-text-content {
                .box-text-content-col {
                  padding-right: 0px;
                }
              }
            }
            .box-image-right {
              display: none !important;
            }
            .box-image-right-mobile {
              display: block !important;
            }
          }
          .col-left {
            .box-text {
              display: none;
            }
            .box-text-mobile {
              height: auto;
              padding: 35px 0px 1px 32px;
              display: block;
              .box-text-content {
                .box-text-content-col {
                  padding-right: 0px;
                  width: unset;
                }
              }
            }
            .box-image {
              display: none !important;
            }
            .box-image-mobile {
              display: block !important;
            }
          }
        }

        .box-text-content {
          .box-text-content-col {
            ul {
              display: flex;
              flex-flow: wrap;
              width: auto;
              line-height: 130%;
            }
            ul li {
              width: 100%;
            }
            .unactive {
              display: none;
            }
          }
        }
      }
    }
    //swap solutions-content
    .solutions-content-col {
      display: contents;
    }
    div#group-1 {
      order: 2;
      margin-top: 24px;
    }
    div#group-2 {
      order: 3;
    }
    div#group-3 {
      order: 1;
    }
    div#group-4 {
      order: 4;
    }
  }
  .box-text-content-col > ul {
    line-height: 170%;
  }
  .solutions-four .box-image-col-1 {
    padding-left: 0;
    width: -webkit-fill-available;
  }
  .solutions-four .box-image-1 {
    height: auto;
  }
  .solutions-four .box-image-right {
    padding-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .solutions-four {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 17px;
        padding-right: 15px;
        margin-top: 0em;
        padding-bottom: 40px;

        .solutions-title {
          display: none;
        }
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 0px 0px 0px 0px;
          margin: 0 auto;
          .solutions-content-col {
            flex-direction: column;
            max-width: 100% !important;
            padding-bottom: 24px;
          }
          .col-right {
            .box-text {
              margin-top: 24px;
              height: auto;
              padding: 35px 0px 0px 32px;
              .box-text-content {
                flex-direction: column;
                .box-text-content-col:nth-child(2) {
                  position: relative;
                  bottom: 1em;
                }
              }
            }
            .box-image-right {
              display: none !important;
            }
            .box-image-right-mobile {
              display: block !important;
            }
          }
          .col-left {
            .box-text {
              height: auto;
              padding: 35px 0px 0px 32px;

              .box-text-content {
                flex-direction: column;
                .box-text-content-col:nth-child(2) {
                  position: relative;
                  bottom: 1em;
                }
              }
            }
            .box-image {
              display: none !important;
            }
            .box-image-mobile {
              display: block !important;
            }
          }
        }
      }
    }
    //swap solutions-content
    .solutions-content-col {
      display: contents;
    }

    div#group-1 {
      order: 2;
      margin-top: 24px;
    }
    div#group-2 {
      order: 3;
    }
    div#group-3 {
      order: 1;
    }
    div#group-4 {
      order: 4;
    }
  }
  .solutions-four .box-image {
    padding-bottom: 0px;
  }
  .solutions-four .box-text-logistics-VN {
    width: 100%;
  }
  .box-text-content-col > ul {
    line-height: 170%;
  }
  .solutions-four .box-text-logistics-rightVN {
    position: relative;
    right: 31px;
    width: 100%;
  }
  .solutions-four .box-image-col-1 {
    padding-left: 0;
    width: -webkit-fill-available;
  }
  .solutions-four .box-image-1 {
    height: auto;
  }
  .solutions-four .box-image-right {
    padding-top: 24px;
  }
  .solutions-four .box-title-telecom {
    text-align: left;
    padding-top: 0px;
    padding-right: 16px;
  }
}
