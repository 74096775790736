.strong-quality-foundation {
  background: #1eb2ff;

  .strong-quality-foundation-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-tablet-mobile {
    display: none;
  }
  .strong-quality-foundation-contain {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
  @media (-webkit-min-device-pixel-ratio: 1.01) and (-webkit-max-device-pixel-ratio: 1.19) {
    .strong-quality-foundation-contain {
      padding-top: 100px !important;
    }
  }

  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .strong-quality-foundation-contain {
      padding-top: 90px;
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .strong-quality-foundation-contain {
      padding-top: 90px;
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .strong-quality-foundation-contain {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
    }
  }
  @media (-webkit-device-pixel-ratio: 0.8) {
    .strong-quality-foundation-contain {
    padding-top: 80px;
  }

}
  @media (-webkit-device-pixel-ratio: 0.9) {
    .strong-quality-foundation-contain {
    zoom: 85%;
    -moz-transform: scale(85);
    padding-top: 80px;
  }

}
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .strong-quality-foundation-contain {
      zoom: 80%;
      -moz-transform: scale(0.8);
      padding-top: 80px;
    }
  }
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  .image {
    width: 100%;
  }
  .content {
    padding-left: 130px;
    padding-right: 130px;
    padding-top: 106px;
    padding-bottom: 106px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .strong-quality-foundation {
    width: 100%;
    height: auto;
    margin-top: -15px;
    .strong-quality-foundation-backgroud {
      background-image: unset;
      align-items: unset;
      height: unset;
      .title {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 80px;
      }
      .strong-quality-foundation-contain {
        .content {
          padding: unset;
          padding-top: 60px;
          .content-image {
            display: flex;
            align-items: center;
            justify-content: center;
            .image {
              display: none;
            }
            .image-tablet-mobile {
              display: inline;
              width: 100%;
              height: auto;
              padding-left: 221px;
              padding-right: 220px;
              padding-bottom: 80px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .strong-quality-foundation {
    width: 100%;
    height: auto;
    margin-top: -5px;
    .strong-quality-foundation-backgroud {
      background-image: unset;
      align-items: unset;
      height: unset;
      .title {
        padding-top: 80px;
        font-size: 38px;
        padding-left: 49px;
        padding-right: 49px;
      }
      .strong-quality-foundation-contain {
        .content {
          padding: unset;
          padding-top: 60px;
          .content-image {
            display: flex;
            align-items: center;
            justify-content: center;
            .image {
              display: none;
            }
            .image-tablet-mobile {
              display: inline;
              width: 100%;
              padding-left: 24px;
              padding-right: 24px;
              height: auto;
              padding-bottom: 80px;
            }
          }
        }
      }
    }
  }
}
