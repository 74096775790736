.members-five {
  background: #1eb2ff;
  .members-five-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .members-five-content {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .members-five-content {
      padding-top: 80px;
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .members-five-content {
      padding-top: 90px;
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .members-five-content {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
    }
  }
  @media (-webkit-device-pixel-ratio: 0.8) {
    .members-five-content {
    padding-top: 60px;
  }

}
  @media (-webkit-device-pixel-ratio: 0.9) {
    .members-five-content {
    zoom: 85%;
    padding-top: 60px;
    -moz-transform: scale(85);
  }

}
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .members-five-content {
      padding-top: 35px;
      zoom: 80%;
      -moz-transform: scale(0.8);
    }
  }
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  .content {
    padding: 56px 125px 50px 125px;
  }
  .content-image {
    padding-right: 36px;
    .image {
      width: 579px;
      height: 410px;
    }
  }
  .box-backgroud {
    background: #ffffff;
    width: 238px;
    height: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    .memberfive-icon {
      width: 42px;
      height: 42px;
    }
  }
  .box {
    padding-bottom: 30px;
  }
  .content-box-right {
    padding-left: 24px;
  }
  .box-text {
    padding-left: 11px;
  }
  .content-box {
    font-weight: bold;
    position: relative;
  }
  .Solutions-title-hr {
    border: 3px solid #ffffff;
  }
  .Solutions-title-hr-nav-1 {
    width: 50px;
    position: absolute;
    left: 224px;
    top: 45px;
  }
  .Solutions-title-hr-nav-2 {
    width: 50px;
    position: absolute;
    left: 224px;
    bottom: 30px;
  }
  .Solutions-title-hr-nav-3 {
    width: 50px;
    position: absolute;
    left: 75px;
    transform: rotate(90deg);
    top: 90px;
  }
  .Solutions-title-hr-nav-4 {
    width: 50px;
    position: absolute;
    left: 75px;
    transform: rotate(90deg);
    top: 200px;
  }
  .Solutions-title-hr-nav-5 {
    width: 50px;
    position: absolute;
    left: 75px;
    transform: rotate(90deg);
    top: 310px;
  }
  .Solutions-title-hr-nav-6 {
    width: 50px;
    position: absolute;
    right: 90px;
    transform: rotate(90deg);
    top: 90px;
  }
  .Solutions-title-hr-nav-7 {
    width: 50px;
    position: absolute;
    right: 90px;
    transform: rotate(90deg);
    top: 200px;
  }
  .Solutions-title-hr-nav-8 {
    width: 50px;
    position: absolute;
    right: 90px;
    transform: rotate(90deg);
    top: 310px;
  }
}

@media screen and (min-width: 1140px) and (max-width: 1365px) {
  .members-five {
    .members-five-backgroud {
      .members-five-content {
        .content-image {
          .image {
            padding-left: 98px !important;
            padding-right: 98px !important;
          }
        }
        .content-box {
          .Solutions-title-hr-nav-1 {
            margin-left: 21em !important;
          }
          .Solutions-title-hr-nav-2 {
            margin-left: 21em !important;
          }

          .Solutions-title-hr-nav-3 {
            margin-top: 58px !important;
            margin-left: 262px !important;
          }
          .Solutions-title-hr-nav-4 {
            margin-top: 58px !important;
            margin-left: 262px !important;
          }
          .Solutions-title-hr-nav-5 {
            margin-top: 75px !important;
            margin-left: 262px !important;
          }
          .Solutions-title-hr-nav-6 {
            margin-top: 58px !important;
            margin-right: 248px !important;
          }
          .Solutions-title-hr-nav-7 {
            margin-top: 60px !important;
            margin-right: 248px !important;
          }
          .Solutions-title-hr-nav-8 {
            margin-top: 75px !important;
            margin-right: 248px !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1098px) and (max-width: 1139px) {
  .members-five {
    .members-five-backgroud {
      .members-five-content {
        .content-image {
          .image {
            padding-left: 98px !important;
            padding-right: 98px !important;
          }
        }
        .content-box {
          .Solutions-title-hr-nav-1 {
            margin-left: 14em !important;
          }
          .Solutions-title-hr-nav-2 {
            margin-left: 14em !important;
          }

          .Solutions-title-hr-nav-3 {
            margin-left: 210px !important;
          }
          .Solutions-title-hr-nav-4 {
            margin-top: 58px !important;
            margin-left: 207px !important;
          }
          .Solutions-title-hr-nav-5 {
            margin-top: 75px !important;
            margin-left: 207px !important;
          }
          .Solutions-title-hr-nav-6 {
            margin-top: 58px !important;
            margin-right: 190px !important;
          }
          .Solutions-title-hr-nav-7 {
            margin-top: 60px !important;
            margin-right: 190px !important;
          }
          .Solutions-title-hr-nav-8 {
            margin-top: 75px !important;
            margin-right: 190px !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1097px) {
  .members-five {
    .members-five-backgroud {
      .members-five-content {
        .content-image {
          .image {
            padding-left: 98px !important;
            padding-right: 98px !important;
          }
        }
        .content-box {
          .Solutions-title-hr-nav-1 {
            margin-left: 13em !important;
          }
          .Solutions-title-hr-nav-2 {
            margin-left: 13em !important;
          }

          .Solutions-title-hr-nav-3 {
            margin-left: 210px !important;
          }
          .Solutions-title-hr-nav-4 {
            margin-top: 58px !important;
            margin-left: 207px !important;
          }
          .Solutions-title-hr-nav-5 {
            margin-top: 75px !important;
            margin-left: 207px !important;
          }
          .Solutions-title-hr-nav-6 {
            margin-top: 58px !important;
            margin-right: 190px !important;
          }
          .Solutions-title-hr-nav-7 {
            margin-top: 60px !important;
            margin-right: 190px !important;
          }
          .Solutions-title-hr-nav-8 {
            margin-top: 75px !important;
            margin-right: 190px !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1023px) {
  .members-five {
    .members-five-backgroud {
      .members-five-content {
        .content-image {
          .image {
            padding-left: 98px !important;
            padding-right: 98px !important;
          }
        }
        .content-box {
          .Solutions-title-hr-nav-1 {
            margin-left: 11em !important;
          }
          .Solutions-title-hr-nav-2 {
            margin-left: 11em !important;
          }

          .Solutions-title-hr-nav-3 {
            margin-left: 210px !important;
          }
          .Solutions-title-hr-nav-4 {
            margin-top: 58px !important;
            margin-left: 207px !important;
          }
          .Solutions-title-hr-nav-5 {
            margin-top: 75px !important;
            margin-left: 207px !important;
          }
          .Solutions-title-hr-nav-6 {
            margin-top: 58px !important;
            margin-right: 190px !important;
          }
          .Solutions-title-hr-nav-7 {
            margin-top: 60px !important;
            margin-right: 190px !important;
          }
          .Solutions-title-hr-nav-8 {
            margin-top: 75px !important;
            margin-right: 190px !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 899px) {
  .members-five {
    .members-five-backgroud {
      .members-five-content {
        .content-image {
          .image {
            padding-left: 98px !important;
            padding-right: 98px !important;
          }
        }
        .content-box {
          .Solutions-title-hr-nav-1 {
            margin-left: 9em !important;
          }
          .Solutions-title-hr-nav-2 {
            margin-left: 8em !important;
          }

          .Solutions-title-hr-nav-3 {
            margin-left: 167px !important;
          }
          .Solutions-title-hr-nav-4 {
            margin-top: 58px !important;
            margin-left: 167px !important;
          }
          .Solutions-title-hr-nav-5 {
            margin-top: 75px !important;
            margin-left: 167px !important;
          }
          .Solutions-title-hr-nav-6 {
            margin-top: 58px !important;
            margin-right: 143px !important;
          }
          .Solutions-title-hr-nav-7 {
            margin-top: 60px !important;
            margin-right: 143px !important;
          }
          .Solutions-title-hr-nav-8 {
            margin-top: 75px !important;
            margin-right: 143px !important;
          }
        }
      }
    }
  }
}
// @media screen and (min-width: 768px) and (max-width: 959px) {
//   .members-five {
//     .members-five-backgroud {
//       .members-five-content {
//         .content-box {
//           .Solutions-title-hr-nav-1 {
//             margin-left: 10em !important;
//           }
//           .Solutions-title-hr-nav-2 {
//             margin-left: 10em !important;
//           }
//         }
//       }
//     }
//   }
// }
@media screen and (min-width: 794px) and (max-width: 845px) {
  .members-five {
    .members-five-backgroud {
      .members-five-content {
        .content-box {
          .Solutions-title-hr-nav-1 {
            width: 100px !important;
          }
          .Solutions-title-hr-nav-2 {
            width: 50px !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 793px) {
  .members-five {
    .members-five-backgroud {
      .members-five-content {
        .content-box {
          .Solutions-title-hr-nav-1 {
            width: 50px !important;
            margin-left: 9em !important;
          }
          .Solutions-title-hr-nav-2 {
            width: 50px !important;
            margin-left: 9em !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .members-five {
    padding-top: 50px;
    margin-top: -15px;
    margin-bottom: -10px;
    .members-five-backgroud {
      background-image: none;
      align-items: unset;
      height: auto;
      justify-content: unset;

      .title {
        display: none;
      }

      .content {
        padding: unset;
        .content-image {
          width: 100%;
          height: auto;
          .image {
            width: 100%;
            height: auto;
            padding-left: 95px;
            padding-right: 94px;
          }
        }
      }

      .content-momberfive {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 5px;
      }

      .content-image {
        padding-right: unset;
      }

      .content-momberfive {
        .content-box {
          padding-top: 40px;
          font-weight: bold;
          width: 100%;
          padding-left: 100px;
          padding-right: 100px;

          .Solutions-title-hr-nav-1 {
            top: 86px;
            width: 149px;
          }
          .Solutions-title-hr-nav-2 {
            width: 149px !important;
            bottom: 41px;
            margin-left: 11em;
          }

          .Solutions-title-hr-nav-3 {
            margin-top: 58px;
          }
          .Solutions-title-hr-nav-4 {
            margin-top: 71px;
          }
          .Solutions-title-hr-nav-5 {
            margin-top: 85px;
          }
          .Solutions-title-hr-nav-6 {
            margin-top: 60px;
            margin-right: 9em;
          }
          .Solutions-title-hr-nav-7 {
            margin-top: 73px;
            margin-right: 9em;
          }
          .Solutions-title-hr-nav-8 {
            margin-top: 85px;
            margin-right: 9em;
          }
        }
        .content-box-left {
          padding-left: 24px;
          padding-right: 12px;
          width: 100%;
          .box-backgroud {
            width: 100%;
            height: auto;
            padding-top: 25px;
            padding-bottom: 25px;
          }
        }
        .content-box-right {
          width: 100%;
          padding-right: 24px;
          padding-left: 12px;
          .box-backgroud {
            width: 100%;
            height: auto;
            padding-top: 25px;
            padding-bottom: 25px;
          }
        }
      }
    }
    .box-four {
      position: relative;
      z-index: 1;
    }
  }
}
@media screen and (max-width: 767px) {
  .members-five {
    padding-top: 40px;
    margin-top: -3px;

    .members-five-backgroud {
      background-image: none;
      align-items: unset;
      height: auto;
      justify-content: unset;
      .content {
        padding: unset;
        .content-box {
          font-weight: bold;
          justify-content: center;
        }
      }
      .title {
        display: none;
      }
      .content-momberfive {
        flex-direction: column;
      }
      .content-image {
        width: 100%;
        height: auto;
        padding: unset;
        .image {
          width: 100%;
          height: auto;
          padding-left: 33px;
          padding-right: 33px;
          padding-bottom: 20px;
        }
      }
      .box-backgroud {
        // display:flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        .box-text {
          padding-left: unset;
          padding-top: 10px;
        }
      }
      .content-box {
        flex-direction: column;
        padding-left: 33px;
        padding-right: 33px;

        .content-box-left {
          display: flex;
          flex-direction: row;
          padding-left: unset;
          flex-wrap: wrap;

          .box-member-1 {
            padding-right: 12px;
          }
          .box-four {
            width: 50%;
          }
          .box {
            width: 50%;
            padding-bottom: 12px;
          }
        }
        .content-box-right {
          display: flex;
          flex-direction: row;
          padding-left: unset;
          flex-wrap: wrap;
          .box-member-1 {
            padding-right: 12px;
          }
          .box {
            padding-bottom: 12px;
            width: 50%;
          }
          .box-four {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 650px) and (max-width: 767px) {
  .members-five {
    .content-box {
      .Solutions-title-hr-nav-1 {
        left: 235px;
        top: 61px;
        width: 150px;
      }
      .Solutions-title-hr-nav-2 {
        left: 235px;
        width: 150px !important;
        bottom: 60px;
      }
      .Solutions-title-hr-nav-3 {
        margin-top: 2em;
        margin-left: 6em !important;
        width: 30px;
      }
      .Solutions-title-hr-nav-4 {
        margin-top: 3em;
        margin-left: 6em !important;
        width: 30px;
      }
      .Solutions-title-hr-nav-5 {
        margin-top: 4em;
        width: 30px;
        margin-left: 6em !important;
      }
      .Solutions-title-hr-nav-6 {
        margin-right: 4em !important;
        padding-top: 4em;
        width: 30px;
      }
      .Solutions-title-hr-nav-7 {
        margin-right: 39px !important;
        padding-top: 7em;
        width: 30px;
      }
      .Solutions-title-hr-nav-8 {
        margin-right: 24px !important;
        padding-top: 9em;
        width: 30px;
      }
    }
  }
}
@media screen and (min-width: 500px) and (max-width: 649px) {
  .members-five {
    .content-box {
      .Solutions-title-hr-nav-1 {
        left: 235px;
        top: 61px;
        width: 150px;
      }
      .Solutions-title-hr-nav-2 {
        left: 235px;
        width: 150px !important;
        bottom: 60px;
      }
      .Solutions-title-hr-nav-3 {
        margin-left: 4em !important;
        top: 117px;
      }
      .Solutions-title-hr-nav-4 {
        top: 243px;
        margin-left: 4em !important;
      }
      .Solutions-title-hr-nav-5 {
        top: 372px;
        margin-left: 4em !important;
      }
      .Solutions-title-hr-nav-6 {
        margin-right: 3em !important;
        top: 114px;
      }
      .Solutions-title-hr-nav-7 {
        margin-right: 3em !important;
        top: 251px;
      }
      .Solutions-title-hr-nav-8 {
        margin-right: 3em !important;
        top: 372px;
      }
    }
  }
}
@media screen and (min-width: 460px) and (max-width: 499px) {
  .members-five {
    .content-box {
      .Solutions-title-hr-nav-1 {
        left: 235px;
        top: 61px;
        width: 150px;
      }
      .Solutions-title-hr-nav-2 {
        left: 235px;
        width: 150px !important;
        bottom: 60px;
      }
      .Solutions-title-hr-nav-3 {
        margin-left: 2em !important;
        top: 117px;
      }
      .Solutions-title-hr-nav-4 {
        top: 243px;
        margin-left: 2em !important;
      }
      .Solutions-title-hr-nav-5 {
        top: 372px;
        margin-left: 2em !important;
      }
      .Solutions-title-hr-nav-6 {
        margin-right: 2em !important;
        top: 114px;
      }
      .Solutions-title-hr-nav-7 {
        margin-right: 2em !important;
        top: 251px;
      }
      .Solutions-title-hr-nav-8 {
        margin-right: 2em !important;
        top: 372px;
      }
    }
  }
}
@media screen and (min-width: 390px) and (max-width: 459px) {
  .members-five {
    .content-box {
      .Solutions-title-hr-nav-1 {
        left: 235px;
        top: 61px;
        width: 150px;
      }
      .Solutions-title-hr-nav-2 {
        left: 235px;
        width: 150px !important;
        bottom: 60px;
      }
      .Solutions-title-hr-nav-3 {
        margin-left: 1em !important;
        top: 117px;
      }
      .Solutions-title-hr-nav-4 {
        top: 243px;
        margin-left: 1em !important;
      }
      .Solutions-title-hr-nav-5 {
        top: 372px;
        margin-left: 1em !important;
      }
      .Solutions-title-hr-nav-6 {
        margin-right: 1em !important;
        top: 114px;
      }
      .Solutions-title-hr-nav-7 {
        margin-right: 1em !important;
        top: 251px;
      }
      .Solutions-title-hr-nav-8 {
        margin-right: 1em !important;
        top: 372px;
      }
    }
  }
}

@media screen and (min-width: 325px) and (max-width: 389px) {
  .members-five {
    .content-box {
      .Solutions-title-hr-nav-1 {
        left: 124px;
        top: 61px;
        width: 75px;
      }
      .Solutions-title-hr-nav-2 {
        left: 124px;
        width: 75px;
        bottom: 60px;
      }
      .Solutions-title-hr-nav-3 {
        top: 117px;
        margin-left: 0em !important;
      }
      .Solutions-title-hr-nav-4 {
        top: 245px;
        margin-left: 0em !important;
      }
      .Solutions-title-hr-nav-5 {
        top: 369px;
        margin-left: 0em !important;
      }
      .Solutions-title-hr-nav-6 {
        top: 117px;
        margin-right: -2px !important;
      }
      .Solutions-title-hr-nav-7 {
        top: 244px;
        margin-right: -2px !important;
      }
      .Solutions-title-hr-nav-8 {
        top: 373px;
        margin-right: -2px !important;
      }
    }
  }
}
@media screen and (min-width: 315px) and (max-width: 324px) {
  .members-five {
    .content-box {
      .Solutions-title-hr-nav-1 {
        left: 98px;
        top: 61px;
        width: 75px;
      }
      .Solutions-title-hr-nav-2 {
        left: 98px;
        width: 75px;
        bottom: 60px;
      }
      .Solutions-title-hr-nav-3 {
        top: 117px;
        margin-left: -1em !important;
      }
      .Solutions-title-hr-nav-4 {
        top: 245px;
        margin-left: -1em !important;
      }
      .Solutions-title-hr-nav-5 {
        top: 369px;
        margin-left: -1em !important;
      }
      .Solutions-title-hr-nav-6 {
        top: 117px;
        margin-right: -15px !important;
      }
      .Solutions-title-hr-nav-7 {
        top: 244px;
        margin-right: -15px !important;
      }
      .Solutions-title-hr-nav-8 {
        top: 373px;
        margin-right: -15px !important;
      }
    }
  }
}

@media screen and (max-width: 314px) {
  .members-five {
    .members-five-backgroud {
      .content-box {
        .box-text {
          padding-left: 26px;
          padding-right: 26px;
        }
        .box-four {
          .box-text {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
        .Solutions-title-hr-nav-1 {
          width: 50px;
          top: 75px;
          margin-left: -6em !important;
        }
        .Solutions-title-hr-nav-2 {
          width: 50px;
          bottom: 78px;
          margin-left: -6em !important;
        }
        .Solutions-title-hr-nav-3 {
          top: 142px;
          left: 62px;
        }
        .Solutions-title-hr-nav-4 {
          left: 62px;
          top: 291px;
        }
        .Solutions-title-hr-nav-5 {
          left: 62px;
          top: 441px;
        }
        .Solutions-title-hr-nav-6 {
          right: 70px;
          top: 146px;
        }
        .Solutions-title-hr-nav-7 {
          right: 70px;
          top: 290px;
        }
        .Solutions-title-hr-nav-8 {
          right: 70px;
          top: 445px;
        }
      }
    }
  }
}
@media screen and (min-width: 390px) and (max-width: 494px) {
  .members-five {
    .content-box {
      .Solutions-title-hr-nav-1 {
        left: 97px !important;
        top: 61px !important;
      }
      .Solutions-title-hr-nav-2 {
        left: 97px !important;

        bottom: 62px !important;
      }
    }
  }
}
