.clients-map {
  flex-wrap: wrap;

  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .clients-map-img {
      zoom: 70%;
    }
  }
  .clients-map-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/ClientsMap/backgroud-frame-clients.webp");
    display: grid;
    align-items: center;
  }
  .clients-map-text {
    font-size: 48px;
    line-height: 120%;
    text-transform: capitalize;
    color: #222222;
    font-weight: 700;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards 1.5s;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  .clients-map-img {
    padding-top: 53px;
    img {
      height: 461px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .mobile-page {
    margin: auto;
    display: block !important;
    width: fit-content;
  }
  .clients-map {
    display: block !important;
    position: relative;
    top: -8em;
    background-color: #fff;
    .clients-map-backgroud {
      display: block;
      background-image: none;
      height: auto;
      .clients-map-content {
        margin: 2em;
        margin-bottom: -40px;
        .clients-map-text {
          padding-top: 56px;
        }
        .clients-map-img {
          padding-top: 53px;
          img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .mobile-page {
    margin: auto;
    display: block !important;
    width: fit-content;
  }
  .clients-map {
    display: block !important;
    // margin-top: 45em;

    .clients-map-backgroud {
      display: block;
      background-image: none;
      height: auto;
      margin-bottom: 5vh;
    }
    .clients-map-content {
      margin: 1em;
      .clients-map-text {
        padding-top: 56px;
        font-size: 38px;
        font-weight: 700;
        padding-left: 5px;
        padding-right: 5px;
      }
      .clients-map-img {
        padding-top: 40px;
        padding-bottom: 40px;
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .mobile-page {
    margin: auto;
    display: block !important;
    width: fit-content;
  }
  .clients-map {
    display: block !important;
    // margin-top: 45em;

    .clients-map-backgroud {
      display: block;
      background-image: none;
      height: auto;
      margin-bottom: 5vh;
    }
    .clients-map-content {
      margin: 1em;
      .clients-map-text {
        padding-top: 56px;
        font-size: 38px;
        font-weight: 700;
        padding-left: 5px;
        padding-right: 5px;
      }
      .clients-map-img {
        padding-top: 40px;
        padding-bottom: 15px;
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
