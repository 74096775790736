.data-science-lab {
  flex-wrap: wrap;
  height: calc(100vh - 102px);
  justify-content: center;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 0.8) {
    .research-center-content {
      zoom: 100%;
      -moz-transform: scale(1);
      padding-left: 140px !important;
    }
    .image-members-1-top {
      zoom: 80%;
      -moz-transform: scale(0.8);
    }
  }
  @media (-webkit-device-pixel-ratio: 0.9) {
    .research-center-content {
      zoom: 85%;
      -moz-transform: scale(0.85);
      padding-left: 180px !important;
    }
    .image-members-1-top {
      zoom: 70%;
      width: 80%;
      -moz-transform: scale(0.7);
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-device-pixel-ratio: 1) {
    .research-center-content {
      zoom: 85%;
      -moz-transform: scale(0.85);
      padding-left: 180px !important;
    }
    .image-members-1-top {
      zoom: 70%;
      width: 80%;
      -moz-transform: scale(0.7);
    }
  }
  .research-center-content {
    padding-left: 70px;
    padding-right: 10px;
    margin-top: -150px;
  }
  .research-center-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    color: #222222;
    padding-top: 20px;
  }

  .members-us-img {
    padding-right: 0 !important;
    background: #1eb2ff;
    height: 100vh;
    // width: 100vh;
    align-items: center;
    display: flex;
    // position: relative;
  }
  .image-members-1-top {
    position: relative;
    right: 95px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    top: -100px;
    padding-top: 78px;
  }
  .research-center-box {
    padding-top: 36px;
    padding-right: 100px;
  }
  .about-us-backroud-frame {
    position: absolute;
    right: 0px;
    top: 0px;
    img {
      width: 222px;
      height: 370px;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.01) and (-webkit-max-device-pixel-ratio: 1.19) {
    .experienced-in-large-right {
      .image-members-1-top {
        -moz-transform: scale(0.85);
        zoom: 85%;
      }
    }
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .experienced-in-large-right {
      .image-members-1-top {
        top: -13em;
        zoom: 22%;
        -moz-transform: scale(0.22);
        right: 27em;
        .image-top {
          padding-bottom: 35px;
        }
      }
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .experienced-in-large-right {
      .image-members-1-top {
        top: -69px;
        -moz-transform: scale(0.8);
        zoom: 80%;
        right: 9em;
      }
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .experienced-in-large-right {
      .image-members-1-top {
        -moz-transform: scale(0.8);
        zoom: 80%;
        top: -65px;
      }
    }
    .research-center-content {
      -moz-transform: scale(0.8);
      left: 40px;
      zoom: 80%;
    }
  }
  .experienced-in-large-right {
    padding-left: 120px;
    padding-right: 0px;
  }
  .image-bottom {
    padding-top: 16px;
    padding-left: 0px;
    padding-right: 0px;
    img {
      width: 100%;
      height: 245px;
    }
  }
  .image-top {
    padding-left: 0px;
    padding-right: 0px;
    img {
      width: 100%;
      height: 395px;
    }
  }
  .box-col {
    padding-left: 0px;
  }
  .box-backgroud {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    height: 120px;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #222222;
    text-align: left;
  }
  .box-1 {
    padding-bottom: 16px;
  }
  .box-3 {
    padding-bottom: 16px;
  }
  .box-text-1 {
    padding-left: 36px;
    padding-right: 36px;
  }
  .box-text-2 {
    padding-left: 36px;
    padding-right: 36px;
  }
  .data-science-lab-backgroud {
    position: fixed;
    left: 0px;
    top: 32px;
    display: none;
    .backgroud-image {
      height: 100vh;
    }
  }
}
@media screen and (max-width: 1920px) {
  .data-science-lab {
    .research-center-content {
      padding-left: 15em;
    }
  }
}
@media screen and (max-width: 1800px) {
  .data-science-lab {
    .research-center-content {
      padding-left: 11em;
    }
  }
}
@media screen and (max-width: 1700px) {
  .data-science-lab {
    .research-center-content {
      padding-left: 9em;
    }
  }
}
@media screen and (max-width: 1600px) {
  .data-science-lab {
    .research-center-content {
      padding-left: 6em;
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .data-science-lab {
    .image-bottom {
      img {
        width: 100%;
        height: auto;
      }
    }
    .image-top {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .data-science-lab .backgroud-image {
    display: none;
  }
  .data-science-lab {
    display: block !important;
    height: auto;
    background-color: #fff;
    position: relative;
    top: 40px;
    .research-center-content {
      max-width: 100%;
      padding-left: 98px;
      padding-right: 98px;

      .research-center-title {
        text-align: center;
        padding-top: 80px;
      }
      .research-center-box {
        padding-bottom: 120px;
        padding-right: 0;
        .box-col {
          display: flex;
          flex-direction: row;
          .box-1 {
            width: 50%;
            padding-right: 8px;
          }
          .box-2 {
            padding-left: 8px;
            width: 50%;
          }
        }
      }
    }
    .experienced-in-large-right {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 100%;
      .members-us-img {
        height: auto;
        .d-flex {
          width: 100%;

          .image-members-1-top {
            width: 100%;
            right: 0;
            top: -10em;
            padding-left: 98px;
            padding-right: 98px;
            .image-top {
              img {
                width: 100%;
                height: auto;
              }
            }
            .image-bottom {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .data-science-lab .backgroud-image {
    display: none;
  }
  .data-science-lab {
    display: block !important;
    height: auto;
    background-color: #fff;
    position: relative;
    top: 40px;
    .research-center-content {
      max-width: 100%;
      padding-left: 24px;
      padding-right: 24px;

      .research-center-title {
        text-align: center;
        padding-top: 80px;
      }
      .research-center-box {
        padding-bottom: 120px;
        padding-right: 0;
        .box-col {
          display: flex;
          flex-direction: row;
          padding-left: 0px;
          padding-right: 0px;
          .box-1 {
            width: 50%;
            padding-right: 8px;
          }
          .box-2 {
            padding-left: 8px;
            width: 50%;
          }
        }
      }
    }
    .experienced-in-large-right {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 100%;
      .members-us-img {
        height: auto;
        .d-flex {
          width: 100%;

          .image-members-1-top {
            width: 100%;
            right: 0;
            top: -10em;
            padding-left: 24px;
            padding-right: 24px;
            .image-top {
              img {
                width: 100%;
                height: auto;
              }
            }
            .image-bottom {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .data-science-lab .backgroud-image {
    display: none;
  }
  .data-science-lab {
    display: block !important;
    height: auto;
    background-color: #fff;
    position: relative;
    top: 10px;
    .research-center-content {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;

      .research-center-title {
        text-align: center;
        padding-top: 60px;
      }
      .research-center-box {
        padding-bottom: 120px;
        padding-right: 0;
        .box-col {
          display: flex;
          flex-direction: column;
          .box-1 {
            width: 100%;
            .box-text-1 {
              text-align: center;
            }
          }
          .box-2 {
            width: 100%;
            .box-text-2 {
              text-align: center;
            }
          }
        }
      }
    }
    .experienced-in-large-right {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 100%;
      .members-us-img {
        height: auto;
        .d-flex {
          width: 100%;

          .image-members-1-top {
            width: 100%;
            right: 0;
            top: -10em;
            padding-left: 16px;
            padding-right: 16px;
            .image-top {
              img {
                width: 100%;
                height: auto;
              }
            }
            .image-bottom {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
