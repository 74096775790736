.solutions-three {
  background: #1eb2ff;
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .solutions-content {
      zoom: 90%;
      width: 90%;
      margin: 0 auto;
      -moz-transform: scale(0.9);
    }
  }
  .solutions-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .solutions-contain {
    width: 100%;
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .solutions-contain {
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }
  .solutions-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  .solutions-content {
    padding: 35px 60px;
  }
  .solutions-content-col {
    padding: 0px;
  }
  .box-text {
    // width: 613px;
    height: 210px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    // padding: 35px;
    padding: 35px 32px 70px 32px;
  }
  .box-text-bottom {
    padding-top: 24px;
  }

  .box-text-mobile {
    display: none;
  }
  .box-text-mobile {
    // width: 613px;
    height: 210px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    // padding: 35px;
    padding: 35px 32px 70px 32px;
  }
  .box-title-telecom {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #222222;
    padding-left: 14px;
    padding-top: 5px;
  }

  .box-text-content {
    padding-top: 20px;
    padding-left: 18px;
    // padding-bottom: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #222222;
  }
  .box-text-content-col {
    text-align: left;
  }
  li::marker {
    color: #1eb2ff;
  }
  .box-text-content-col > ul {
    line-height: 180%;
    padding-left: 0px;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
  }
  .col-right {
    padding-left: 10px;
  }
  .col-left {
    padding-right: 10px;
  }
  .box-image {
    padding-top: 30px;
  }
  .box-image-col-1 {
    padding-left: 0;
    padding-right: 7px;
  }
  .box-image-col-2 {
    padding-right: 0px;
    padding-left: 8px;
  }
  .box-image-1 {
    width: 100%;
    height: 210px;
  }
  .box-image-2 {
    width: 100%;
    height: 210px;
  }
  .box-icon {
    width: 34px;
    height: 34px;
  }
  .box-image-right {
    padding-top: 24px;
  }
  .box-title-commerce-right {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #222222;
    padding-left: 14px;
  }
  .box-image-col-3 {
    width: 100%;
  }
  .unactive {
    display: none;
  }
  .box-text-content-col-right {
    padding-left: 52px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .solutions-three {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 98px;
        padding-right: 98px;

        .solutions-title {
          display: none;
        }
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 28px 0px 0px 0px;
          margin: 0 auto;

          .solutions-content-col {
            max-width: 100% !important;
            .box-text-content {
              //   display: block !important;
            }
          }

          .col-right {
            padding-top: 24px;
            padding-left: 0px;
          }
          .col-left {
            padding-right: 0px;
            .box-text {
              display: none;
            }
            .box-text-bottom {
              display: none;
            }
            .box-text-bottom-mobile {
              padding-top: 24px;
            }
            .box-text-mobile {
              display: block;
              height: 173px;
            }
          }

          .box-image-col-3 {
            padding-top: 24px;
          }
        }
      }
    }

    .box-text-content-col > ul {
      line-height: 180%;
    }
  }
  .solutions-three .box-image-1 {
    width: 100%;
    height: auto;
  }
  .solutions-three .box-image-2 {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .solutions-three {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 24px;
        padding-right: 20px;
        .solutions-title {
          display: none;
        }
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 28px 0px 0px 0px;
          margin: 0 auto;

          .solutions-content-col {
            max-width: 100% !important;
            .box-text-content {
              //   display: block !important;
            }
          }

          .col-right {
            padding-top: 24px;
            padding-left: 0px;
          }
          .col-left {
            padding-right: 0px;
            .box-text {
              display: none;
            }
            .box-text-bottom-mobile {
              padding-top: 24px;
            }
            .box-text-mobile {
              display: block;
              height: 173px;
            }
          }

          .box-image-col-3 {
            padding-top: 24px;
          }
        }
      }
    }

    .box-text-content-col > ul {
      line-height: 190%;
    }
  }
  .solutions-three .box-image-1 {
    width: 100%;
    height: auto;
  }
  .solutions-three .box-image-2 {
    width: 100%;
    height: auto;
  }
  .solutions-three .box-text-bottom {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  .solutions-three {
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 17px;
        padding-right: 15px;
        .solutions-title {
          display: none;
        }
        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 80px 0px 0px 0px;
          margin: 0 auto;
          padding-top: 24px;
          .solutions-content-col {
            max-width: 100% !important;

            .box-text-content {
              flex-direction: column;

              .box-text-content-col-right {
                padding-left: 0px;
                position: relative;
                bottom: 1em;
              }
            }
            .box-image-right {
              .box-image-col-1 {
                padding-right: 4px;
              }
              .box-image-col-2 {
                padding-left: 4px;
              }
            }
          }

          .col-right {
            padding-top: 24px;
            padding-left: 0px;
          }
          .col-left {
            padding-right: 0px;
          }

          .box-image-col-3 {
            // padding-top: 24px;
          }
        }
      }
    }

    .box-text-content-col > ul {
      line-height: 180%;
    }
  }
  .solutions-three .box-image-1 {
    width: 100%;
    height: auto;
  }
  .solutions-three .box-image-2 {
    width: 100%;
    height: auto;
  }
  .solutions-three .box-text {
    height: auto;
    padding: 35px 15px 0px 32px;
  }
  .solutions-three .box-title-telecom {
    text-align: left;
  }
}
