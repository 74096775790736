.none-desktop {
  display: block !important;
}
.mobile-page {
  display: none !important;
}

.active-scroll {
  color: #171819 !important;
  font-weight: 500;
}

.home-page {
  .home-aboutus-technology {
    position: relative;
  }
  .aboutus-technology-contain-img {
    position: absolute;
    top: 1152px;
  }
  .aboutus-technology-img {
    height: 1350px;
    width: 800px;
  }
  .aboutus-TechnologySolutions-contain-img {
    position: absolute;
    top: 3800px;
    right: 0px;
  }
  .aboutus-TechnologySolutions-img {
    height: 1350px;
  }

  .swiper-button-prev {
    display: none;
  }
  @media (-webkit-device-pixel-ratio: 1.25) {
    .image-page-scale {
      bottom: -3em;
    }
  }
  .swiper-button-next {
    background-image: url("../images/banner/icon-next.webp");
    width: 100px;
    height: 33px;
    top: unset;
    justify-content: unset;
    right: -28px;
    opacity: 1;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 56px 29px;
    z-index: 47;
    bottom: 8px;
  }

  .swiper-button-next::after {
    animation: scrollDownAnimation 2s infinite;
    background-color: #fff;
    content: "";
    height: 6px;
    margin: 0 auto;
    position: absolute;
    right: 85px;
    display: block;
    top: 0px;
    width: 2px;
  }
  @keyframes scrollDownAnimation {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: translate(0, 12px);
    }
    100% {
      opacity: 0;
    }
  }
  .carousel-indicators {
    bottom: 20px;
  }
  .visually-hidden {
    display: none;
  }
  .swiper-pagination .swiper-pagination-bullet {
    width: 9px;
    height: 7px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.3);
    border: none;
    margin-left: 6px;
    opacity: unset;
  }
  .swiper-pagination-bullet-active {
    width: 28px !important;
    height: 4px !important;
    border-radius: 100px !important;
    background: #ffffff !important;
    position: relative;
    bottom: 1px;
  }
  .swiper-pagination {
    align-items: center;
  }
  span.swiper-pagination-bullet:focus {
    outline: unset;
  }
  .carousel-control-next:focus {
    color: #fff !important;
    text-decoration: none !important;
    outline: 0 !important;
    opacity: 0.9 !important;
  }
}

.members-one-backgroud {
  position: relative;
}

.home-page {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.3);
  }
  .swiper-pagination-bullet-active {
    background: #ffffff !important;
    position: relative;
    bottom: 1px;
  }
}
.about-us {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
}
.infographics {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
  .swiper-button-next {
    background-image: url("../images/banner/icon-next-black.webp");
  }
  .swiper-button-next::after {
    animation: scrollDownAnimation 2s infinite;
    background-color: black;
    content: "";
    height: 6px;
    margin: 0 auto;
    position: absolute;
    right: 85px;
    display: block;
    top: 0px;
    width: 2px;
  }
  @keyframes scrollDownAnimation {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: translate(0, 12px);
    }
    100% {
      opacity: 0;
    }
  }
}
.clients-map {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
  .swiper-button-next {
    background-image: url("../images/banner/icon-next-black.webp");
  }
  .swiper-button-next::after {
    animation: scrollDownAnimation 2s infinite;
    background-color: black;
    content: "";
    height: 6px;
    margin: 0 auto;
    position: absolute;
    right: 85px;
    display: block;
    top: 0px;
    width: 2px;
  }
  @keyframes scrollDownAnimation {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: translate(0, 12px);
    }
    100% {
      opacity: 0;
    }
  }
}

.technologies {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
}

.membersOne {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
  .swiper-button-next {
    background-image: url("../images/banner/icon-next-black.webp");
    width: 100px;
    height: 33px;
    top: unset;
    justify-content: unset;
    right: -28px;
    opacity: 1;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 56px 29px;
    z-index: 47;
    bottom: 8px;
  }
  .swiper-button-next::after {
    animation: scrollDownAnimation 2s infinite;
    background-color: black;
    content: "";
    height: 6px;
    margin: 0 auto;
    position: absolute;
    right: 85px;
    display: block;
    top: 0px;
    width: 2px;
  }
  @keyframes scrollDownAnimation {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: translate(0, 12px);
    }
    100% {
      opacity: 0;
    }
  }
}
.membersTwo {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
}
.largeTalentPool {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
  .swiper-button-next {
    background-image: url("../images/banner/icon-next-black.webp");
    width: 100px;
    height: 33px;
    top: unset;
    justify-content: unset;
    right: -28px;
    opacity: 1;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 56px 29px;
    z-index: 47;
    bottom: 8px;
  }
  .swiper-button-next::after {
    animation: scrollDownAnimation 2s infinite;
    background-color: black;
    content: "";
    height: 6px;
    margin: 0 auto;
    position: absolute;
    right: 85px;
    display: block;
    top: 0px;
    width: 2px;
  }
  @keyframes scrollDownAnimation {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: translate(0, 12px);
    }
    100% {
      opacity: 0;
    }
  }
}
.pageNumber {
  width: 76px;
  height: 24px;
  position: absolute;
  bottom: 16px;
  left: 40px;
}
.experiencedInLarge {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
}
.TMASolutionsBinhDinh {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
}

.researchCenter {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
}
.dataScienceLab {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
}
.TMAInnovation {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
}
.TMAInnovationVN {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #ffffff !important;
  }
}

.contactUs {
  .swiper-pagination .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    background: #1eb2ff !important;
  }
  .swiper-button-next {
    background-image: url("../images/banner/icon-next-black.webp");
    width: 100px;
    height: 33px;
    top: unset;
    justify-content: unset;
    right: -28px;
    opacity: 1;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 56px 29px;
    z-index: 47;
    bottom: 8px;
  }
  .swiper-button-next::after {
    animation: scrollDownAnimation 2s infinite;
    background-color: black;
    content: "";
    height: 6px;
    margin: 0 auto;
    position: absolute;
    right: 85px;
    display: block;
    top: 0px;
    width: 2px;
  }
  @keyframes scrollDownAnimation {
    0% {
      opacity: 0;
      transform: translate(0, 0);
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: translate(0, 12px);
    }
    100% {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 1365px) {
  .none-desktop {
    display: none !important;
  }
  .mobile-page {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .home-page {
    .aboutus-technology-contain-img {
      display: none;
    }
    .aboutus-TechnologySolutions-contain-img {
      display: none;
    }
  }
}
@media screen and (max-width: 320px) {
  .home-page {
    .aboutus-technology-contain-img {
      display: none;
    }
    .aboutus-TechnologySolutions-contain-img {
      display: none;
    }
  }
}
