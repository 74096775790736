.mobile-menu {
  display: none;
}
.desktop-menu {
  display: block;
}

.menu-scroll-member {
  width: 100%;
  background-color: #ffffff;
  position: relative;
}
.mobile-menu-member {
  display: none;
}
.deskstop-menu-member {
  display: block;
  height: 36px;
  margin: 0 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 20;
  background-color: #ffffff;
  width: 100%;
  .title-menu-member {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .menu {
    height: 100%;
  }
  .title-menu-member {
    height: 100%;
    padding-top: 6px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    text-transform: capitalize;
    color: #515151;
    .row {
      position: relative;
      padding-left: 20px;
      padding-right: 20px;
    }
    :nth-child(2)::before {
      content: "|";
      color: #2222224d;
      position: absolute;
      left: 6px;
    }
    :nth-child(3)::before {
      content: "|";
      color: #2222224d;
      position: absolute;
      left: 6px;
    }
  }
  .menu-tag-a {
    color: #515151;
  }
  .active-tab {
    color: #1eb2ff !important;
    font-weight: 500;
  }
  .active-tab:hover {
    color: #1eb2ff !important;
    text-decoration: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
  .deskstop-menu-member {
    display: none;
  }
  .menu-scroll-member {
  }
  .mobile-menu-member {
    display: block !important;
    height: 40px;
    margin: 0 auto;
    z-index: 20;
    width: 100%;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 70px !important;
    height: 40px;
    z-index: 999;
    display: block !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .menu {
      justify-content: center;
      height: 100%;
    }
    .title-menu-member {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 100%;
      padding-top: 9px;
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      text-transform: capitalize;
      color: #515151;
      .row {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
      }
      .row:nth-child(2)::before {
        content: "|";
        color: #2222224d;
        position: absolute;
        left: 0px;
      }
      .row:nth-child(3)::before {
        content: "|";
        color: #2222224d;
        position: absolute;
        left: 0;
      }

      .mobile-menu {
        padding: unset;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .deskstop-menu-member {
    display: none;
  }
  .menu-scroll-member {
  }
  .mobile-menu-member {
    display: block !important;
    height: 40px;
    margin: 0 auto;
    width: 100%;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 60px !important;
    height: 40px;
    z-index: 999;
    display: block !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    .menu {
      justify-content: center;
      height: 100%;
      .mobile-menu{
        font-size: 13px;
      }
    }
    .title-menu-member {
      display: flex;
      flex-direction: row;
      height: 100%;
      padding-top: 9px;
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      text-transform: capitalize;
      color: #515151;
      justify-content: center;

      .row {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
      }
      .row:nth-child(2)::before {
        content: "|";
        color: #2222224d;
        position: absolute;
        left: 0px;
      }
      .row:nth-child(3)::before {
        content: "|";
        color: #2222224d;
        position: absolute;
        left: 0;
      }

      .mobile-menu {
        padding: unset;
      }
      .padding-left-16 {
        padding-left: unset !important;
      }
    }
  }
}
@media screen and (max-width: 458px) {
  .mobile-menu-member .title-menu-member .row {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 400px) {
  .deskstop-menu-member {
    display: none;
  }
  .menu-scroll-member {
  }
  .mobile-menu-member {
    display: block !important;
    height: 68px !important;
    margin: 0 auto;
    z-index: 20;
    width: 100%;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 60px !important;
    height: 40px;
    z-index: 999;
    display: block !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    overflow-x: auto;
    scroll-snap-type: x;
    .menu {
      justify-content: center;
      height: 100%;
      padding-top: 14px;
    }
    .title-menu-member {
      display: flex;
      flex-direction: row;
      height: 100%;
      padding-top: 9px;
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      text-transform: capitalize;
      color: #515151;
      width: 405px;
      justify-content: unset;
      .row {
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
      }
      .row:nth-child(2)::before {
        content: "|";
        color: #2222224d;
        position: absolute;
        left: 0px;
      }
      .row:nth-child(3)::before {
        content: "|";
        color: #2222224d;
        position: absolute;
        left: 0;
      }

      .mobile-menu {
        padding: unset;
      }
      .padding-left-16 {
        padding-left: unset !important;
      }
    }
  }
}
@media screen and (max-width: 405px) {
  .mobile-menu-member {
    height: 68px;
    .menu {
      padding-top: unset;
      height: 68px;
      .title-menu-member {
        text-align: center;
       padding-top: unset;
      }
      .row{
        align-items: center;

      }
      .mobile-menu{
        font-size: 14px;
      }
    }
  }
}
