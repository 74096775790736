.benefits-for-setting {
  background: #1eb2ff;
  @media screen and (max-width: 1366px) and (-webkit-device-pixel-ratio: 1) {
    .strong-quality-foundation-contain {
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
  }
  .strong-quality-foundation-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Members/backgroud-benefits.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (-webkit-device-pixel-ratio: 1.25) {
    .strong-quality-foundation-contain {
      padding-top: 20px;
      zoom: 90%;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .strong-quality-foundation-contain {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }
  .strong-quality-foundation-contain {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  .content {
    padding-top: 32px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .content-col-left {
    padding-left: 0px;
    padding-right: 16px;
  }

  .content-col-right {
    padding-left: 16px;
    padding-right: 0px;
  }
  .content-row {
    padding-bottom: 12px;
  }
  .col-image-1 {
    padding-left: 0px;
    padding-right: 8px;
  }
  .col-image-2 {
    padding-left: 8px;
    padding-right: 0px;
  }
  .image-1 {
    width: 100%;
    height: 397px;
  }
  .image-2 {
    width: 100%;
    height: 397px;
  }
  .col-left {
    padding-left: 0px;
    padding-right: 6px;
  }
  .col-right {
    padding-left: 6px;
    padding-right: 0px;
  }
  .box-backgroud {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    width: 100%;
    height: 94px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .box {
    padding-bottom: 12px;
  }
  .box-backgroud-bottom {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    width: 100%;
    height: 77px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .box-1 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .box-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #222222;
    padding-left: 6px;
  }
  .box-bottom-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 146%;
    text-align: center;
    text-transform: uppercase;
    color: #222222;
    padding-left: 6px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .benefits-for-setting {
    .strong-quality-foundation-backgroud {
      background-image: none;
      height: auto;
      margin-top: -5px;
      .strong-quality-foundation-contain {
        .title {
          padding-top: 30px;
          padding-left: 98px;
          padding-right: 98px;
        }
        .content {
          padding-left: 98px;
          padding-right: 98px;
          .content-row {
            flex-direction: column;
            align-items: center;
            .content-col-left {
              padding-bottom: 40px;
              padding-left: 0px;
              padding-right: 0px;
            }
            .content-col-right {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 80px;
              .col-image-1 {
                .image-1 {
                  height: auto;
                }
              }
              .col-image-2 {
                .image-2 {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
    .col-xl-6 {
      max-width: 100%;
    }
    .col-right {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .benefits-for-setting {
    .strong-quality-foundation-backgroud {
      background-image: none;
      height: auto;
      margin-top: -5px;
      .strong-quality-foundation-contain {
        .title {
          padding-top: 30px;
          padding-left: 70px;
          padding-right: 70px;
        }
        .content {
          padding-left: 24px;
          padding-right: 24px;
          .content-row {
            flex-direction: column;
            align-items: center;
            .content-col-left {
              padding-bottom: 40px;
              padding-left: 0px;
              padding-right: 0px;
            }
            .content-col-right {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 80px;
              .col-image-1 {
                .image-1 {
                  height: auto;
                }
              }
              .col-image-2 {
                .image-2 {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
    .col-xl-6 {
      max-width: 100%;
    }
    .col-right {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
@media screen and (max-width: 767px) {
  .benefits-for-setting {
    .strong-quality-foundation-backgroud {
      background-image: none;
      height: auto;
      margin-top: -5px;

      .strong-quality-foundation-contain {
        .title {
          padding-top: 30px;
          font-size: 38px;
          padding-left: 16px;
          padding-right: 16px;
        }
        .content {
          padding-left: 16px;
          padding-right: 16px;
          .content-row {
            flex-direction: column;
            align-items: center;
            .content-col-left {
              padding-bottom: 24px;
              padding-left: 0px;
              padding-right: 0px;
              .content-left-row {
                flex-direction: column;
                .col-left {
                  padding-left: 0px;
                  padding-right: 0px;
                }
                .col-right {
                  padding-left: 0px;
                  padding-right: 0px;
                }
              }
            }
            .content-col-right {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 30px;
              .col-image-1 {
                .image-1 {
                  height: auto;
                }
              }
              .col-image-2 {
                .image-2 {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
    .col-xl-6 {
      max-width: 100%;
    }
  }
}
