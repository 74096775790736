.contact-us {
  background: #f3f8fb;
  height: calc(100vh - 102px);
  justify-content: center;
  display: flex;
  align-items: center;
  .contact-us-content {
    padding: 0px 168px 38px 298px;
  }
  .item-contact {
    align-items: center;
  }
  .contact-us-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #222222;
    padding-bottom: 24px;
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .contact-us-content {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }

  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .contact-us-content {
      zoom: 90%;
      -moz-transform: scale(0.85);
      .col-phone-contact {
        padding-left: 45px;
      }
    }
  }
  .contact-us-contact-background {
    height: 444px;
  }
  .contact-us-contact-background-d-flex {
    display: flex;
  }
  .contact-us-contact-phone {
    padding-left: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    right: 140px;
    top: 60px;
    display: flex;
    height: auto;
    width: 500px;
    .padding-left-4 {
      width: 20px;
      height: 2px;
    }
  }
  .contact-phone-left {
    padding: 40px 0px 40px 32px;
  }
  .contruy-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #222222;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .number-phone {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #222222;
    padding-top: 12px;
  }
  .contact-phone-right {
    padding-top: 40px;
    padding-left: 40px;
  }
  .icon-phone {
    position: relative;
    padding-left: 55px;
    .icon-phone-img {
      width: 169px;
      height: 169px;
      position: absolute;
      bottom: 0px;
      left: -167px;
    }
  }
  .icon-website {
    .icon-contacts {
      width: 64px;
      height: 64px;
    }
  }
  .icon-phone-img {
    position: absolute;
    bottom: -138px;
  }
  .title-email {
    font-size: 16px;
    line-height: 120%;
    color: #ffffff;
  }
  .content-email {
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
    font-weight: 600;
  }
  .backgroud-image {
    background-image: url("../images/ContactUs/backgroud-frame-contact.webp");
    width: 870px;
    height: 481px;
  }
  .backgroud-contact-us {
    background: #1eb2ff;
  }
  .padding-top-item {
    padding-top: 32px;
  }
  .col-phone-contact {
    padding-left: 0px;
    padding-top: 49px;
  }
  .content-item {
    padding-left: 32px;
  }
  .item-2 {
    padding-top: 51px;
  }
  .item-3 {
    padding-top: 54px;
  }
  .icon-right {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
  }
  .content-address {
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
    font-weight: 600;
    padding-right: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .contact-us {
    width: 100%;
    height: auto;
    .contact-us-contact-phone-nav {
      flex: unset;
    }
    .col-sm-12 {
      flex: unset;
      padding-top: 15px;
    }
  }
  .contact-us .contact-us-content {
    padding: unset;
    padding: 0px 8px 80px 111px;
    .contact-us-title {
      padding-right: 63px;
      padding-bottom: 60px;
    }
  }
  .contact-us-container {
    margin-top: 80px;
  }

  .contact-us-contact-phone {
    margin-left: 24px;
    top: 35px !important;
  }

  .contact-us .content-item {
    padding-left: unset;
    margin-left: 25px;
  }

  .item-3 {
    margin-top: -20px;
  }
  .item-2 {
    margin-top: -20px;
  }
  .col-phone-contact {
    position: absolute;
    width: 80%;
  }
  .padding-top-12.content-address {
    padding-right: unset;
    width: 79%;
    height: auto;
  }

  .icon-website {
    margin-left: -10px;
  }

  .contact-us .contact-phone-left {
    padding: 50px 0px 70px 20px;
  }
  .contact-us .contact-phone-right {
    padding-top: 50px;
  }
  .contact-us .col-phone-contact {
    padding-left: unset;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 55px;
    padding-top: unset;
    position: unset;
  }
  .backgroud-contact-us {
    .contact-us-contact-background {
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .col-phone-contact {
    margin-right: 20em;
    margin-left: -6em;
  }
  .padding-top-12.content-address {
    width: 89%;
  }
  .contact-us .contact-us-contact-phone {
    width: 381px;
    height: auto;
    top: 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .contact-us {
    height: unset;
    padding-left: 16px;
    padding-right: 16px;
    .contact-us-container {
      padding-left: unset;
      padding-right: unset;
    }
    .contact-us-contact-phone {
      .contact-phone-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        .padding-top-item-america {
          padding-right: 10px;
        }
      }
    }
  }
  .contact-us-contact-phone {
    flex-direction: column;
    margin-top: -34em;
    height: auto !important;
    width: 95% !important;
    right: 5px !important;
    left: 10px !important;
    top: 121px !important;
    justify-content: center;
    align-items: center;
  }

  .contact-us-contact-phone .contruy-name {
    width: 100%;
  }
  .contact-us-contact-background.contact-us-contact-background-d-flex.backgroud-image {
    width: unset;
    background-image: url("../images/ContactUs/backgroud-contact-us-2.webp");
    background-size: 100% 117%;
    height: 537px;
  }
  .contact-us .contact-us-content {
    padding: unset;
  }
  .col-lg-6.col-sm-12.col-phone-contact {
    padding-top: 122px;
    position: absolute;
    width: 90%;
    padding-left: 15px;
    .item-2 {
      padding-top: 40px;
    }
    .item-3 {
      padding-top: 40px;
    }
    .icon-contacts {
      width: 48px;
      height: 48px;
    }
    .content-item {
      padding-left: 10px;
    }
  }
  .col-phone-contact {
    .item-contact {
      .icon-right {
        padding: unset;
        text-align: center;
      }
    }
  }
  .col-lg-6.col-sm-6.col-xs-12.contact-phone-right {
    padding-top: unset;
    padding-bottom: 40px;
    padding-left: unset;
    padding-right: unset;
    max-width: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    .padding-top-item {
      padding-right: 10px;
    }
  }
  .item-contact {
    padding-left: 5px;
  }

  .backgroud-contact-us {
    margin-top: 23em;
  }
  .contact-us-title {
    padding-bottom: 32px !important;
    margin-bottom: 9em;
    padding-top: 60px;
  }
  img.icon-phone-img {
    left: unset !important;
    right: 0em;
  }
}
@media screen and (max-width: 767px) {
  .contact-us {
    .icon-phone {
      position: unset;
    }
    .contact-phone-left {
      padding: unset !important;
      max-width: unset;
    }
    .contruy-name-vietnam {
      padding-top: 8px;
    }
  }
}
@media screen and (min-width: 500px) and (max-width: 665px) {
  .contact-us {
    .contact-us-contact-phone {
      left: 7px !important;
    }
  }
}
@media screen and (min-width: 350px) and (max-width: 499px) {
  .contact-us {
    .contact-us-contact-phone {
      left: 1px !important;
    }
  }
}
@media screen and (max-width: 349px) {
  .contact-us {
    .contact-us-contact-phone {
      left: 0px !important;
    }
  }
}
