.change-language {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: end;
  height: 32px;
  .container-change-language {
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
  }
  .change-language-div {
    padding-top: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
  }
  .change-language-span {
    padding-right: 12px;
  }
  .cursor {
    cursor: pointer;
    color: #A5A5A5;
  }
  .set-color-change-language {
    color: #1eb2ff;
  }
}

//change language mobile
.change-language-mobile {
  display: none;
  //   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: end;
  height: 32px;
  width: 70px;
  margin-right: 2%;
  margin-top: 0.4%;
  .container-change-language {
    width: 100%;
  }

  .set-color-change-language {
    color: #1eb2ff;
  }
  .cursor {
    cursor: pointer;
  }
}

//dropdown change language mobile
.dropbtn {
  color: #000;
  padding-right: 16px;
  padding-top: 4px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  background-color: #fff;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 50px;
  overflow: auto;
  right: -0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

.dropdown-content span {
  padding: 5px 12px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
  .change-language-span {
    padding-right: 20%;
  }
}
