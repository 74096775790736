.TMA-innovation-park {
  background: #1eb2ff;
  @media (-webkit-device-pixel-ratio: 0.9) {
    .TMA-innovation-park-contain {
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
    .title-VN {
      zoom: 90%;

      padding-top: 50px;
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .TMA-innovation-park-contain {
      zoom: 85%;
      -moz-transform: scale(0.85);
    }
    .title-VN {
      zoom: 90%;

      padding-top: 50px;
    }
  }
  .TMA-innovation-park-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .TMA-innovation-park-contain {
      padding-top: 45px;
      zoom: 90%;
      -moz-transform: scale(0.9);
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .TMA-innovation-park-contain {
      -moz-transform: scale(0.9);
      zoom: 90%;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .TMA-innovation-park-contain {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }

  .TMA-innovation-park-contain {
    padding-left: 60px;
    padding-right: 60px;
  }
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    margin-top: -84px;
    padding-top: 100px;
  }
  .title-VN {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    margin-top: -84px;
    padding-left: 259px;
    padding-right: 258px;
  }
  .title-span {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #ffffff;
    padding-top: 8px;
  }
  .content {
    padding-top: 56px;
  }
  .content-VN {
    padding-top: 36px;
  }
  .content-icon {
    padding-bottom: 8px;
    padding-top: 15px;
    img {
      width: 48px;
      height: 48px;
    }
  }
  .content-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 146%;
    color: #ffffff;
  }
  .content-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 146%;
    color: #ffffff;
    padding-top: 8px;
  }
  .image {
    padding-top: 40px;
    padding-bottom: 51px;
  }
  .image-VN {
    padding-top: 24px;
  }
  .image-col-lab-6 {
    padding-left: 0px;
    padding-right: 12px;
  }
  .image-col-lab-8 {
    padding-right: 0px;
    padding-left: 12px;
  }
  .content-letf {
    padding-left: 200px;
    padding-right: 65px;
  }
  .content-betwen {
    padding-right: 65px;
  }
  .contain-content {
    text-align: left;
    padding-left: 8px;
  }
  .image-inno-park {
    width: 100%;
    height: 238px;
  }
  .image-col-1 {
    padding-left: 0px;
    padding-right: 12px;
  }
  .image-col-2 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .image-col-3 {
    padding-left: 12px;
    padding-right: 0px;
  }
  .box-col-1 {
    padding-left: 0px;
    padding-right: 12px;
  }
  .box-col-2 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .box-col-3 {
    padding-left: 12px;
    padding-right: 0px;
  }
  .box-backgroud {
    width: 100%;
    height: 100px;
    background: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-1 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .box-2 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .none {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .TMA-innovation-park {
    .TMA-innovation-park-backgroud {
      background-image: none;
      height: auto;
      margin-top: -5px;
      .TMA-innovation-park-contain {
        padding-left: 98px;
        padding-right: 98px;
        z-index: 99;
        padding-top: 2em;
        margin-top: -8em;

        //vn
        .title-VN {
          padding-left: 0px;
          padding-right: 0px;
          padding-top: 60px;
        }
        .image-VN {
          .d-flex {
            flex-direction: column;
            align-items: center;
            .image-col-1 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-2 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-3 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 80px;
            }
            .col-xl-4 {
              max-width: 100%;
            }
          }
        }
        .content-VN {
          .d-flex {
            justify-content: center;
            .content-letf {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-betwen {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-right {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
          }
        }

        //en
        .image {
          .d-flex {
            flex-direction: column;
            align-items: center;
            .image-col-1 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-2 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-3 {
              padding-left: 0px;
              padding-right: 0px;
            }
            .col-xl-4 {
              max-width: 100%;
            }
          }
        }

        .content {
          .d-flex {
            justify-content: center;
            .content-letf {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-betwen {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-right {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .TMA-innovation-park .image-inno-park {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .TMA-innovation-park {
    .TMA-innovation-park-backgroud {
      background-image: none;
      height: auto;
      margin-top: -5px;
      .TMA-innovation-park-contain {
        padding-left: 24px;
        padding-right: 24px;
        z-index: 99;
        margin-top: -8em;
        padding-top: 2em;

        .title {
          padding-top: 80px;
        }
        //VN
        .title-VN {
          padding-left: 0px;
          padding-right: 0px;
          padding-top: 60px;
        }
        .image-VN {
          .d-flex {
            flex-direction: column;
            align-items: center;
            .image-col-1 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-2 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-3 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 80px;
            }
            .col-xl-4 {
              max-width: 100%;
            }
          }
        }
        .content-VN {
          .d-flex {
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: row;
            .content-letf {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-betwen {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-right {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .box-col-1 {
              width: 354px;
            }
            .box-col-2 {
              width: 354px;
            }
            .box-col-3 {
              width: 354px;
              padding-top: 24px;
            }
          }
        }

        //EN
        .image {
          .d-flex {
            flex-direction: column;
            align-items: center;
            .image-col-1 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-2 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-3 {
              padding-left: 0px;
              padding-right: 0px;
            }
            .col-xl-4 {
              max-width: 100%;
            }
          }
        }
        .content {
          .d-flex {
            justify-content: center;
            .content-letf {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-betwen {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-right {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .TMA-innovation-park .image-inno-park {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .TMA-innovation-park {
    .TMA-innovation-park-backgroud {
      background-image: none;
      height: auto;
      margin-top: -5px;
      .TMA-innovation-park-contain {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 2em;
        margin-top: -8em;

        z-index: 99;
        //VN
        .title-VN {
          padding-left: 0px;
          padding-right: 0px;
          padding-top: 60px;
          font-size: 38px;
        }
        .title {
          font-family: "IBM Plex Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 38px;
          line-height: 120%;
          text-align: center;
          text-transform: capitalize;
          padding-top: 60px;
        }
        .title-span {
          font-family: "IBM Plex Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          text-align: center;
        }

        .image-VN {
          .d-flex {
            flex-direction: column;
            align-items: center;
            .image-col-1 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-2 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-3 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 80px;
            }
            .col-xl-4 {
              max-width: 100%;
            }
          }
        }
        .content-VN {
          .d-flex {
            justify-content: center;
            flex-direction: column;
            .content-letf {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-betwen {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .content-right {
              padding-left: 0;
              flex-direction: column;
              align-items: center;
              .contain-content {
                text-align: center;
              }
            }
            .box-col-1 {
              width: 100%;
              padding-left: 0px;
              padding-right: 0px;
            }
            .box-col-2 {
              width: 100%;
              padding-top: 24px;
              padding-left: 0px;
              padding-right: 0px;
            }
            .box-col-3 {
              width: 100%;
              padding-top: 24px;
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
        //EN
        .image {
          .d-flex {
            flex-direction: column;
            align-items: center;
            .image-col-1 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-2 {
              padding-left: 0px;
              padding-right: 0px;
              padding-bottom: 24px;
            }
            .image-col-3 {
              padding-left: 0px;
              padding-right: 0px;
            }
            .col-xl-4 {
              max-width: 100%;
            }
          }
        }
        .content {
          .d-flex {
            justify-content: center;
            flex-direction: column;
            .content-letf {
              padding-left: 0;
              padding-right: 20px;
              flex-direction: row;
              align-items: center;
              padding-bottom: 36px;
              .contain-content {
                .content-text {
                  font-weight: 400;
                  line-height: 146%;
                }
                .content-title {
                  font-weight: 700;
                  font-size: 25px;
                  line-height: 146%;
                }
              }
            }
            .content-betwen {
              padding-left: 0;
              padding-right: 0;
              flex-direction: row;
              align-items: center;
              padding-bottom: 36px;
              .contain-content {
                .content-text {
                  font-weight: 400;
                  line-height: 146%;
                }
                .content-title {
                  font-weight: 700;
                  font-size: 25px;
                  line-height: 146%;
                }
              }
            }
            .content-right {
              padding-left: 0;
              flex-direction: row;
              align-items: center;
              padding-bottom: 20px;
              padding-right: 26px;
              .contain-content {
                .content-text {
                  font-weight: 400;
                  line-height: 146%;
                }
                .content-title {
                  font-weight: 700;
                  font-size: 25px;
                  line-height: 146%;
                }
              }
            }
          }
        }
      }
    }
  }
  .TMA-innovation-park .image-inno-park {
    width: 100%;
    height: auto;
  }
}
