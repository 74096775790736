@media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
  .history-page {
    .history-content {
      zoom: 90%;
      -moz-transform: scale(0.85);
    }
  }
}
.history-page {
  background: #1eb2ff;
  .history-page-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
  }
  .history-page-backgroud-img {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 100%;
  }

  .history-img {
    width: 100%;
  }
  .history-page-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
  }
  .history-page-content {
    padding-top: 0px;
    width: 100%;
  }
  .history-content {
    display: flex;
    padding-left: 60px;
    padding-top: 56px;
    position: relative;
  }
  .history-content-img {
    width: 312px;
    height: 366px;
  }
  .history-line {
    width: 100%;
    padding-left: 48px;
  }
  .history-content-item {
    width: 100%;
    position: relative;
  }

  .history-circle {
    border-radius: 50%;
    width: 14px;
    background: #f8f9fa;
    height: 14px;
    position: absolute;
    top: -7px;
  }
  .circle-2 {
    left: 35%;
  }
  .circle-3 {
    left: 71%;
  }
  .history-line-year {
    display: flex;
    padding-bottom: 30px;
    position: relative;
  }
  .history-box-year {
    width: 108px;
    height: 44px;
    background: #ffff;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #222222;
  }
  .box-2 {
    position: absolute;
    left: 35%;
  }
  .box-3 {
    position: absolute;
    left: 71%;
  }
  .history-text {
    border-top: 1px solid #ffffff;
    display: flex;
    padding-top: 38px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
    position: relative;
  }
  .history-text-ul {
    padding-left: 20px;
  }
  .ul-1 {
    width: 300px;
  }
  .ul-2 {
    position: absolute;
    left: 35%;
    width: 240px;
  }
  .ul-2-VN {
    position: absolute;
    left: 35%;
    width: 272px;
  }
  .ul-3 {
    position: absolute;
    left: 71%;
    padding-right: 60px;
  }
  .ul-3-VN {
    position: absolute;
    left: 71%;
    width: 242px;
    padding-right: 60px;
  }
  .history-text-ul > li {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 1920px) {
  .history-page {
    .history-page-content {
      // padding-top: 11%;
    }
    .ul-3-VN {
      width: unset;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .history-page {
    .history-content {
      padding-left: 98px !important;
      padding-right: 98px !important;
      .history-page-title {
        padding-left: 98px;
        padding-right: 98px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1365px) {
  .history-page {
    margin-bottom: -10px;
    width: 100%;
    height: auto;
    background: #1eb2ff;
    .history-img {
      display: none;
    }
    .box {
      padding-left: 2em;
    }
  }
  .history-page .history-page-backgroud {
    height: auto;
  }
  .history-page .history-line {
    padding-left: 10px;
    padding-top: 4em;
  }
  .history-page .history-page-content {
    padding-top: unset !important;
  }
  .history-content {
    margin-top: -56px;
    margin-left: -60px;
    margin-right: -53.75px;
  }
  .history-text-ul {
    font-size: 18px;
  }
  .history-page-title {
    font-size: 38px;
    padding: 80px 83px 00px 82px;
  }

  .history-content {
    margin-top: 60px;
    padding-top: unset !important;
    padding-left: 24px !important;
    padding-right: 25px !important;
    flex-direction: column;
    margin-left: unset;
    margin-right: unset;
  }

  .history-content-img {
    // margin: 60px 5.12% 40px 5.26%;
    width: 100% !important;
    height: auto !important;

    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
  }
  .history-page .ul-3-VN {
    padding-top: 27em;
    margin-left: 46px;
  }
  .history-box-year {
    font-size: 20px !important;
    min-width: 165px;
  }
  .history-circle {
    margin-left: -8px;
    margin-top: -6px;
    width: 18px !important;
    height: 18px !important;
  }

  .history-page .history-line-year {
    .box-1 {
      padding-bottom: 70px;
    }
  }

  .history-page .history-content-item {
    width: 55%;
    // padding-top: 40px;
    position: relative;
  }
  .history-tow-page .ul-3-VN {
    margin-left: -3px;
    margin-top: 8em !important;
  }
  .history-page .history-page .ul-2-VN {
    margin-top: 11em;
    margin-left: 36px;
  }
  .history-line-year {
    .box-2 {
      padding-top: 12em;
      margin-left: -35%;
    }
  }

  .history-circle.circle-2 {
    left: 0;
    margin-top: 12em;
  }
  .history-page .history-line-year {
    .box-3 {
      padding-top: 29em;
      margin-left: -71%;
    }
  }

  .history-circle.circle-3 {
    left: 0;
    margin-top: 29em;
  }

  .history-page .history-text {
    margin-top: -121px;

    border-top: none !important;
    border-left: 2px solid;
    height: 980px;
    line-height: 150%;
  }
  .history-page .history-text {
    .ul-1 {
      margin-left: 32px;
      min-width: 150%;
      padding-top: 0em;
      line-height: 150%;
    }
    .li-3 {
      margin-bottom: 36px;
    }

    .ul-2 {
      line-height: 150%;
      min-width: 130%;
      left: unset !important;
      margin-top: 191px;
      margin-left: 33px;
    }
    .ul-2-VN {
      line-height: 150%;
      left: unset;
      min-width: 150%;
      padding-top: 193px;
      padding-left: 3em;
    }

    .ul-3 {
      line-height: 150%;
      min-width: 100%;
      margin-top: 463px;
      left: unset !important;
      margin-left: 33px;
    }
    .ul-3-VN {
      line-height: 150%;
      min-width: 132%;
      padding-top: 26em;
      left: unset !important;
      margin-left: 32px;
    }
  }
}
@media screen and (max-width: 767px) {
  .history-page {
    margin-bottom: -10px;
    width: 100%;
    height: auto;
    background: #1eb2ff;
    .history-img {
      display: none;
    }
    .history-page-title {
      padding-left: 16px;
      padding-right: 16px;
      font-size: 38px;
      padding-top: 60px;
    }
    .history-page-backgroud {
      width: 100%;
      height: auto;
    }
    .history-content {
      padding-left: unset;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
    }
    .history-content-img {
      width: 100%;
      height: auto;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 20px;
    }
    .history-line {
      padding-left: unset;
    }
    .history-line-year {
      .box-1 {
        margin-top: 1em;
        margin-left: 3em;
      }
      .box-2 {
        margin-left: 3em;
        left: unset;
        margin-top: 12em;
      }
      .box-3 {
        left: unset;
        margin-top: 27em;
        margin-left: 3em;
      }
    }

    .history-text {
      margin-left: 25px;
    }
    .history-content-item {
      .history-circle {
        margin-left: 18px;
        z-index: 1;
      }
      .circle-2 {
        left: unset;
        margin-top: 11em;
      }
      .circle-3 {
        left: unset;
        margin-top: 26em;
      }
    }
    .history-text {
      font-size: 18px;
      height: 715px;
      margin-top: -3em;
      border-top: none;
      border-left: 1px solid;
      .ul-1 {
        margin-left: 23px;
        width: 90%;
      }
      .ul-2 {
        padding-top: 178px;
        left: unset;
        margin-left: 23px;
        width: 90%;
      }
      .ul-2-VN {
        left: 0;
        margin-top: 178px;
        width: 90%;
        margin-left: 23px;
      }
      .ul-3-VN {
        left: 0;
        width: 90%;
        padding-top: 418px;
        margin-left: 23px;
      }
      .ul-3 {
        left: unset;
        padding-top: 420px;
        margin-left: 23px;
        width: 90%;
      }
    }
  }
}
@media screen and (min-width: 448px) and (max-width: 542px) {
  .history-page {
    .history-content-item {
      width: 89%;
    }
    .history-line {
      .box-2 {
        margin-top: 181px;
      }
      .circle-2 {
        margin-top: 161px;
      }
      .ul-2-VN {
        left: 0;
        margin-top: 171px;
        width: 90%;
        margin-left: 23px;
      }
      .ul-2 {
        margin-top: -8px;
      }
      .box-3 {
        margin-top: 406px;
      }

      .circle-3 {
        margin-top: 385px;
      }
      .ul-3 {
        padding-top: 395px;
      }
      .history-text {
        font-size: 15px;
        line-height: 120%;
        padding-top: 25px;
        height: 646px;
        .ul-3-VN {
          padding-top: 399px;
        }
      }
    }
  }
}
@media screen and (min-width: 361px) and (max-width: 447px) {
  .history-page {
    .history-content-item {
      width: 89%;
    }
    .history-line {
      .box-2 {
        margin-top: 190px;
      }
      .circle-2 {
        margin-top: 172px;
      }
      .ul-2-VN {
        left: 0;
        margin-top: 171px;
        width: 90%;
        margin-left: 23px;
      }
      .ul-2 {
        margin-top: 4px;
      }
      .box-3 {
        margin-top: 420px;
      }

      .circle-3 {
        margin-top: 400px;
      }
      .ul-3 {
        padding-top: 411px;
      }
      .history-text {
        font-size: 15px;
        line-height: 120%;
        padding-top: 25px;
        height: 678px;
        .ul-3 {
          padding-right: unset;
        }
        .ul-3-VN {
          padding-top: 399px;
          padding-right: unset;
        }
      }
    }
  }
}
@media screen and (min-width: 349px) and (max-width: 360px) {
  .history-page {
    .history-content-item {
      width: 89%;
    }
    .history-line {
      .box-2 {
        margin-top: 190px;
      }
      .circle-2 {
        margin-top: 172px;
      }
      .ul-2-VN {
        left: 0;
        margin-top: 176px;
        width: 90%;
        margin-left: 23px;
      }
      .ul-2 {
        margin-top: 4px;
      }
      .box-3 {
        margin-top: 420px;
      }

      .circle-3 {
        margin-top: 400px;
      }
      .ul-3 {
        padding-top: 411px;
      }
      .history-text {
        font-size: 15px;
        line-height: 120%;
        padding-top: 25px;
        height: 703px;
        .ul-3 {
          padding-right: unset;
        }
        .ul-3-VN {
          padding-top: 419px;
          padding-right: unset;
        }
      }
    }
  }
}
@media screen and (max-width: 348px) {
  .history-page {
    .history-content-item {
      width: 95%;
    }
    .history-line {
      .box-2 {
        margin-top: 204px;
      }
      .box-3 {
        margin-top: 438px;
      }

      .circle-2 {
        margin-top: 183px;
      }
      .circle-3 {
        margin-top: 418px;
      }
      .history-text {
        font-size: 15px;
        line-height: 120%;
        padding-top: 25px;
        height: 746px;
        .ul-2 {
          padding-top: 199px;
        }
        .ul-2-VN {
          margin-top: 195px;
        }
        .ul-3 {
          padding-top: 425px;
        }
        .ul-3-VN {
          padding-top: 435px;
          padding-right: 33px;
        }
      }
    }
  }
}
@media screen and (max-width: 297px) {
  .history-page {
    .history-line {
      .box-2 {
        margin-top: 13em;
      }
      .box-3 {
        margin-top: 448px;
      }

      .circle-2 {
        margin-top: 190px;
      }
      .circle-3 {
        margin-top: 426px;
      }
      .history-text {
        font-size: 15px;
        line-height: 120%;
        padding-top: 25px;
        height: 788px;
        .ul-2 {
          padding-top: 199px;
        }
        .ul-2-VN {
          margin-top: 200px;
        }
        .ul-3 {
          padding-top: 442px;
        }
        .ul-3-VN {
          padding-top: 442px;
        }
      }
    }
  }
}
