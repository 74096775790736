.research-center {
  flex-wrap: wrap;
  height: calc(100vh - 102px);
  justify-content: center;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 0.8) {
    .research-center-content {
      zoom: 85%;
      -moz-transform: scale(0.85);
      padding-left: 140px !important;
    }
    .image-members-1-top {
      -moz-transform: scale(0.7);
      zoom: 70%;
      width: 100%;
    }
  }
  @media (-webkit-device-pixel-ratio: 0.9) {
    .research-center-content {
      zoom: 85%;
      -moz-transform: scale(0.85);
      padding-left: 140px !important;
    }
    .image-members-1-top {
      -moz-transform: scale(0.75);
      zoom: 90%;
      width: 100%;
      margin-right: 20%;
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .research-center-content {
      zoom: 85%;
      -moz-transform: scale(0.85);
      padding-left: 140px !important;
    }
    .image-members-1-top {
      -moz-transform: scale(0.75);
      zoom: 90%;
      width: 100%;
      margin-right: 20%;
    }
  }
  .research-center-content {
    padding-left: 60px;
    padding-right: 10px;
    margin-top: -150px;
  }
  .research-center-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    color: #222222;
    padding-top: 20px;
  }
  @media (-webkit-min-device-pixel-ratio: 1.01) and (-webkit-max-device-pixel-ratio: 1.19) {
    .image-members-1-top {
      top: 110px;
      -moz-transform: scale(0.85);
      zoom: 85%;
    }
  }
  @media (-webkit-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.29) {
    .image-members-1-top {
      zoom: 19%;
      -moz-transform: scale(0.19);
      left: -25em;
      padding-bottom: 14em;
      .image-top {
        padding-bottom: 35px;
      }
      .image-bottom {
        padding-bottom: 35px;
      }
    }
  }
  @media (-webkit-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.24) {
    .research-center-content {
      -moz-transform: scale(0.85);
      zoom: 85%;
      left: 45px !important;
      top: -30px;
    }
    .image-members-1-top {
      -moz-transform: scale(0.85);
      zoom: 85%;
      right: 120px !important;
      top: -65px !important;
    }
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .research-center-content {
      padding-top: 50px;
      -moz-transform: scale(0.8);
      zoom: 80%;
      left: 65px;
    }
    .image-members-1-top {
      -moz-transform: scale(0.8);
      zoom: 80%;
      left: -100px;
      top: -65px !important;
    }
  }
  .members-us-img {
    padding-right: 0 !important;
    background: #1eb2ff;
    height: 100vh;
    // width: 100vh;
    align-items: center;
    display: flex;
    // position: relative;
  }
  .image-members-1-top {
    position: relative;
    right: 60px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    top: -80px;
    padding-top: 78px;
  }
  .research-center-box {
    padding-top: 36px;
    padding-right: 10px;
  }
  .about-us-backroud-frame {
    position: absolute;
    right: 0px;
    top: 0px;
    img {
      width: 222px;
      height: 370px;
    }
  }
  .experienced-in-large-right {
    padding-left: 120px;
    padding-right: 0px;
  }
  .image-bottom {
    padding-top: 16px;
    padding-left: 0px;
    padding-right: 0px;
    img {
      width: 100%;
      height: 245px;
    }
  }
  .image-top {
    padding-left: 0px;
    padding-right: 0px;
    img {
      width: 100%;
      height: 245px;
    }
  }
  .box-col {
    padding-left: 0px;
  }
  .box-backgroud {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    height: 162px;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #222222;
  }
  .box-1 {
    padding-bottom: 16px;
  }
  .box-3 {
    padding-bottom: 16px;
  }
  .box-text-1 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .box-text-2 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .box-text-3 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .box-text-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .research-center-backgroud {
    position: fixed;
    left: 0px;
    top: 32px;
    display: none;
    .backgroud-image {
      height: 100vh;
    }
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .research-center {
    .image-bottom {
      img {
        width: 100%;
        height: auto;
      }
    }
    .image-top {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .research-center .backgroud-image {
    display: none;
  }
  .research-center {
    display: block !important;
    margin-top: 13em;
    height: auto;
    .research-center-content {
      max-width: 100%;
      padding-bottom: 160px;
      padding-left: 98px;
      padding-right: 98px;
      .research-center-title {
        text-align: center;
        padding-top: 10px;
      }
      .research-center-box {
        padding-right: 0px;
        padding-top: 60px;
        .box-col {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
    .experienced-in-large-right {
      max-width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        height: auto;
        .d-flex {
          width: 100%;

          .image-members-1-top {
            width: 100%;
            right: 0;
            top: -12em;
            padding-left: 98px;
            padding-right: 98px;
            .image-top {
              img {
                width: 100%;
                height: auto;
              }
            }
            .image-bottom {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .research-center .backgroud-image {
    display: none;
  }
  .research-center {
    display: block !important;
    margin-top: 10em;
    height: auto;
    .research-center-content {
      max-width: 100%;
      padding-bottom: 160px;
      padding-left: 24px;
      padding-right: 24px;
      .research-center-title {
        text-align: center;
        padding-top: 60px;
      }
      .research-center-box {
        padding-right: 0px;
        padding-top: 60px;

        .box-col {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
    .experienced-in-large-right {
      max-width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        height: auto;
        .d-flex {
          width: 100%;

          .image-members-1-top {
            width: 100%;
            right: 0;
            top: -12em;
            padding-left: 24px;
            padding-right: 24px;
            .image-top {
              img {
                width: 100%;
                height: auto;
              }
            }
            .image-bottom {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .research-center .backgroud-image {
    display: none;
  }
  .research-center {
    display: block !important;
    margin-top: 10em;
    height: auto;
    .research-center-content {
      max-width: 100%;
      padding-bottom: 160px;
      padding-left: 16px;
      padding-right: 16px;
      .research-center-title {
        text-align: center;
        padding-top: 60px;
      }
      .research-center-box {
        padding-right: 0px;
        flex-direction: column;
        padding-top: 60px;

        .box-col {
          max-width: 100%;
          padding-right: 0px;
          padding-bottom: 16px;
        }
      }
    }
    .experienced-in-large-right {
      max-width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      .members-us-img {
        height: auto;
        .d-flex {
          width: 100%;

          .image-members-1-top {
            width: 100%;
            right: 0;
            top: -12em;
            padding-left: 16px;
            padding-right: 16px;
            .image-top {
              img {
                width: 100%;
                height: auto;
              }
            }
            .image-bottom {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
