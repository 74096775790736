.contain-banner {
	text-align: center;
	.banner-logo-tma-img {
		// width: 97px;
		height: 75px;
	}
	.padding-left-0 {
		padding-left: 0px !important;
	}
	.padding-right-0 {
		padding-right: 0px !important;
	}
	.contain-banner-backgound {
		width: 100%;
		height: calc(100vh - 102px);
		background: #1eb2ff;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url('../images/banner/backgound-banner-new.webp');
		background-position-x: center;
	}
	.banner-title {
		font-weight: 700;
		font-size: 56px;
		line-height: 140%;
		text-align: center;
		color: #ffffff;
		padding-top: 32px;
	}
	@media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
		.banner-logo-backgroud {
			-moz-transform: scale(0.8);
			zoom: 80%;
		}
	}
	@media (-webkit-device-pixel-ratio: 0.8) {
		.banner_form {
			top: 90px;
			zoom: 90%;
			left: 30%;
			-moz-transform: scale(0.7);
		}
	}
	@media (-webkit-device-pixel-ratio: 0.9) {
		.banner_form {
			top: 90px;
			zoom: 80%;
			left: 30%;
			-moz-transform: scale(0.7);
		}
	}
	@media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
		.banner_form {
			top: 90px;
			zoom: 70%;
			left: 25%;
			-moz-transform: scale(0.7);
		}
	}
	.banner-title-nav {
		font-weight: 400;
		font-size: 28px;
		line-height: 140%;
		text-align: center;
		color: rgba(255, 255, 255, 0.9);
	}

	.banner-title-slider-2 {
		font-weight: 700;
		font-size: 56px;
		line-height: 140%;
		text-align: center;
		color: #ffffff;
		padding-top: 87px;
	}
	.banner-title-nav-2 {
		font-weight: 700;
		font-size: 56px;
		line-height: 140%;
		text-align: center;
		color: #ffffff;
	}
	.banner-title-slider-3 {
		font-weight: 700;
		font-size: 56px;
		line-height: 140%;
		text-align: center;
		color: #ffffff;
		padding-top: 42px;
	}
	.banner-contain-logo-three {
		padding-top: 64px;
		.banner-bg-left {
			height: 267px;
			width: 100%;
		}
		.banner-bg-right {
			height: 269px;
			width: 194px;
		}
	}
	.banner-logo-three {
		background: #ffffff;
		box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
		height: 267px;
		flex-wrap: wrap;
	}
	.map-slider-3 {
		padding-top: 10px;
	}
	.logo-tma-three {
		padding-top: 80px;
	}
	.group-logo-banner {
		flex-wrap: wrap;
		.logo-banner-1 {
			flex: 1 0 50px;
		}
		.group-logo-banner-bot {
			flex: 2 0 50px;
			display: flex;
			align-items: center;
			.logo-1 {
				width: 194px;
				height: 89px;
			}
			.logo-2 {
				height: 110px;
			}
			.logo-3 {
				width: 202px;
				height: 89px;
			}
			.logo-tma-three-nav {
				flex: 1 0 50px;
			}
			width: 100%;
		}
		.logo-tma-three-nav {
			object-fit: none;
			height: 80%;
		}
	}
	.banner-bg-right {
		text-align: end;
	}
	.icon-banner-slider {
		padding-top: 21px;
		position: absolute;
		bottom: 20px;
		left: 50%;
	}
	.icon-banner-slider-2 {
		padding-top: 90px;
	}
	.icon-banner-slider-3 {
		padding-top: 20px;
	}
	.mySlider {
		display: block;
	}
	.slick-slide img {
		display: initial;
	}
	.banner-slider2-engineer {
		justify-content: center;
		font-size: 28px;
		padding-top: 12px;
		color: #222222;
		> b {
			padding-right: 5px;
		}
	}
	.banner-slider2-backgoud-bg {
		margin-left: -111px;
	}

	// logo
	.banner-logo {
		width: 100%;
		padding-top: 48px;
		padding-left: 98px;
		padding-right: 98px;
	}
	.banner-logo-backgroud {
		background: #ffffff;
		position: relative;
		height: 267px;
	}
	.backgroud-fame-left {
		position: absolute;
		.backgroud-fame-left-img-mobile {
			display: none;
		}
	}
	.backgroud-fame-left-img {
		width: 227px;
	}
	.backgroud-fame-right {
		position: absolute;
		right: 0px;
	}
	.backgroud-fame-right-img {
		width: 227px;
	}
	.backgroud-fame-right-img-mobile {
		display: none;
	}
	.logo-tma {
		position: absolute;
		top: 73px;
		left: 200px;
	}
	.logo-tma-BD {
		position: absolute;
	    top: 40px;
    left: 450px;
	}
	.logo-tma-inno {
		position: absolute;
		top: 73px;
		right: 200px;
	}
	.banner-logo-img {
		width: 194px;
	}
	.banner-logo-img-bd {
		width: 230px;
	}
	.banner-logo-img-inno {
		width: 202px;
	}
}

@media screen and (max-width: 1365px) {
	.contain-banner {
		width: 100%;
		height: auto;
		.logo-tma-three {
			padding-top: 42px;
		}
		// logo
		.banner-logo {
			width: 100%;
			padding-top: 24px;
			padding-left: 98px;
			padding-right: 98px;
			padding-bottom: 80px;
		}
		.banner-logo-backgroud {
			height: 194px;
		}
		.backgroud-fame-left-img {
			width: 165px;
		}
		.backgroud-fame-right-img {
			width: 165px;
		}
		.backgroud-fame-right-img {
			width: 140px;
			height: 194px;
		}
		.logo-tma {
			position: absolute;
			top: 42px;
			left: 15%;
		}
		.logo-tma-BD {
			position: absolute;
			top: 10px;
			left: 39%;
		}
		.logo-tma-inno {
			position: absolute;
			top: 42px;
			right: 15%;
		}
		.banner-logo-img {
			width: 194px;
			height: 89px;
		}
		.banner-logo-img-inno {
			width: 202px;
			height: 89px;
		}
	}

	.contain-banner .contain-banner-backgound {
		background-image: url('../images/banner/backgound-banner-new.webp');
		background-position-x: center;
		background-size: 2652px 1141px;
		width: 100%;
		height: auto;
		.banner-contain-logo-three {
			padding-bottom: 80px;
		}
	}

	.contain-banner .backgroud-image {
		display: none;
	}
	.banner-logo-tma-img {
		margin-bottom: 25px;
		margin-top: 8em;
	}

	.group-logo-banner-bot {
		margin-right: 133px;
		min-width: 100%;
		height: 194px;
		margin-top: -43px;
	}
	.col-lg-2.banner-bg.padding-right-0.padding-left-0.banner-bg-left.col {
		position: absolute;
		left: 0;
		margin-left: 3em;
	}
	.col-lg-2.banner-bg.banner-bg-right.padding-right-0.padding-left-0.col {
		position: absolute;
	}
	.banner-bg-left {
		width: 165px !important;
		height: 194px !important;
	}

	.banner-bg-right {
		width: 140px !important;
		height: 194px !important;
		position: absolute;
		right: 24px;
	}
	.banner-logo-three.d-flex.margin-left--5.margin-right-5 {
		height: auto;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
		margin: -27px 32px 0px 32px;
		justify-content: center;
	}

	img.logo-banner-1.logo-tma-three-nav {
		min-width: 194px;
		min-height: 89px;
	}

	img.col-xs-6.logo-tma-three-nav {
		min-width: 160px;
		min-height: 110px;
	}
	.logo-tma-three-nav {
		z-index: 1;
	}
}
@media screen and (max-width: 1140px) {
	.contain-banner {
		// logo
		.banner-logo {
			padding-left: 32px;
			padding-right: 32px;
		}
		.logo-tma {
			left: 10%;
		}
		.logo-tma-BD {
			left: 40%;
		}
		.logo-tma-inno {
			right: 10%;
		}
	}
}
@media screen and (max-width: 900px) {
	.contain-banner {
		// logo
		.logo-tma {
			left: 3%;
		}
		.logo-tma-BD {
			left: 35%;
		}
		.logo-tma-inno {
			right: 3%;
		}
	}
}
@media screen and (max-width: 767px) {
	.contain-banner {
		width: 100%;
		height: auto;
		padding-top: 4em;
		.title-banner {
			margin-bottom: unset;
			display: flex;
			flex-direction: column;
		}
		.contain-banner-backgound {
			width: 100%;
			height: auto;
			.container {
				padding-left: 16px;
				padding-right: 16px;
			}
			.banner-logo-tma {
				padding-top: 59px;
			}
			.banner-title {
				font-size: 38px;
				padding-bottom: 18px;
			}
			.banner-title-nav {
				font-size: 18px;
			}
		}
		.logo-tma-three {
			display: flex;
		}
		.banner-logo-three {
			height: auto;
			margin-left: unset;
			margin-right: unset;
		}
		.group-logo-banner-bot {
			flex-wrap: wrap;
		}
		.group-logo-banner-bot {
			align-items: unset !important;
			.logo-tma-three-nav {
				height: auto;
				position: absolute;
			}
			.logo-1 {
				width: 100% !important;

				height: auto !important;
				object-fit: unset;
				padding-top: 3em;
			}
			.logo-2 {
				width: 100% !important;
				height: auto !important;
				object-fit: unset;
				padding-top: 11em;
				margin-left: -8em;
			}
			.logo-3 {
				width: 100% !important;
				height: auto !important;
				object-fit: unset;
				padding-top: 12em;
				margin-left: 10em;
			}
		}
		.banner-bg-left {
			height: auto !important;
			width: 100%;
			margin-top: 71px;
		}
		.banner-bg-right {
			width: 100% !important;
			height: auto !important;
			margin-top: -13px;
		}
		.banner-logo-tma-img {
			margin-bottom: 25px;
			margin-top: 1em;
		}
		//logo
		.banner-logo-backgroud {
			height: 257px;
		}
		.backgroud-fame-right-img {
			display: none;
		}
		.backgroud-fame-right-img-mobile {
			display: block;
			width: 126px;
		}
		.backgroud-fame-left {
			bottom: 0px;
			.backgroud-fame-left-img {
				display: none;
			}
			.backgroud-fame-left-img-mobile {
				display: block;
				width: 126px;
			}
		}
		.logo-tma {
			left: 40%;
		}
		.logo-tma-BD {
			left: 28%;
			top: 115px;
		}
		.logo-tma-inno {
			right: 25%;
			top: 135px;
		}
		.banner-logo-img-bd {
			width: 162px;
		}
		.banner-logo-img {
			width: 136px;
			height: 63px;
		}
		.banner-logo-img-inno {
			width: 142px;
			height: 62px;
		}
		.banner-logo {
			padding-left: 16px;
			padding-right: 16px;
		}
	}
	.contain-banner .contain-banner-backgound {
		background-image: url('../images/banner/backgound-banner-new.webp');
		background-position-x: center;
		background-size: 1572px 716px;
		top: -2em;
		position: relative;
	}
	.banner-contain-logo-three {
		position: relative;
		padding-top: 24px !important;
		padding-bottom: 65px;
		.container-logo {
			padding-top: unset;
		}
	}
}
@media screen and (max-width: 700px) {
	.contain-banner {
		.logo-tma {
			left: 40%;
		}
		.logo-tma-BD {
			left: 25%;
		}
		.logo-tma-inno {
			right: 25%;
		}
	}
}
@media screen and (max-width: 630px) {
	.contain-banner {
		.contain-banner-backgound {
			.group-logo-banner-bot {
				.logo-2 {
					margin-left: -7em;
				}
				.logo-3 {
					margin-left: 8em;
					padding-bottom: 11em;
				}
			}
		}
		.logo-tma {
			left: 40%;
		}
		.logo-tma-BD {
			left: 20%;
		}
		.logo-tma-inno {
			right: 20%;
		}
	}
}
@media screen and (max-width: 550px) {
	.contain-banner {
		.contain-banner-backgound {
			.group-logo-banner-bot {
				.logo-2 {
					margin-left: -5em;
					padding-top: 10em;
				}
				.logo-3 {
					margin-left: 7em;
					padding-top: 10em;
				}
			}
		}
	}
}
@media screen and (min-width: 571px) and (max-width: 629px) {
	.contain-banner {
		.logo-tma {
			left: 37%;
		}
		.logo-tma-BD {
			left: 15%;
		}
		.logo-tma-inno {
			right: 15%;
		}
	}
}
@media screen and (min-width: 531px) and (max-width: 570px) {
	.contain-banner {
		.logo-tma {
			left: 37%;
		}
		.logo-tma-BD {
			left: 12%;
		}
		.logo-tma-inno {
			right: 12%;
		}
	}
}
@media screen and (max-width: 530px) {
	.contain-banner {
		.logo-tma {
			left: 37%;
		}
		.logo-tma-BD {
			left: 12%;
		}
		.logo-tma-inno {
			right: 12%;
		}
	}
}

@media screen and (max-width: 470px) {
	.contain-banner {
		.logo-tma {
			left: 34%;
		}
		.logo-tma-BD {
			left: 13%;
		}
		.logo-tma-inno {
			right: 13%;
		}
	}
}
@media screen and (max-width: 450px) {
	.contain-banner {
		.contain-banner-backgound {
			.group-logo-banner-bot {
				.logo-2 {
					margin-left: -5em;
					padding-top: 9em;
				}
				.logo-3 {
					margin-left: 6em;
					padding-top: 9em;
				}
			}
		}
	}
}
@media screen and (min-width: 426px) and (max-width: 469px) {
	.contain-banner {
		.logo-tma {
			left: 34%;
		}
		.logo-tma-BD {
			left: 8%;
		}
		.logo-tma-inno {
			right: 8%;
		}
	}
}
@media screen and (max-width: 425px) {
	.contain-banner {
		.banner-logo {
			padding-left: 0px;
			padding-right: 0px;
		}
		.logo-tma {
			left: 30%;
		}
		.logo-tma-BD {
			left: 10%;
		}
		.logo-tma-inno {
			right: 15%;
		}
		.banner-logo-img-bd {
			width: 162px;
		}
	}
}
@media screen and (max-width: 346px) {
	.contain-banner {
		.banner-title {
			font-size: 34px !important;
		}
		.banner-title-nav {
			font-size: 16px !important;
		}
	}
}
@media screen and (max-width: 400px) {
	.contain-banner {
		.banner-logo {
			padding-left: 0px;
			padding-right: 0px;
		}
		.logo-tma {
			left: 28%;
		}
		.logo-tma-BD {
			left: 5%;
		}
		.logo-tma-inno {
			right: 10%;
		}
	}
}
@media screen and (max-width: 350px) {
	.contain-banner {
		.logo-tma-BD {
			left: 0%;
		}
		.logo-tma-inno {
			right: 3%;
		}
	}
	.contain-banner .contain-banner-backgound {
		background-size: 35em 51em;
		background-image: url('../images/banner/backgound-banner-new-mobile.webp');
		background-position-x: center;
		top: -2em;
		position: relative;
	}
}
