.desktop-menu {
  display: block;
}
.mobile-menu {
  display: none;
}
.title-menu {
  list-style-type: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  padding-top: 12px;
  justify-content: end;
  text-transform: uppercase;
  padding-right: 0px;
}
.row {
  margin-left: unset;
  margin-right: unset;
}
.menu-tag-a {
  text-decoration: none;
  color: #222222;
  //   background-color: #ffffff;
  padding-left: unset;
  padding-right: unset;
  width: 100%;
}
.menu-tag-a:hover {
  text-decoration: none;
  color: #222222;
}
.menu-scroll {
  width: 100%;
  // height: 70px;
  position: relative;
}
.menu-member {
  background-color: #ffffff;
  width: 100%;
}
.wrapper-menu__bar {
  display: none;
}

.container-menu {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  height: 70px;
  z-index: 50;
  position: relative;
}

.active-tab {
  color: #289cd8;
}
.active-tab:hover {
  color: #289cd8;
  text-decoration: none;
}
.logo-tma {
  width: 94px;
  height: 47px;
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .desktop-menu {
    display: none !important;
  }
  .mobile-menu {
    display: block !important;
  }
  .menu-scroll {
    max-height: 70px;
    position: fixed;
    z-index: 9999;
  }
  .menu {
    justify-content: space-between;
    position: relative;
  }
  .menu .Logo a img {
    width: 80px;
    height: 40px;
  }
  .container-menu {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: 70px;
    background-color: #ffffff;
  }
  .wrapper-menu__bar {
    display: block;
    height: auto;
    cursor: pointer;
    color: rgb(36, 41, 47);
    z-index: 120;
    padding-top: 8px;
  }
  .wrapper-menu__bar__item {
    width: 17.8px;
    height: 2px;
    background: #15a4dd;
    margin: 5px 0;
  }
  .title-menu {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    z-index: 100;
    position: fixed;
    width: 70%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 10px 50px rgb(27 31 35 / 15%);
    transform: translateX(100%);
    transition: transform 0.5s ease-out;
  }
  .title-menu.active {
    transform: translateX(0%);
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    width: 40%;
    overflow: hidden;
    z-index: 110;
  }
  .mobile-menu {
    padding: 15px 0px;
    font-size: medium;
  }
  .change-language {
    display: none;
    // width: 50px;
  }
  .change-language-mobile {
    display: block;
    margin-left: auto;
    margin-top: 0.3% !important;
  }
}

@media screen and (max-width: 767px) {
  .desktop-menu {
    display: none !important;
  }
  .mobile-menu {
    display: block !important;
  }
  .menu-scroll {
    max-height: 60px;
    width: 100% !important;
    position: fixed;
    z-index: 9999;
    background: #fff;
  }
  .menu {
    justify-content: space-between;
    position: relative;
  }
  .menu .Logo a img {
    width: 80px;
    height: 40px;
  }
  .container-menu {
    width: 100%;
    padding-left: 10px;
    padding-right: 20px;
    height: 70px;
    margin: 0px auto;
  }

  .wrapper-menu__bar {
    display: block;
    height: auto;
    cursor: pointer;
    color: rgb(36, 41, 47);
    z-index: 120;
    padding-top: 8px;
  }
  .wrapper-menu__bar__item {
    width: 17.8px;
    height: 2px;
    background: #15a4dd;
    margin: 5px 0;
  }
  .title-menu {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    z-index: 100;
    position: fixed;
    width: 50%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 10px 50px rgb(27 31 35 / 15%);
    transform: translateX(100%);
    transition: transform 0.5s ease-out;
    overflow: hidden;
  }
  .title-menu.active {
    transform: translateX(0%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    gap: 10px;
  }
  .mobile-menu {
    padding: 6px 0px;
    font-size: 14px;
  }
  .change-language {
    display: none;
    // width: 50px;
  }
  .change-language-mobile {
    display: block;
    margin-left: auto;
    margin-top: 0.3% !important;
  }
  .change-language-mobile .change-language-span {
    padding-right: 7px;
  }
  .padding-left-16 {
    padding-left: 5px !important;
  }
  .active-tab {
    color: #289cd8;
  }
  .active-tab:hover {
    color: #289cd8;
    text-decoration: none;
  }
}
