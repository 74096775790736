.solutions-one {
  background: #1eb2ff;
  @media screen and (max-width: 1366px) and (-webkit-max-device-pixel-ratio: 1) {
    .solutions-content {
      zoom: 80%;
      width: 90%;
      margin: 0 auto;
      -moz-transform: scale(0.8);
    }
  }
  .solutions-backgroud {
    height: calc(100vh - 102px);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-image: url("../images/Solutions/backgroud-frame-one.webp");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .solutions-contain {
    width: 100%;
  }
  .solutions-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }
  .solutions-content {
    padding: 35px 60px;
  }
  @media (-webkit-min-device-pixel-ratio: 1.3) and (-webkit-max-device-pixel-ratio: 1.49) {
    .solutions-contain {
      -moz-transform: scale(0.8);
      zoom: 80%;
      right: -50px;
    }
  }
  .solutions-content-col {
    padding: 0px;
  }
  .box-text {
    // width: 613px;
    height: 210px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    // padding: 35px;
    padding: 35px 32px 70px 32px;
  }
  .box-text-mobile {
    display: none;
  }
  .box-text-mobile {
    // width: 613px;
    height: 210px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    // padding: 35px;
    padding: 35px 32px 70px 32px;
  }
  .box-title-telecom {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #222222;
    padding-left: 14px;
    padding-top: 5px;
  }
  .box-icon {
    width: 34px;
    height: 34px;
  }

  .box-text-content {
    padding-top: 20px;
    // padding-bottom: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #222222;
  }
  .box-text-content-col {
    text-align: left;
  }
  li::marker {
    color: #1eb2ff;
  }
  .box-text-content-col > ul {
    line-height: 150%;
    padding-left: 0px;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
  }
  .col-right {
    padding-left: 10px;
  }
  .col-left {
    padding-right: 10px;
  }
  .box-image {
    padding-top: 30px;
  }
  .box-image-col-1 {
    padding-left: 0px;
    padding-right: 7px;
  }
  .box-image-col-2 {
    padding-right: 0px;
    padding-left: 7px;
  }
  .box-image-1 {
    width: 100%;
    height: 210px;
  }
  .box-image-2 {
    width: 100%;
    height: 210px;
  }
  .box-image-right {
    padding-bottom: 30px;
  }
  .box-title-commerce-right {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #222222;
    padding-left: 14px;
  }
  .box-image-col-3 {
    width: 100%;
  }
  .unactive {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .mobile-page {
    width: 100%;
    height: auto;
  }
  .solutions-one {
    .box-text-content-col > ul {
      line-height: 30px;
    }
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 98px;
        padding-right: 98px;
        .solutions-title {
          padding-top: 80px;
          padding-bottom: 25px;
        }

        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 24px 0px;
          margin: 0 auto;
          .solutions-content-col {
            max-width: 100% !important;
            .box-text-content {
              .box-text-content-col {
                .box-text-vn {
                  display: flex;
                  flex-wrap: wrap;
                  li {
                    width: 40%;
                  }
                }
                .box-text-vn-1 {
                  display: flex;
                  flex-wrap: wrap;
                  position: relative;
                  left: -7em;
                  width: 112%;
                  li {
                    width: 40%;
                  }
                }
                .box-text-vn-1 li:nth-child(2) {
                  width: 50%;
                }
              }
            }
          }

          .col-right {
            padding-top: 24px;
            padding-left: 0px;
            .box-text {
              display: none;
            }
            .box-text-mobile {
              display: block;

              .box-text-content {
                .box-text-content-col {
                  .box-text-vn-3 {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    left: 60px;
                    li {
                      width: 60%;
                    }
                  }
                  .box-text-vn-5 {
                  }
                  .box-text-vn-6 {
                  }
                }

                .col-xl-6 {
                  -ms-flex: 0 0 50%;
                  flex: unset;
                  max-width: 50%;
                }
              }
              #box-text-content-col-en {
                width: 100%;
                .box-text-vn-4 {
                }
              }
            }
          }
          .col-left {
            padding-right: 0px;
          }
        }
      }
    }
    //swap solutions-content
    .solutions-content-col {
      display: contents;
    }
    div#group-1 {
      order: 1;
    }
    div#group-2 {
      order: 4;
    }
    div#group-3 {
      order: 2;
    }
    div#group-4 {
      order: 3;
      .line-height {
        line-height: 130%;
      }
      .text-vn-3 {
        line-height: 130%;
        padding-bottom: 8px;
      }
    }
  }

  .solutions-one .box-image-right {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .solutions-one .box-image {
    padding-top: 24px;
  }
  .solutions-one .box-image-1 {
    width: 100%;
    height: auto;
  }
  .solutions-one .box-image-2 {
    width: 100%;
    height: auto;
  }
  .solutions-one .box-image-col-1 {
    padding-left: 0px;
    padding-right: 10px;
  }
  .solutions-one .box-image-col-2 {
    padding-left: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mobile-page {
    width: 100%;
    height: auto;
  }
  .solutions-one {
    .box-text-content-col > ul {
      line-height: 190%;
    }
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      .solutions-contain {
        display: block !important;
        background: #1eb2ff;
        padding-left: 24px;
        padding-right: 20px;
        .solutions-title {
          padding-top: 60px;
          padding-bottom: 20px;
        }

        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 24px 0px;
          margin: 0 auto;

          .solutions-content-col {
            max-width: 100% !important;
            .box-text-content {
              .box-text-content-col {
                .box-text-vn {
                  display: flex;
                  flex-wrap: wrap;
                  li {
                    width: 40%;
                  }
                }
                .box-text-vn-1 {
                  display: flex;
                  flex-wrap: wrap;
                  position: relative;
                  left: -7em;
                  width: 112%;
                  li {
                    width: 40%;
                  }
                }
                .box-text-vn-1 li:nth-child(2) {
                  width: 50%;
                }
              }
            }
          }

          .col-right {
            padding-top: 24px;
            padding-left: 0px;
            .box-text {
              display: none;
            }
            .box-text-mobile {
              display: block;

              .box-text-content {
                .box-text-content-col {
                  padding-right: 0px;
                  line-height: 130%;
                  .box-text-vn-3 {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    left: 35px;
                    li {
                      width: 60%;
                    }
                  }
                  .box-text-vn-5 {
                  }
                }
              }
              .box-text-content-col {
                .text-vn-3 {
                  padding-bottom: 8px;
                }
              }
              #box-text-content-col-en {
                width: 100%;
                .box-text-vn-4 {
                }
              }
            }
          }
          .col-left {
            padding-right: 0px;
          }
        }
      }
    }
  }

  .solutions-one .box-image-right {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .solutions-one .box-image-1 {
    width: 100%;
    height: auto;
  }
  .solutions-one .box-image-2 {
    width: 100%;
    height: auto;
  }
  .solutions-one .box-image {
    padding-top: 24px;
  }
  .solutions-one .box-image-col-1 {
    padding-right: 10px;
  }
  .solutions-one .box-image-col-2 {
    padding-left: 10px;
  }
  //swap solutions-content
  .solutions-content-col {
    display: contents;
  }
  div#group-1 {
    order: 1;
    height: auto !important;
    padding-bottom: 20px;
    .box-text-vn,
    .box-text-vn-1 {
      line-height: 130%;
      li {
        padding-bottom: 8px;
      }
    }
  }
  div#group-2 {
    order: 4;
  }
  div#group-3 {
    order: 2;
  }
  div#group-4 {
    order: 3;
    height: 205px;
    padding-bottom: 32px;
    .line-height {
      line-height: 130%;
    }
    .text-vn-3 {
      line-height: 130%;
      padding-bottom: 8px;
    }
  }
}

@media screen and (max-width: 767px) {
  .mobile-page {
    width: 100%;
    height: auto;
  }
  .solutions-one {
    .box-text-content-col > ul {
      line-height: 29px;
    }
    .box-text-content-col > li {
      line-height: 25px;
    }
    .solutions-backgroud {
      background-image: none;
      display: block;
      height: auto;
      margin-top: -5px;
      position: relative;
      top: -55px;
      .solutions-contain {
        // display: block !important;
        background: #1eb2ff;
        padding-left: 17px;
        padding-right: 15px;

        .solutions-title {
          padding-left: 5px;
          padding-right: 5px;
          line-height: 120%;
          font-size: 38px;
        }

        .solutions-content {
          width: 100%;
          flex-direction: column;
          padding: 24px 0px;
          display: flex;

          .solutions-content-col {
            max-width: 100% !important;
            .box-text {
              height: auto;
              background: #ffffff;
              box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
              padding: 35px 29px 0px 32px;

              .box-text-content-col:nth-child(2) ul {
                position: relative;
                bottom: 1em;
              }
              .box-text-title {
                .box-title-telecom {
                  text-align: left;
                }
              }
            }
            .box-text-content {
              display: block !important;
              padding-left: 5px;
            }
            .box-title-commerce-right {
              text-align: left;
            }
          }

          .col-right {
            padding-top: 24px;
            padding-left: 0px;
          }
          .col-left {
            padding-right: 0px;
          }
        }
      }
    }
    .solutions-content-col {
      display: contents;
    }
    div#group-1 {
      order: 1;
    }
    div#group-2 {
      order: 4;
    }
    div#group-3 {
      order: 2;
    }
    div#group-4 {
      order: 3;
    }
    .line-height {
      line-height: 150%;
    }
  }

  .solutions-one .box-image-1 {
    width: 100%;
    height: auto;
  }
  .solutions-one .box-image-2 {
    width: 100%;
    height: auto;
  }
  .solutions-one .box-image-right {
    padding-bottom: 24px;
    padding-top: 24px;
  }

  .solutions-one .box-image {
    padding-top: 24px;
  }
  .solutions-one .box-image-col-1 {
    padding-right: 6px;
  }
  .solutions-one .box-image-col-2 {
    padding-left: 6px;
  }
  .solutions-one {
    .box-text-vn-3 {
      line-height: 170% !important;
    }
  }
}
